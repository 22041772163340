import React from 'react';
import styled from 'styled-components';
import { Route, NavLink } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import {
  GET_NEWSLETTER_NAME_QUERY,
  GET_PROJECT_NAME_QUERY,
} from '../../../client/__graphql__/queries';

const StyledBreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background: rgb(27, 27, 27);
  float: left;
  user-select: none;
`;

const StyledBreadCrumbItem = styled((props) => <NavLink {...props} />)`
  transition: background 0.2s ease-in-out;
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  text-decoration: none;
  color: rgb(150, 150, 150);
  pointer-events: auto;

  &.active {
    pointer-events: none;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: '>';
    position: relative;
    right: -15px;
    width: 10px;
    height: 10px;
  }
`;

export const BreadCrumbs = () => {
  return (
    <StyledBreadCrumbs>
      <Route
        path="/:page?/:sguid?/:newsletter?/:page2?"
        render={({ match }) => {
          if (match.params.page) {
            return (
              <StyledBreadCrumbItem exact to={`/oversikt`}>
                Oversikt
              </StyledBreadCrumbItem>
            );
          }
          return null;
        }}
      />
      <Route
        path="/:page?/:sguid?/:newsletter?/:page2?"
        render={({ match }) => {
          if (match.params.page && match.params.sguid) {
            return (
              <Query
                query={GET_PROJECT_NAME_QUERY}
                variables={{ sguid: match.params.sguid }}
                fetchPolicy="network-only"
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  return (
                    <StyledBreadCrumbItem
                      exact
                      to={`/prosjekt/${match.params.sguid}`}
                    >
                      {data.getProjectName.name}
                    </StyledBreadCrumbItem>
                  );
                }}
              </Query>
            );
            return null;
          }
          return null;
        }}
      />
      <Route
        path="/:page?/:sguid?/:newsletter?/:page2?"
        render={({ match }) => {
          if (
            match.params.page &&
            match.params.sguid &&
            match.params.newsletter &&
            match.params.newsletter !== 'interessenter'
          ) {
            return (
              <Query
                fetchPolicy="cache-only"
                query={GET_NEWSLETTER_NAME_QUERY}
                variables={{ id: match.params.newsletter }}
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return null;
                  }
                  return (
                    <StyledBreadCrumbItem
                      exact
                      to={`/prosjekt/${match.params.sguid}/${match.params.newsletter}`}
                    >
                      {data?.getNewsletter && data.getNewsletter.name}
                    </StyledBreadCrumbItem>
                  );
                }}
              </Query>
            );
          } else if (
            match.params.newsletter &&
            match.params.newsletter === 'interessenter'
          ) {
            return (
              <StyledBreadCrumbItem
                exact
                to={`/prosjekt/${match.params.sguid}/interessenter`}
              >
                Interessenter og kjøpere
              </StyledBreadCrumbItem>
            );
          }
        }}
      />
      <Route
        path="/:page?/:sguid?/:newsletter?/:page2?"
        render={({ location, match }) => {
          if (match.params.page2 === 'statistikk') {
            return (
              <StyledBreadCrumbItem exact to={location.pathname}>
                Statistikk
              </StyledBreadCrumbItem>
            );
          }
          return null;
        }}
      />
    </StyledBreadCrumbs>
  );
};
