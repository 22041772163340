import React from 'react';
import styled from 'styled-components';

import { Logo } from '../ui/Logo';
import { UserMenu } from '../ui/Menu';

export const Header = props => {
  return (
    <StyledHeader>
      <Logo />
      <UserMenu {...props} />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  float: left;
  background: black;
  z-index: 9997;
`;
