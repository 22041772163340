import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { animated, Transition, config } from 'react-spring/renderprops.cjs';

import { NOTIFICATION_STATE_QUERY } from '../../../client/__graphql__/queries';

const StyledNotificationWrapper = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9998;
`;

const StyledNotification = styled(animated.div)`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  background: ${(props) => props.theme.notification[props.type].background};
  color: ${(props) => props.theme.notification[props.type].font};
  border-radius: 2px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  float: left;
  clear: left;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NotificationItem = ({ client, props, name, type, message }) => {
  useEffect(() => {
    setTimeout(() => {
      const { notification } = client.readQuery({
        query: NOTIFICATION_STATE_QUERY,
      });
      let items = notification.items.filter((item) => item.key !== name);
      client.writeQuery({
        query: NOTIFICATION_STATE_QUERY,
        data: {
          notification: {
            __typename: 'Notification',
            items,
          },
        },
      });
    }, 5000);
  }, []);
  return (
    <StyledNotification style={props} type={type}>
      {message ? message : null}
    </StyledNotification>
  );
};

export const Notification = () => {
  return (
    <ApolloConsumer>
      {(client) => (
        <Query query={NOTIFICATION_STATE_QUERY}>
          {({ data, loading, error }) => {
            if (loading) {
              return null;
            }
            return (
              <StyledNotificationWrapper>
                <Transition
                  items={(data?.notification && data.notification.items) || []}
                  config={config.fast}
                  keys={(item) => item.key}
                  from={{ opacity: 0, transform: 'translate3d(-50px, 0, 0)' }}
                  enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
                  leave={{ opacity: 0, transform: 'translate3d(0, 0, 0)' }}
                >
                  {(item) => (props) => {
                    return (
                      <NotificationItem
                        client={client}
                        props={props}
                        name={item.key}
                        message={item.message}
                        type={item.type}
                      />
                    );
                  }}
                </Transition>
              </StyledNotificationWrapper>
            );
          }}
        </Query>
      )}
    </ApolloConsumer>
  );
};
