import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Refresh } from './Icons';

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 15px;
  background: ${props =>
    props.primary
      ? props.theme.button.primary.background
      : props.theme.button.secondary.background};
  font-size: 16px;
  font-weight: 600;
  color: ${props =>
    props.primary
      ? props.theme.button.primary.fontColor
      : props.theme.button.secondary.fontColor};
  outline: none;
  border: none;
  border-radius: 2px;

  &.delete {
    background: rgb(210, 30, 0);
    color: white;
  }

  &.check-active {
    opacity: ${props => (props.active ? '1' : '0.5')};
    pointer-events: ${props => (props.active ? 'auto' : 'none')};
  }

  &:hover {
    cursor: pointer;
  }

  .add-icon {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    fill: #000;
  }

  .button-icon {
    transition: none !important;
    position: relative;
    left: -2px;
    width: 15px;
    height: 15px;
    margin-left: 0;
    margin-right: 5px;
    fill: #fff;
  }

  span {
    position: relative;
    float: left;
  }
`;

export const LinkButton = styled(props => <Link {...props} />)`
  display: inline-flex;
  align-items: center;
  padding: 15px;
  background: ${props =>
    props.primary
      ? props.theme.button.primary.background
      : props.theme.button.secondary.background};
  font-size: 16px;
  font-weight: 600;
  color: ${props =>
    props.primary
      ? props.theme.button.primary.fontColor
      : props.theme.button.secondary.fontColor};
  outline: none;
  border: none;
  border-radius: 2px;
  text-decoration: none;

  &.delete {
    background: rgb(210, 30, 0);
    color: white;
  }

  &.light-gray {
    background: rgb(200, 200, 200);
    color: rgb(150, 150, 150);
  }

  &.check-active {
    opacity: ${props => (props.active ? '1' : '0.5')};
    pointer-events: ${props => (props.active ? 'auto' : 'none')};
  }

  &:hover {
    cursor: pointer;
  }

  .add-icon {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    fill: #000;
  }

  .button-icon {
    transition: none !important;
    position: relative;
    left: -2px;
    width: 15px;
    height: 15px;
    margin-left: 0;
    margin-right: 5px;
    fill: #fff;
  }

  span {
    position: relative;
    float: left;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 20px;

  &.flex-center {
    display: flex;
    justify-content: center;
  }

  &.margin-top {
    margin-top: 15px;
  }

  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const RefreshButton = ({ onClick, loading }) => {
  return (
    <StyledRefreshButton onClick={onClick}>
      <Refresh className={loading ? 'icon loading' : 'icon'} />
    </StyledRefreshButton>
  );
};

const StyledRefreshButton = styled.button`
  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  padding: 10px 11px;
  background: rgb(40, 40, 40);
  border: none;
  border-radius: 2px;
  outline: none;
  float: right;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 20px;
    height: 20px;

    &.loading {
      animation-name: ckw;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    path {
      fill: white;
      stroke-width: 3px;
    }
  }
`;
