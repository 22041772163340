import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ListItem = ({ children }) => {
  return <StyledListItem>{children}</StyledListItem>;
};

const StyledListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background: rgb(15, 15, 15);
  margin-bottom: 10px;

  &:nth-of-type(odd) {
    background: rgb(30, 30, 30);
  }

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    color: white;
  }

  .column {
    display: flex;
    flex-flow: column;
    justify-content: center;

    &.column-row {
      flex-flow: row;
      justify-content: flex-end;
    }
  }

  .row {
    display: flex;
    width: 100%;

    &.margin-top {
      margin-top: 10px;
    }
  }

  span {
    &.info-column {
      display: flex;
      flex-flow: column;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      .label {
        font-size: 14px;
        font-weight: 300;
        color: rgb(150, 150, 150);
      }
      .value {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 300;
        color: white;

        &.link {
          &:hover {
            cursor: pointer;
          }
        }

        .copy-icon {
          position: relative;
          top: 2px;
          width: 15px;
          height: 15px;
          fill: ${props => props.theme.primary};
        }
      }
    }
  }
`;
