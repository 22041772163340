import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { Button } from '../Button';
import { Query, Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { Modal } from '../Modals';

import {
  GET_NEWSLETTER_DATA_QUERY,
  GET_PROJECT_IMAGES,
} from '../../../../client/__graphql__/queries';
import {
  ADD_WM_IMAGE_MUTATION,
  UPDATE_FIELD_MUTATION,
} from '../../../../client/__graphql__/mutations';

const ButtonGroup = styled.div`
  margin-top: 20px;
`;

const ModalError = styled.div`
  width: 100%;
  padding: 10px;
  background: red;
  color: white;
`;

const InputWrapper = styled.div`
  .input-wrapper {
    width: 350px;
    float: left;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
`;

const ImagePreview = styled.img`
  height: 130px;
  margin-bottom: 20px;
  margin-right: 20px;
  border: ${(props) =>
    props.selected === 1 ? 'solid 2px white' : 'solid 2px transparent'};

  &:hover {
    cursor: pointer;
  }
`;

export const SelectWmImageModal = ({
  show,
  sguid,
  newsletterId,
  elementId,
  refetch,
  onSave,
  onCancel,
  onClickOutside,
}) => {
  const [selectedItem, setSelected] = useState('');
  if (typeof window !== 'undefined') {
    return createPortal(
      <ApolloConsumer>
        {(client) => (
          <Modal
            show={show}
            className="gallery"
            onClickOutside={onClickOutside}
          >
            <Query
              query={GET_PROJECT_IMAGES}
              variables={{ sguid }}
              fetchPolicy="network-only"
              ssr={false}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return null;
                }

                if (error) {
                  return null;
                }

                if (data && data.getProjectImages) {
                  return (
                    <Mutation
                      mutation={ADD_WM_IMAGE_MUTATION}
                      onCompleted={(data) => {
                        if (
                          data &&
                          data.selectWmImage &&
                          data &&
                          data.selectWmImage.imagePath
                        ) {
                          const prevData = client.readQuery({
                            query: GET_NEWSLETTER_DATA_QUERY,
                            variables: { newsletterId, elementId },
                          });

                          let newData = Object.assign(
                            {},
                            prevData.getNewsletterData,
                            {
                              data: data.selectWmImage.imagePath,
                            }
                          );

                          client.writeQuery({
                            query: GET_NEWSLETTER_DATA_QUERY,
                            variables: { newsletterId, elementId },
                            data: {
                              getNewsletterData: newData,
                            },
                          });
                        }
                      }}
                    >
                      {(save) => (
                        <Mutation mutation={UPDATE_FIELD_MUTATION}>
                          {(update) => (
                            <Fragment>
                              <h2>Velg bilde fra Webmegler</h2>
                              <ImageWrapper>
                                {show &&
                                  data.getProjectImages.map((item, index) => {
                                    return (
                                      <ImagePreview
                                        src={item.standard}
                                        key={index}
                                        selected={
                                          selectedItem === item.standard ? 1 : 0
                                        }
                                        onClick={() => {
                                          if (item.standard === selectedItem) {
                                            setSelected('');
                                          } else {
                                            setSelected(item.standard);
                                          }
                                        }}
                                      />
                                    );
                                  })}
                              </ImageWrapper>

                              <ButtonGroup>
                                <Button
                                  onClick={onCancel}
                                  style={{ marginRight: '20px' }}
                                >
                                  Avbryt
                                </Button>
                                <Button
                                  primary="true"
                                  onClick={() => {
                                    if (selectedItem !== '') {
                                      save({
                                        variables: {
                                          newsletterId,
                                          elementId,
                                          image: selectedItem,
                                        },
                                      });
                                      onSave();
                                    }
                                  }}
                                >
                                  Lagre
                                </Button>
                              </ButtonGroup>
                            </Fragment>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  );
                } else {
                  return null;
                }
              }}
            </Query>
          </Modal>
        )}
      </ApolloConsumer>,
      document.getElementById('modals')
    );
  } else {
    return null;
  }
};
