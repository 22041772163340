import styled from 'styled-components';

export const ModalError = styled.div`
  background: rgb(210, 30, 0);
  color: white;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  max-width: 350px;
`;
