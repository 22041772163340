import React, { useRef } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { UPLOAD_FILE } from '../../../../../client/__graphql__/mutations';
import { UploadIcon } from '../../../ui/Icons';

import { GET_NEWSLETTER_DATA_QUERY } from '../../../../../client/__graphql__/queries';

export const UploadLogo = ({
  preview,
  edit,
  newsletterId,
  elementId,
  match,
}) => {
  const field = useRef(null);

  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <Query
            query={GET_NEWSLETTER_DATA_QUERY}
            variables={{ newsletterId, elementId }}
            fetchPolicy="cache-and-network"
            ssr={false}
          >
            {({ data: { getNewsletterData }, loading, error, refetch }) => {
              return (
                <Mutation
                  mutation={UPLOAD_FILE}
                  onCompleted={(data) => {
                    if (
                      data &&
                      data.singleUpload &&
                      data &&
                      data.singleUpload.publicPath
                    ) {
                      const prevData = client.readQuery({
                        query: GET_NEWSLETTER_DATA_QUERY,
                        variables: { newsletterId, elementId },
                      });

                      let newData = Object.assign(
                        {},
                        prevData.getNewsletterData,
                        {
                          data: data.singleUpload.publicPath,
                        }
                      );

                      client.writeQuery({
                        query: GET_NEWSLETTER_DATA_QUERY,
                        variables: { newsletterId, elementId },
                        data: {
                          getNewsletterData: newData,
                        },
                      });

                      refetch();
                    }
                  }}
                >
                  {(uploadFile, { loading, error }) => (
                    <StyledUploadLogo>
                      {!loading &&
                      getNewsletterData &&
                      getNewsletterData.data ? (
                        <img src={getNewsletterData.data} width="200px" />
                      ) : null}
                      <input
                        ref={field}
                        type="file"
                        name="newsletter_logo"
                        onChange={({
                          target: {
                            validity,
                            files: [file],
                          },
                        }) =>
                          validity.valid &&
                          uploadFile({
                            variables: {
                              newsletterId,
                              elementId,
                              file: file,
                            },
                          })
                        }
                      />
                      {!preview && edit.mode !== 2 ? (
                        <StyledUploadButton
                          onClick={() => {
                            field.current.click();
                          }}
                        >
                          <UploadIcon className="icon" fill="white" />
                        </StyledUploadButton>
                      ) : null}
                    </StyledUploadLogo>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </ApolloConsumer>
  );
};

const StyledUploadLogo = styled.div`
  position: relative;
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 200px;
  min-height: 123px;
  z-index: 1;

  input {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

const StyledUploadButton = styled.button`
  transition: background 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 10px;
  background: rgba(40, 40, 40, 0.5);
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 600;
  width: 60px;
  height: 60px;
  color: white;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  span {
    margin-top: 10px;
    display: block;
    font-size: 10px !important;
    float: left;

    &:last-child {
      margin-top: 0;
    }
  }

  .icon {
    position: relative;
    margin: 0;
    width: 30px;
    height: 30px;
    fill: white;
  }

  &:hover {
    cursor: pointer;
  }
`;
