import {
  EDIT_MODE_QUERY,
  GET_NEWSLETTER_QUERY,
  PREVIEW_STATE_QUERY,
  SHOW_TEMPLATES_STATE_QUERY,
} from './queries';
export const resolvers = {
  Mutation: {
    setEditMode: (parent, args, { cache }) => {
      try {
        let prevData = cache.readQuery({
          query: EDIT_MODE_QUERY,
        });
        let newData = Object.assign({}, prevData.edit, { mode: args.mode });
        cache.writeQuery({
          query: EDIT_MODE_QUERY,
          data: {
            edit: newData,
          },
        });
        return {
          __typename: 'Edit',
          editMode: args.mode,
        };
      } catch (err) {
        console.error(err);
      }
    },
    updateField: (parent, args, { cache }) => {
      try {
        const { getNewsletter } = cache.readQuery({
          query: GET_NEWSLETTER_QUERY,
          variables: { id: args.id },
        });

        const newData = {
          ...getNewsletter,
          [args.key]: args.value,
        };

        cache.writeQuery({
          query: GET_NEWSLETTER_QUERY,
          variables: { id: args.id },
          data: {
            getNewsletter: newData,
          },
        });

        return null;
      } catch (err) {
        console.error(err);
      }
    },
    toggleShowTemplates: (parent, args, { cache }) => {
      try {
        const { showTemplates } = cache.readQuery({
          query: SHOW_TEMPLATES_STATE_QUERY,
        });
        cache.writeQuery({
          query: SHOW_TEMPLATES_STATE_QUERY,
          data: {
            showTemplates: !showTemplates,
          },
        });
        return null;
      } catch (err) {
        console.error(err);
      }
    },
    togglePreview: (parent, args, { cache }) => {
      try {
        const { preview } = cache.readQuery({
          query: PREVIEW_STATE_QUERY,
        });
        cache.writeQuery({
          query: PREVIEW_STATE_QUERY,
          data: {
            preview: !preview,
          },
        });
        return null;
      } catch (err) {
        console.error(err);
      }
    },
  },
  Query: {},
};
