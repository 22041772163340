import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import { Button } from '../ui/Button';
import { Pagination } from '../ui/Pagination';

import { Content } from '../layout/Content';
import {
  GET_NEWSLETTER_POSTED_HISTORY_QUERY,
  GET_NEWSLETTER_POSTED_HISTORY_INTERESTED_QUERY,
} from '../../../client/__graphql__/queries';

const HistoryItem = ({ item, index }) => {
  const [show, toggleShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  return (
    <StyledHistoryItem>
      <HistoryItemTop>
        <HistoryInfoRow>
          <HistoryInfoColumn>
            <span>Sendt:</span>
            <span>{item.posted}</span>
          </HistoryInfoColumn>
          <HistoryInfoColumn>
            <span>Sendt til (interessenter / kjøpere):</span>
            <span>
              {item.interested} / {item.buyers}
            </span>
          </HistoryInfoColumn>
          <HistoryInfoColumn>
            <span>Klikket på av:</span>
            <span>{item.click} </span>
          </HistoryInfoColumn>
          <HistoryInfoColumn>
            <span>Åpnet av:</span>
            <span>{item.viewed} </span>
          </HistoryInfoColumn>
        </HistoryInfoRow>

        <Button onClick={() => toggleShow(!show)}>
          {show ? 'Lukk' : 'Se mer info'}
        </Button>
      </HistoryItemTop>
      {show ? (
        <HistoryItemBottom>
          <Query
            query={GET_NEWSLETTER_POSTED_HISTORY_INTERESTED_QUERY}
            variables={{
              id: item.identifier,
              currentPage: currentPage ? currentPage : 0,
            }}
            fetchPolicy="network-only"
            ssr={false}
          >
            {({ data, loading, error, fetchMore }) => {
              if (loading) {
                return null;
              }

              if (
                data?.interestedHistory &&
                data.interestedHistory.items &&
                data.interestedHistory.items.length
              ) {
                return (
                  <Fragment>
                    {data.interestedHistory.items.map((item, i) => {
                      return (
                        <InterestedItem key={`item-${i}`}>
                          <HistoryInfoColumn>
                            <span>Sendt til: </span>
                            <span>{item.to}</span>
                          </HistoryInfoColumn>
                          <HistoryInfoColumn>
                            <span>Sendt: </span>
                            <span>{item.posted}</span>
                          </HistoryInfoColumn>
                          <HistoryInfoColumn>
                            <span>Klikket: </span>
                            <span>{item.clicked ? 'Ja' : 'Nei'}</span>
                          </HistoryInfoColumn>
                          <HistoryInfoColumn>
                            <span>Sett:</span>
                            <span>{item.viewed ? 'Ja' : 'Nei'}</span>
                          </HistoryInfoColumn>
                        </InterestedItem>
                      );
                    })}
                    <Pagination
                      pages={data.interestedHistory.pages}
                      currentPage={data.interestedHistory.currentPage}
                      onClick={(page) => {
                        fetchMore({
                          variables: {
                            input: {
                              currentPage: currentPage,
                            },
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;
                            setCurrentPage(page);
                            return Object.assign(
                              {},
                              {
                                getProjects: {
                                  ...prev.interestedHistory,
                                  projects: [
                                    ...fetchMoreResult.interestedHistory.items,
                                  ],
                                },
                              }
                            );
                          },
                        });
                      }}
                    />
                  </Fragment>
                );
                return;
              }
            }}
          </Query>
        </HistoryItemBottom>
      ) : null}
    </StyledHistoryItem>
  );
};

export const Statistics = ({ match, location, history }) => {
  return (
    <Content scroll="true" padding="20px 35px 20px 20px">
      <h1 className="heading">Statistikk</h1>
      <p>En oversikt over statistikk for dette nyhetsbrevet.</p>
      <HistoryWrapper>
        <Query
          query={GET_NEWSLETTER_POSTED_HISTORY_QUERY}
          fetchPolicy="network-only"
          variables={{ id: match.params.newsletterId }}
        >
          {({ data, error, loading }) => {
            return (
              <Query
                query={GET_NEWSLETTER_POSTED_HISTORY_QUERY}
                fetchPolicy="network-only"
                variables={{ id: match.params.newsletterId }}
                pollInterval={10000}
              >
                {({ data: { postedHistory } = {}, loading, error }) => {
                  if (loading) {
                    return null;
                  }

                  if (postedHistory) {
                    return postedHistory.map((item, index) => (
                      <HistoryItem item={item} index={index} key={index} />
                    ));
                  }
                }}
              </Query>
            );
          }}
        </Query>
      </HistoryWrapper>
    </Content>
  );
};

const HistoryWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  float: left;
`;

const StyledHistoryItem = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  background: rgb(10, 10, 10);
  color: white;
`;

const HistoryInfoColumn = styled.div`
  display: inline-flex;
  flex-flow: column;
  margin-right: 20px;

  span {
    &:first-child {
      font-size: 14px;
      font-weight: 300;
      color: rgb(150, 150, 150);
    }
    &:last-child {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 300;
    }
  }
`;

const HistoryInfoRow = styled.div``;

const HistoryItemTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HistoryItemBottom = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const InterestedItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;

  &:nth-of-type(odd) {
    background: rgb(15, 15, 15);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
