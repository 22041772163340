import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

export const RemoveElementButton = ({ className, onClick }) => {
  return (
    <StyledRemoveElementButtonWrapper className={className}>
      <Tooltip
        title="Fjern element"
        position="top"
        theme="light"
        animation="shift"
        offset={0}
        distance={20}
      >
        <StyledRemoveElementButton onClick={onClick}>
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="1" x2="10" y2="1" stroke="white" strokeWidth="2" />
          </svg>
        </StyledRemoveElementButton>
      </Tooltip>
    </StyledRemoveElementButtonWrapper>
  );
};

const StyledRemoveElementButtonWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 3;
  right: -20px;
  border-radius: 100%;

  &.button {
    right: 20px;
    top: 10px;
  }
`;

const StyledRemoveElementButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 20px;
  height: 20px;
  background: #18a0fb;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;
