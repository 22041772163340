import React, { useState } from 'react';
import { withRouter } from 'react-router';

import * as compose from 'lodash/flowRight';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

import { ColorIcon, EditIcon, LinkIcon } from '../../../ui/Icons';
import { EditButtonModal } from '../../../ui/Modals/EditButton';
import { EditImageLinkModal } from '../../../ui/Modals/AddImageLink';

export const EditButton = React.memo(
  compose(withRouter)(
    ({
      item,
      match,
      type,
      label,
      disabled,
      preview,
      active,
      right,
      className,
      onClick,
    }) => {
      switch (type) {
        case 'color':
          return (
            <StyledEditButton
              onClick={() => onClick(disabled)}
              className={className}
              style={item.meta.edit.style}
              active={active}
            >
              <Tooltip
                title="Endre farge"
                position="top"
                theme="light"
                animation="shift"
                offset={0}
                distance={25}
              >
                <ColorIcon className="icon" />
                <div className="hover-target"></div>
              </Tooltip>
            </StyledEditButton>
          );
        case 'button-link':
          const [showButtonModal, toggleShowEditModal] = useState(false);
          return (
            <>
              {!preview ? (
                <EditButtonModal
                  newsletterId={match.params.newsletterId}
                  elementId={item.id}
                  show={showButtonModal ? 1 : 0}
                  onCancel={() => toggleShowEditModal(false)}
                  onSave={() => toggleShowEditModal(false)}
                />
              ) : null}

              <StyledEditButton
                onClick={(e) => {
                  if (typeof onClick === 'function') {
                    onClick(e);
                  }
                  toggleShowEditModal(!showButtonModal);
                }}
                className={'edit-button-link'}
                active={active}
              >
                <Tooltip
                  title="Endre lenke"
                  position="top"
                  theme="light"
                  animation="shift"
                  distance={25}
                >
                  <LinkIcon className="icon" />
                  <div className="hover-target"></div>
                </Tooltip>
              </StyledEditButton>
            </>
          );
        case 'image-link':
          const [showImageModal, toggleShowLinkModal] = useState(false);
          return (
            <>
              <EditImageLinkModal
                newsletterId={match.params.newsletterId}
                elementId={item.id}
                show={showImageModal ? 1 : 0}
                onCancel={() => toggleShowLinkModal(false)}
                onSave={() => toggleShowLinkModal(false)}
              />
              <StyledEditButtonWrapper>
                <Tooltip
                  title={label}
                  position="top"
                  theme="light"
                  animation="shift"
                  offset={30}
                  distance={25}
                >
                  <StyledEditButton
                    onClick={(e) => {
                      if (typeof onClick === 'function') {
                        onClick(e);
                      }
                      toggleShowLinkModal(!showImageModal);
                    }}
                    className={'edit-image-link'}
                    active={active}
                  >
                    <LinkIcon className="icon" fill="white" />
                  </StyledEditButton>
                </Tooltip>
              </StyledEditButtonWrapper>
            </>
          );
        default:
          return <StyledEditButton onClick={onClick} active={active} />;
      }
    }
  )
);

const StyledEditButtonWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 45px;
  height: 45px;

  &.left {
    left: 10px;
    right: auto;
  }
  &.right {
    top: 10px;
    right: 10px;
    left: auto;
  }
`;

const StyledEditButton = styled.div`
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: ${(props) => (props.active ? '#18a0fb' : 'rgb(40, 40, 40)')};
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  border-radius: 100%;
  border: none;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  .hover-target {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .icon {
    fill: white;
  }

  &:hover {
    background: ${(props) =>
      !props.active ? 'rgb(230, 230, 230)' : '#18a0fb'};
    cursor: pointer;

    .icon {
      fill: ${(props) => (props.active ? 'white' : 'black')};
    }
  }

  &.edit-image-link {
    top: -10px;
    right: -10px;
    width: 45px;
    height: 45px;

    .icon {
      height: 20px;
    }
  }

  &.section-color {
    .icon {
      position: relative;
      top: 2px;
      left: -1px;
    }
  }

  &.edit-button-link {
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    display: inline-block;

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 12px;
    }
  }

  &.edit-button-background {
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    display: inline-block;

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 12px;
    }
  }

  &.edit-button-color {
    top: 0;
    bottom: 0;
    margin: auto;
    left: -30px;
    right: auto;
    width: 25px;
    height: 25px;

    .icon {
      position: relative;
      height: 12px;
    }
  }

  &.font-color {
    width: 30px;
    height: 30px;
    left: 10px;
    right: auto;

    .icon {
      height: 12px;
    }
  }
`;
