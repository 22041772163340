import gql from 'graphql-tag';

export const defaults = {
  data: {
    edit: {
      __typename: 'Edit',
      mode: 1,
      element: '',
      elements: [],
      elementName: '',
      startColor: '',
      colorType: '',
      color: '',
      saved: true,
    },
    preview: false,
    showTemplates: false,
    notification: {
      __typename: 'Notification',
      items: [],
      show: false,
      type: '',
      message: '',
    },
  },
};

export const defaultsQuery = gql`
  query {
    edit {
      mode
      element
      elements {
        id
      }
      elementName
      startColor
      colorType
      color
      saved
    }
    preview
    notification
    showTemplates
    notification {
      items {
        key
        type
        show
        message
      }
    }
  }
`;
