import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { UPLOAD_FILE } from '../../../../../client/__graphql__/mutations';
import { SelectWmImageModal } from '../../../ui/Modals/SelectWmImage';
import { ImagesIcon, UploadIcon } from '../../../ui/Icons';
import { Tooltip } from 'react-tippy';

import { GET_NEWSLETTER_DATA_QUERY } from '../../../../../client/__graphql__/queries';

export const UploadImage = ({
  item,
  newsletterId,
  elementId,
  onWmBtnClick,
  match,
  refetch,
}) => {
  const field = useRef(null);
  const [showWmModal, toggleShowWmModal] = useState(false);
  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation
          mutation={UPLOAD_FILE}
          onCompleted={(data) => {
            if (
              data &&
              data.singleUpload &&
              data &&
              data.singleUpload.publicPath
            ) {
              const prevData = client.readQuery({
                query: GET_NEWSLETTER_DATA_QUERY,
                variables: { newsletterId, elementId },
              });

              let newData = Object.assign({}, prevData.getNewsletterData, {
                data: data.singleUpload.publicPath,
              });

              client.writeQuery({
                query: GET_NEWSLETTER_DATA_QUERY,
                variables: { newsletterId, elementId },
                data: {
                  getNewsletterData: newData,
                },
              });
            }
          }}
        >
          {(uploadFile, { loading, error }) => (
            <StyledUploadImageWrapper>
              <SelectWmImageModal
                newsletterId={match.params.newsletterId}
                elementId={item.id}
                sguid={match.params.sguid}
                show={showWmModal ? 1 : 0}
                refetch={() => null}
                onCancel={() => toggleShowWmModal(false)}
                onSave={() => toggleShowWmModal(false)}
              />
              <input
                ref={field}
                type="file"
                name="newsletter"
                onChange={({
                  target: {
                    validity,
                    files: [file],
                  },
                }) =>
                  validity.valid &&
                  uploadFile({
                    variables: {
                      newsletterId,
                      elementId,
                      file: file,
                    },
                  })
                }
              />
              <Tooltip
                title="Last opp bilde"
                position="top"
                theme="light"
                animation="shift"
                offset={8}
                distance={20}
              >
                <StyledUploadButton
                  onClick={() => {
                    field.current.click();
                  }}
                >
                  <UploadIcon className="icon" />
                </StyledUploadButton>
              </Tooltip>

              <Tooltip
                title="Hent bilde fra Webmegler"
                position="top"
                theme="light"
                animation="shift"
                offset={8}
                distance={20}
              >
                <StyledUploadButton
                  onClick={(e) => {
                    if (typeof onWmBtnClick === 'function') {
                      onWmBtnClick(e);
                    }
                    toggleShowWmModal(true);
                  }}
                >
                  <ImagesIcon className="icon" />
                </StyledUploadButton>
              </Tooltip>
            </StyledUploadImageWrapper>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
};

const StyledUploadImageWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  input {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

const StyledUploadButton = styled.button`
  transition: background 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 10px;
  background: rgb(40, 40, 40);
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 600;
  width: 60px;
  height: 60px;
  color: white;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .icon {
    position: relative;
    top: -2px;
    width: 30px;
    height: 30px;
    fill: white;
  }

  &:hover {
    background: white;
    cursor: pointer;

    .icon {
      fill: rgb(40, 40, 40) !important;
    }
  }

  span {
    margin-top: 10px;
    display: block;
    font-size: 10px !important;
    float: left;

    &:last-child {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-left: 10px;
  }
`;
