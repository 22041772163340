import React from 'react';
import styled from 'styled-components';

export const Textarea = ({ placeholder, value, onChange }) => {
  return (
    <StyledTextAreaWrapper>
      {placeholder ? (
        <StyledTextAreaPlaceholder>{placeholder} </StyledTextAreaPlaceholder>
      ) : null}
      <StyledTextArea value={value} onChange={onChange} rows="5" />
    </StyledTextAreaWrapper>
  );
};

const StyledTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
`;

const StyledTextAreaPlaceholder = styled.div`
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: rgb(100, 100, 100);
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 27px 10px 5px 10px;
  border: none;
  outline: none;
  font-size: 16px;
  color: rgb(100, 100, 100);
  resize: none;
`;
