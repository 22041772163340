import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation UploadFile(
    $file: Upload!
    $newsletterId: String
    $elementId: String
  ) {
    singleUpload(
      file: $file
      newsletterId: $newsletterId
      elementId: $elementId
    ) {
      filename
      filePath
      publicPath
    }
  }
`;

export const ADD_WM_IMAGE_MUTATION = gql`
  mutation SelectWMImage(
    $newsletterId: String
    $elementId: String
    $image: String
  ) {
    selectWmImage(
      newsletterId: $newsletterId
      elementId: $elementId
      image: $image
    ) {
      success
      imagePath
    }
  }
`;

export const ADD_NEWSLETTER_MUTATION = gql`
  mutation AddNewsletter($sguid: String) {
    addNewsletter(sguid: $sguid) {
      identifier
    }
  }
`;

export const SELECT_TEMPLATE_MUTATION = gql`
  mutation SelectTemplate($newsletterId: String, $templateId: String) {
    selectTemplate(newsletterId: $newsletterId, templateId: $templateId) {
      name
      identifier
      success
    }
  }
`;

export const SAVE_NEWSLETTER_MUTATION = gql`
  mutation SaveNewsletter($input: NewsletterInput) {
    saveNewsletter(input: $input) {
      success
    }
  }
`;

export const UPDATE_PUBLISHED_NEWSLETTER_MUTATION = gql`
  mutation PublishNewsletter($id: String, $published: Boolean) {
    togglePublishedStateNewsletter(id: $id, published: $published) {
      published
    }
  }
`;

export const DELETE_NEWSLETTER_MUTATION = gql`
  mutation DeleteNewsletter($input: NewsletterInput) {
    deleteNewsletter(input: $input) {
      success
    }
  }
`;

export const POST_NEWSLETTER_MUTATION = gql`
  mutation PostNewsletter($input: PostingInput) {
    post(input: $input) {
      success
    }
  }
`;

export const SAVE_NEWSLETTER_BUTTON_MUTATION = gql`
  mutation SaveNewsletterButton(
    $newsletterId: String
    $elementId: String
    $data: String
    $text: String
  ) {
    saveNewsletterButton(
      newsletterId: $newsletterId
      elementId: $elementId
      data: $data
      text: $text
    ) {
      success
    }
  }
`;

export const SAVE_NEWSLETTER_OFFICE_NAME_MUTATION = gql`
  mutation SaveNewsletterOfficeName(
    $newsletterId: String
    $elementId: String
    $text: String
    $data: String
  ) {
    saveNewsletterOfficeName(
      newsletterId: $newsletterId
      elementId: $elementId
      text: $text
      data: $data
    ) {
      success
    }
  }
`;

export const SAVE_NEWSLETTER_DATA_MUTATION = gql`
  mutation SaveNewsletterData(
    $newsletterId: String
    $elementId: String
    $field: String
    $type: String
    $data: String
  ) {
    saveNewsletterData(
      newsletterId: $newsletterId
      elementId: $elementId
      field: $field
      type: $type
      data: $data
    ) {
      success
      data
    }
  }
`;

export const TEST_NEWSLETTER_MUTATION = gql`
  mutation TestNewsletter($input: PostingInput) {
    test(input: $input) {
      success
    }
  }
`;

/*
  Cache resolvers
*/

export const SET_EDIT_MODE_MUTATION = gql`
  mutation SetEditMode($mode: Int) {
    setEditMode(mode: $mode) @client {
      editMode
    }
  }
`;

export const UPDATE_FIELD_MUTATION = gql`
  mutation UpdateField($key: String, $value: String, $id: String) {
    updateField(key: $key, value: $value, id: $id) @client
  }
`;

export const TOGGLE_PREVIEW_MUTATION = gql`
  mutation TogglePreview($state: Boolean) {
    togglePreview(state: $state) @client
  }
`;

export const TOGGLE_SHOW_TEMPLATES_MUTATION = gql`
  mutation ToggleTemplates($state: Boolean) {
    toggleShowTemplates(state: $state) @client
  }
`;

export const LOGIN_MUTATION = gql`
  mutation UserLogin($input: LoginInput) {
    login(input: $input) {
      success
    }
  }
`;

export const GET_RESET_CODE_MUTATION = gql`
  mutation GetResetCode($input: ResetCodeInput) {
    getResetCode(input: $input) {
      success
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetInput) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export const GET_REGISTER_CODE_MUTATION = gql`
  mutation GetRegisterCode($input: RegisterCodeInput) {
    getRegisterCode(input: $input) {
      success
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation RegisterUser($input: RegisterInput) {
    register(input: $input) {
      success
    }
  }
`;

export const REGISTER_THIRDPARTY_MUTATION = gql`
  mutation RegisterThirdPartyUser($input: RegisterThirdPartyInput) {
    registerThirdParty(input: $input) {
      success
    }
  }
`;

export const REGISTER_INTERESTED = gql`
  mutation AddInterested($input: RegisterInterestedInput) {
    addInterested(input: $input) {
      id
      created
      success
    }
  }
`;

export const GET_LATEST_INTEREST_DATA = gql`
  mutation GetLatestData($sguid: String) {
    getLatestInterestData(sguid: $sguid) {
      success
    }
  }
`;

export const UNSUBSCRIBE_INTERESTED_MUTATION = gql`
  mutation Unsubscribe(
    $id: String
    $projectid: String
    $type: String
    $currentState: Boolean
  ) {
    unsubscribe(
      id: $id
      projectid: $projectid
      type: $type
      currentState: $currentState
    ) {
      success
    }
  }
`;

export const DELETE_INTERESTED_MUTATION = gql`
  mutation Delete($id: String, $projectid: String) {
    deleteInterested(id: $id, projectid: $projectid) {
      success
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutUser {
    logout {
      success
    }
  }
`;

/*
    Template mutations
*/

export const ADD_TEMPLATE_ELEMENT_MUTATION = gql`
  mutation AddElement($input: AddElementInput) {
    addTemplateElement(input: $input) {
      success
    }
  }
`;

export const REMOVE_TEMPLATE_ELEMENT_MUTATION = gql`
  mutation RemoveElement($input: RemoveElementInput) {
    removeTemplateElement(input: $input) {
      success
    }
  }
`;
