export const theme = {
  background: 'rgb(20, 20, 20)',
  secondaryBackground: 'rgb(0, 0, 0)',
  primary: '#e5c88f',
  secondary: 'rgb(30, 30, 30)',
  button: {
    primary: {
      fontColor: '#000',
      background: '#e5c88f'
    },
    secondary: {
      fontColor: '#fff',
      background: 'rgb(40, 40, 40)'
    }
  },
  switch: {
    primary: {},
    secondary: {}
  },
  input: {
    primary: {
      field: 'rgb(180, 180, 180)',
      font: 'rgb(100, 100, 100)',
      placeholder: 'rgb(100, 100, 100)',
      prefix: 'rgb(100, 100, 100)'
    },
    secondary: {
      field: 'rgb(255, 255, 255)',
      font: 'rgb(100, 100, 100)',
      placeholder: 'rgb(100, 100, 100)',
      prefix: 'rgb(150, 150, 150)'
    }
  },
  notification: {
    error: {
      background: 'rgb(230, 40, 0)',
      font: 'white'
    },
    success: {
      background: 'white',
      font: 'rgb(100, 100, 100)'
    }
  }
};
