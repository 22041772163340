import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { withApollo } from '@apollo/client/react/hoc';

export const AddElementButton = withApollo(({ className, onClick }) => {
  return (
    <StyledAddElementButtonWrapper
      className={className}
      onClick={() => {
        onClick();
      }}
    >
      <Tooltip
        title="Legg til element"
        position="top"
        theme="light"
        animation="shift"
        offset={0}
        distance={20}
      >
        <StyledAddElementIcon className="add-icon">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="5" x2="10" y2="5" stroke="white" strokeWidth="2" />
            <line x1="5" y1="10" x2="5" stroke="white" strokeWidth="2" />
          </svg>
        </StyledAddElementIcon>
      </Tooltip>
    </StyledAddElementButtonWrapper>
  );
});

const StyledAddElementButtonWrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: -20px;
  border-radius: 100%;

  &.relative {
    position: relative;
    right: 0;
  }

  &.button {
    right: 20px;
    top: 10px;
  }
  &:hover {
    cursor: pointer;

    .add-icon {
      background: rgba(255, 255, 255, 0.3);
    }
  }
`;

const StyledAddLabel = styled.div`
  display: inline-flex;
  margin-right: 10px;
  font-size: 16px;
  color: white;
`;

const StyledAddElementIcon = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 30px;
  height: 30px;
  background: #18a0fb;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;
