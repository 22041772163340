import React, { useState, useEffect } from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash/flowRight';
import styled from 'styled-components';
import {
  GET_NEWSLETTER_DATA_QUERY,
  GET_OFFICE_NAME_BY_SGUID,
} from '../../../../../client/__graphql__/queries';
import { SAVE_NEWSLETTER_DATA_MUTATION } from '../../../../../client/__graphql__/mutations';
import { UploadLogo } from '../modules/UploadLogo';

export const EditableLogo = React.memo(
  compose(withApollo)(
    ({
      newsletterId,
      elementId,
      edit,
      style,
      data,
      preview,
      client,
      match,
    }) => {
      const [type, setType] = useState('');

      function getType({ additionalData }) {
        let parsed = '';
        try {
          parsed = JSON.parse(additionalData);
        } catch (err) {
          parsed = { url: '', type: 'ordinary' };
        }
        switch (parsed && parsed.url) {
          case '/images/pm_logo.png':
          case '':
          case null:
            setType(1);
            return 'url(#grad1)';
          case '/images/pm_logo_b.png':
            setType(2);
            return 'black';
          case '/images/pm_logo_w.png':
            setType(3);
            return 'white';
          default:
            setType(1);
            return 'url(#grad1)';
        }
      }

      return (
        <Mutation mutation={SAVE_NEWSLETTER_DATA_MUTATION}>
          {(save) => (
            <Query
              query={GET_NEWSLETTER_DATA_QUERY}
              variables={{ newsletterId, elementId }}
              fetchPolicy="network-only"
              onCompleted={({ getNewsletterData: logoData }) => {
                if (
                  logoData &&
                  logoData.additionalData === '' &&
                  logoData.type !== 'uploaded'
                ) {
                  let logo = { url: '/images/pm_logo.png', type: 1 };
                  save({
                    variables: {
                      newsletterId,
                      elementId,
                      type: 'ordinary',
                      field: 'additional_data',
                      data: JSON.stringify({
                        url: logo.url,
                        type: 1,
                      }),
                    },
                  });
                }
              }}
            >
              {({
                data: { getNewsletterData: logoData } = {},
                loading,
                error,
                refetch,
              }) => {
                if (loading) {
                  return null;
                }

                let logo = '';
                try {
                  logo = JSON.parse(logoData.additionalData);
                } catch (err) {
                  logo = { url: '/images/pm_logo.png', type: 1 };
                }

                return (
                  <StyledEditableLogoWrapper>
                    {!loading && logoData && logoData.type === 'uploaded' ? (
                      <UploadLogo
                        preview={preview}
                        newsletterId={newsletterId}
                        elementId={elementId}
                        edit={edit}
                      />
                    ) : null}
                    {!loading && logoData && logoData.type !== 'uploaded' ? (
                      <img
                        src={
                          !logoData.additionalData
                            ? data.types[0].url
                            : logo.url
                        }
                        style={style}
                      />
                    ) : null}
                    {!loading && logoData && logoData.type !== 'uploaded' ? (
                      <Query
                        query={GET_OFFICE_NAME_BY_SGUID}
                        variables={{ sguid: match.params.sguid }}
                        fetchPolicy="cache-only"
                      >
                        {({
                          data: { getProject: project },
                          loading,
                          error,
                        }) => {
                          if (loading) {
                            return null;
                          } else {
                            return (
                              <svg
                                style={{ fontFamily: 'Arial' }}
                                viewBox="200 20 450 300"
                                height="20px"
                                width="200px"
                                xmlns="http://www.w3.org/2000/svg"
                                textAnchor="middle"
                              >
                                <linearGradient
                                  id="grad1"
                                  x1="10%"
                                  y1="30%"
                                  x2="80%"
                                  y2="80%"
                                >
                                  <stop
                                    offset="0%"
                                    style={{
                                      stopColor: 'rgb(193,126,84)',
                                      stopOpacity: 1,
                                    }}
                                  />
                                  <stop
                                    offset="50%"
                                    style={{
                                      stopColor: 'rgb(241,192,135)',
                                      stopOpacity: 1,
                                    }}
                                  />
                                  <stop
                                    offset="100%"
                                    style={{
                                      stopColor: 'rgb(185,113,73)',
                                      stopOpacity: 1,
                                    }}
                                  />
                                </linearGradient>
                                <text
                                  y="270"
                                  x="450"
                                  fill={type ? getType(logoData) : ''}
                                  style={{
                                    font: 'Arial',
                                    fontSize: '200px',
                                    textTransform: 'uppercase',
                                  }}
                                  contentEditable="true"
                                  suppressContentEditableWarning="true"
                                >
                                  {project.office}
                                </text>
                              </svg>
                            );
                          }
                        }}
                      </Query>
                    ) : null}

                    {!preview &&
                    edit.mode === 2 &&
                    !loading &&
                    logoData &&
                    logoData.type === 'ordinary' ? (
                      <StyledButtonWrapper>
                        {data &&
                          data.types &&
                          data.types.map((item, index) => {
                            return (
                              <StyledButton
                                key={index}
                                active={item.id === type}
                                onClick={() => {
                                  const prevData = client.readQuery({
                                    query: GET_NEWSLETTER_DATA_QUERY,
                                    variables: { newsletterId, elementId },
                                  });

                                  const newData = Object.assign(
                                    {},
                                    prevData.getNewsletterData,
                                    {
                                      additionalData: JSON.stringify({
                                        url: item.url,
                                        type: item.id,
                                      }),
                                    }
                                  );

                                  client.writeQuery({
                                    query: GET_NEWSLETTER_DATA_QUERY,
                                    variables: { newsletterId, elementId },
                                    data: {
                                      getNewsletterData: newData,
                                    },
                                  });

                                  save({
                                    variables: {
                                      newsletterId,
                                      elementId,
                                      field: 'additional_data',
                                      data: JSON.stringify({
                                        url: item.url,
                                        type: item.id,
                                      }),
                                    },
                                  });
                                }}
                              >
                                {item.title}
                              </StyledButton>
                            );
                          })}
                      </StyledButtonWrapper>
                    ) : null}
                    {!preview && edit.mode === 1 ? (
                      <StyledButtonWrapper>
                        <StyledButton
                          onClick={() => {
                            let type =
                              logoData.type === 'uploaded'
                                ? 'ordinary'
                                : 'uploaded';

                            const newData = Object.assign({}, logoData, {
                              type,
                            });

                            save({
                              variables: {
                                newsletterId,
                                elementId,
                                field: 'type',
                                data: type,
                              },
                            });

                            client.writeQuery({
                              query: GET_NEWSLETTER_DATA_QUERY,
                              variables: { newsletterId, elementId },
                              data: {
                                getNewsletterData: newData,
                              },
                            });
                          }}
                        >
                          {logoData.type === 'ordinary' || logoData.type === ''
                            ? 'Last opp annen logo'
                            : 'Bruk ordinær logo'}
                        </StyledButton>
                      </StyledButtonWrapper>
                    ) : null}
                  </StyledEditableLogoWrapper>
                );
              }}
            </Query>
          )}
        </Mutation>
      );
    }
  ),
  (prevProps, nextProps) => {
    return true;
  }
);

const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.active ? '#18a0fb' : 'rgb(40, 40, 40)')};
  color: white;
  padding: 10px;
  font-size: 12px;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

const StyledEditableLogoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 30px;

  svg {
    margin-bottom: 20px;
  }
`;
