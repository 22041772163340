import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Query, Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { GET_NUMBER_OF_RECEIVERS } from '../../../../client/__graphql__/queries';
import { POST_NEWSLETTER_MUTATION } from '../../../../client/__graphql__/mutations';
import { addNotification } from '../../utils/notification';

import { ConfirmActionModal } from './ConfirmAction';
import { ModalLoader } from '../Loader';
import { Button, ButtonGroup } from '../Button';
import { LockIcon } from '../Icons';

export const SelectReceiversModal = withRouter(
  ({ match, show, sguid, onClose }) => {
    const [selected, setSelected] = useState(0);

    useEffect(() => {
      if (show === false) {
        setSelected(0);
      }
    }, [show]);

    const template = (opts) => {
      switch (opts.selected) {
        case 0:
          return (
            <>
              <h2 style={{ marginBottom: 0 }}>
                Hvem ønsker du å sende nyhetsbrevet til?
              </h2>
              <ButtonGroup className="flex-center">
                <Button
                  className="check-active"
                  active={opts.data.interested > 0}
                  primary={opts.data.interested > 0 ? 'true' : null}
                  onClick={() =>
                    opts.data.interested > 0 ? setSelected(1) : null
                  }
                >
                  {opts.data.interested === 0 ? (
                    <LockIcon className="button-icon" />
                  ) : null}
                  Interessenter
                </Button>
                <Button
                  className="check-active"
                  active={opts.data.buyers > 0}
                  primary={opts.data.buyers > 0 ? 'true' : null}
                  onClick={() => (opts.data.buyers > 0 ? setSelected(2) : null)}
                >
                  {opts.data.buyers === 0 ? (
                    <LockIcon className="button-icon" />
                  ) : null}
                  Kjøpere
                </Button>
                <Button
                  className="check-active"
                  active={opts.data.buyers > 0 && opts.data.interested > 0}
                  primary={
                    opts.data.buyers > 0 && opts.data.interested > 0
                      ? 'true'
                      : null
                  }
                  onClick={() =>
                    opts.data.buyers > 0 && opts.data.interested > 0
                      ? setSelected(3)
                      : null
                  }
                >
                  {opts.data.buyers === 0 || opts.data.interested === 0 ? (
                    <LockIcon className="button-icon" />
                  ) : null}
                  Alle
                </Button>
              </ButtonGroup>
              <ModalCancel
                onClick={() => {
                  onClose(false);
                  setSelected(0);
                }}
              >
                Avbryt
              </ModalCancel>
            </>
          );
        case 1:
          if (opts.data.interested === 0) {
            setSelected(0);
          }
          return (
            <>
              <h2>Ønsker å sende nyhetsbrevet til interessentene nå?</h2>
              <ButtonGroup className="flex-center">
                <Button
                  primary="true"
                  onClick={() =>
                    opts.post({
                      variables: {
                        input: {
                          id: match.params.newsletterId,
                          type: selected,
                        },
                      },
                    })
                  }
                >
                  Ja
                </Button>
                <Button onClick={() => setSelected(0)}>Nei</Button>
              </ButtonGroup>
              <ModalCancel
                onClick={() => {
                  onClose(false);
                  setSelected(0);
                }}
              >
                Avbryt
              </ModalCancel>
            </>
          );
        case 2:
          if (opts.data.buyers === 0) {
            setSelected(0);
          }
          return (
            <>
              <h2>Ønsker å sende nyhetsbrevet til kjøpere nå?</h2>
              <ButtonGroup className="flex-center">
                <Button
                  primary="true"
                  onClick={() =>
                    opts.post({
                      variables: {
                        input: {
                          id: match.params.newsletterId,
                          type: selected,
                        },
                      },
                    })
                  }
                >
                  Ja
                </Button>
                <Button onClick={() => setSelected(0)}>Nei</Button>
              </ButtonGroup>
              <ModalCancel
                onClick={() => {
                  onClose(false);
                  setSelected(0);
                }}
              >
                Avbryt
              </ModalCancel>
            </>
          );
        case 3:
          if (opts.data.interested === 0 || opts.data.buyers === 0) {
            setSelected(0);
          }
          return (
            <>
              <h2>Ønsker å sende nyhetsbrevet til alle nå?</h2>
              <ButtonGroup className="flex-center">
                <Button
                  primary="true"
                  onClick={() =>
                    opts.post({
                      variables: {
                        input: {
                          id: match.params.newsletterId,
                          type: selected,
                        },
                      },
                    })
                  }
                >
                  Ja
                </Button>
                <Button onClick={() => setSelected(0)}>Nei</Button>
              </ButtonGroup>
              <ModalCancel
                onClick={() => {
                  onClose(false);
                  setSelected(0);
                }}
              >
                Avbryt
              </ModalCancel>
            </>
          );
      }
    };

    return (
      <ConfirmActionModal show={show}>
        <ModalContent>
          {show ? (
            <ApolloConsumer>
              {(client) => (
                <Mutation
                  mutation={POST_NEWSLETTER_MUTATION}
                  onCompleted={() => {
                    onClose();
                    setSelected(0);
                  }}
                  update={(cache) => {
                    addNotification;
                    addNotification(cache, {
                      type: 'success',
                      message: 'Nyhetsbrevet blir sendt.',
                    });
                  }}
                >
                  {(post, { loading: loadingWhilePosting }) => {
                    return (
                      <Query
                        query={GET_NUMBER_OF_RECEIVERS}
                        fetchPolicy="network-only"
                        ssr={false}
                        variables={{ sguid: sguid }}
                      >
                        {({ data, loading, error }) => {
                          if (loading || loadingWhilePosting) {
                            return (
                              <ModalLoader
                                loaderText={
                                  loadingWhilePosting
                                    ? 'Sender nyhetsbrev..'
                                    : 'Henter alternativer..'
                                }
                              />
                            );
                          }
                          if (data?.getReceiversCount) {
                            return (
                              <>
                                {template({
                                  selected: selected,
                                  data: data?.getReceiversCount,
                                  client: client,
                                  post: post,
                                })}
                              </>
                            );
                          }
                        }}
                      </Query>
                    );
                  }}
                </Mutation>
              )}
            </ApolloConsumer>
          ) : null}
        </ModalContent>
      </ConfirmActionModal>
    );
  }
);

const ModalCancel = styled.div`
  margin: 20px 0 0 0;
  text-align: center;
  font-size: 18px;
  color: white;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
`;
