import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring/web.cjs';

const StyledContentWrapper = styled(animated.div)`
  padding: ${props => (props.padding ? props.padding : '20px')};
  width: calc(100% + 1rem);
  height: calc(100vh - 130px);
  float: left;
  ${props => (props.scroll ? 'overflow-y: auto;' : '')}
`;

export const Content = ({
  className,
  scroll,
  padding,
  children,
  style = {}
}) => {
  const p = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  });
  let props = Object.assign({}, p, style);

  return (
    <StyledContentWrapper
      className={className}
      style={props}
      scroll={scroll}
      padding={padding}
    >
      {children}
    </StyledContentWrapper>
  );
};
