import React from 'react';
import styled from 'styled-components';

export const Logo = ({ className, fill = 'rgb(171, 120, 88)' }) => {
  return (
    <StyledLogoWrapper className={className}>
      <svg
        width="249"
        height="36"
        viewBox="0 0 249 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7693 25.7483C16.5415 25.2258 16.2802 24.7556 16.2802 23.5539V20.3406H18.8143C21.8447 20.3406 23.8693 19.0082 23.8693 16.4872C23.8693 13.9663 21.8447 12.6339 18.8012 12.6339H12.4138V12.6731C13.2759 13.1564 13.4719 13.7311 13.4719 14.972V23.4624C13.4719 24.7033 13.289 25.265 12.4138 25.7614V25.8006H17.7693V25.7483ZM16.2933 13.1303H17.926C20.0813 13.1303 21.0871 14.5018 21.0871 16.5003C21.0871 18.4988 20.0944 19.8703 17.926 19.8703H16.2933V13.1303ZM24.0652 8.93733L24.9012 11.0795H25.0449L25.8809 8.93733L26.5601 10.975H27.1871L26.0115 7.44824H25.8678L24.9796 9.70799L24.0914 7.44824H23.9477L22.7721 10.975H23.386L24.0652 8.93733Z"
          fill="#AB7858"
        />
        <rect
          x="0.566038"
          y="0.566038"
          width="34.8679"
          height="34.8679"
          stroke="#AB7858"
          strokeWidth="1.13208"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.5952 15.3676C57.5952 15.8104 57.4381 16.1924 57.1283 16.5021C56.8182 16.8122 56.3925 16.9694 55.8628 16.9694H53.551V13.7378H55.8628C56.3925 13.7378 56.8182 13.8963 57.128 14.2092C57.4381 14.5225 57.5952 14.9122 57.5952 15.3676ZM60.1462 15.3676C60.1462 14.2351 59.7355 13.2831 58.925 12.5386C58.1169 11.7965 57.0861 11.4204 55.8614 11.4204H51.0796C51.036 11.4204 51 11.4564 51 11.5V24.2424C51 24.2864 51.036 24.322 51.0796 24.322H53.4714C53.515 24.322 53.551 24.2864 53.551 24.2424V19.2868H55.8677C57.0903 19.2868 58.1194 18.9121 58.9268 18.1731C59.7359 17.4317 60.1462 16.4882 60.1462 15.3676Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.6548 24.322H68.0466C68.0902 24.322 68.1262 24.2864 68.1262 24.2424V13.7378H70.438C71.0323 13.7378 71.5212 13.8949 71.8907 14.205C72.2616 14.5158 72.4414 14.9206 72.4414 15.4423C72.4414 15.9511 72.2598 16.3478 71.8858 16.6554C71.5132 16.9631 71.0198 17.1188 70.4195 17.1188H68.2893C68.2456 17.1188 68.2096 17.1548 68.2096 17.1988V17.7219C68.2096 17.7401 68.2159 17.7576 68.2275 17.7719L73.4894 24.2927C73.5044 24.3112 73.5274 24.322 73.5512 24.322H76.5034C76.5342 24.322 76.5624 24.3042 76.5757 24.2763C76.5886 24.2484 76.5844 24.2152 76.5645 24.1914L72.2343 18.985C72.9607 18.8607 73.5997 18.4706 74.1351 17.8236C74.7039 17.1363 74.9924 16.3352 74.9924 15.4423C74.9924 14.2916 74.5849 13.3226 73.781 12.5634C72.9788 11.8049 71.8917 11.4204 70.549 11.4204H65.6548C65.6112 11.4204 65.5752 11.4564 65.5752 11.5V24.2424C65.5752 24.2864 65.6112 24.322 65.6548 24.322Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.9436 24.2424V11.5C83.9436 11.4564 83.908 11.4204 83.864 11.4204H81.4725C81.4285 11.4204 81.3926 11.4564 81.3926 11.5V24.2424C81.3926 24.2864 81.4285 24.322 81.4725 24.322H83.864C83.908 24.322 83.9436 24.2864 83.9436 24.2424Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.5904 24.7425H95.1599C95.1914 24.7425 95.2193 24.7243 95.2322 24.6964L101.342 11.5339C101.354 11.5088 101.351 11.4801 101.337 11.4571C101.322 11.4344 101.297 11.4204 101.27 11.4204H98.7006C98.6695 11.4204 98.6412 11.4386 98.628 11.4669L94.8519 19.6887L91.085 11.4669C91.0717 11.4386 91.0434 11.4204 91.0124 11.4204H88.4432C88.416 11.4204 88.3908 11.4344 88.3762 11.4574C88.3615 11.4801 88.3594 11.5091 88.3709 11.5339L94.5181 24.6964C94.531 24.7243 94.5593 24.7425 94.5904 24.7425Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.069 18.6697H107.655L108.866 16.0531L110.069 18.6697ZM109.175 11H108.605C108.574 11 108.545 11.0182 108.533 11.0461L102.386 24.2089C102.374 24.2334 102.376 24.2624 102.391 24.2851C102.405 24.3081 102.43 24.3221 102.458 24.3221H105.027C105.058 24.3221 105.087 24.3039 105.099 24.2753L106.592 20.9871H111.132L112.643 24.2756C112.656 24.3043 112.684 24.3221 112.715 24.3221H115.284C115.311 24.3221 115.337 24.3081 115.351 24.2854C115.366 24.2624 115.368 24.2337 115.356 24.2089L109.247 11.0461C109.234 11.0182 109.206 11 109.175 11Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.73 11.4204H117.547C117.503 11.4204 117.467 11.4564 117.467 11.5V13.6582C117.467 13.7022 117.503 13.7378 117.547 13.7378H120.877V24.2424C120.877 24.2864 120.913 24.322 120.957 24.322H123.348C123.392 24.322 123.428 24.2864 123.428 24.2424V13.7378H126.73C126.774 13.7378 126.81 13.7022 126.81 13.6582V11.5C126.81 11.4564 126.774 11.4204 126.73 11.4204Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.172 24.2676C145.182 24.3004 145.213 24.3221 145.247 24.3221H146.858C146.883 24.3221 146.907 24.3099 146.922 24.2893C146.937 24.2683 146.941 24.2414 146.933 24.2173L142.567 11.0545C142.556 11.0227 142.527 11.001 142.493 11C142.466 11.0028 142.43 11.0192 142.417 11.0506L138.673 20.5227L134.92 11.0506C134.907 11.0192 134.885 11.0024 134.844 11C134.81 11.001 134.781 11.0227 134.77 11.0545L130.402 24.2173C130.394 24.2414 130.398 24.2683 130.413 24.2893C130.428 24.3099 130.452 24.3221 130.478 24.3221H131.98C132.014 24.3221 132.045 24.3004 132.055 24.2676L134.977 15.5638L138.55 24.6919C138.562 24.7223 138.591 24.7425 138.624 24.7425C138.657 24.7425 138.686 24.7223 138.698 24.6919L142.262 15.5935L145.172 24.2676Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.659 24.2424V22.8693C159.659 22.8253 159.623 22.7894 159.579 22.7894H153.69V18.5674H159.346C159.39 18.5674 159.426 18.5318 159.426 18.4878V17.1147C159.426 17.0707 159.39 17.035 159.346 17.035H153.69V12.9531H159.579C159.623 12.9531 159.659 12.9171 159.659 12.8735V11.5C159.659 11.4564 159.623 11.4204 159.579 11.4204H152.078C152.034 11.4204 151.998 11.4564 151.998 11.5V24.2424C151.998 24.2864 152.034 24.322 152.078 24.322H159.579C159.623 24.322 159.659 24.2864 159.659 24.2424Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M177.63 17.0725H171.474C171.43 17.0725 171.394 17.1081 171.394 17.1521V18.5252C171.394 18.5692 171.43 18.6051 171.474 18.6051H176.018C176.004 19.3539 175.809 20.0547 175.435 20.6896C175.049 21.3461 174.497 21.8787 173.796 22.2715C173.094 22.6651 172.302 22.8641 171.441 22.8641C170.543 22.8641 169.709 22.6417 168.961 22.2024C168.213 21.7634 167.616 21.154 167.186 20.3917C166.755 19.6294 166.537 18.7815 166.537 17.8714C166.537 16.9618 166.756 16.1139 167.191 15.3512C167.624 14.5885 168.223 13.9791 168.97 13.5402C169.718 13.1012 170.549 12.8784 171.441 12.8784C172.876 12.8784 174.158 13.4888 175.253 14.6926C175.282 14.7244 175.331 14.7275 175.363 14.7003L176.502 13.7473C176.535 13.7193 176.54 13.669 176.512 13.6355C175.884 12.876 175.126 12.2855 174.26 11.8807C173.394 11.4763 172.445 11.271 171.441 11.271C170.257 11.271 169.146 11.5685 168.138 12.1552C167.13 12.7419 166.321 13.5513 165.733 14.5616C165.143 15.5719 164.845 16.6855 164.845 17.8714C164.845 19.057 165.142 20.1707 165.728 21.1809C166.313 22.1909 167.121 23.0003 168.129 23.587C169.137 24.174 170.251 24.4716 171.441 24.4716C172.657 24.4716 173.761 24.1695 174.722 23.5734C175.682 22.9776 176.428 22.1762 176.94 21.1911C177.45 20.2073 177.709 19.1119 177.709 17.9354V17.1521C177.709 17.1081 177.674 17.0725 177.63 17.0725Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.944 22.7894H185.054V11.5C185.054 11.4564 185.019 11.4204 184.975 11.4204H183.443C183.399 11.4204 183.363 11.4564 183.363 11.5V24.2424C183.363 24.2864 183.399 24.322 183.443 24.322H190.944C190.988 24.322 191.024 24.2864 191.024 24.2424V22.8693C191.024 22.8253 190.988 22.7894 190.944 22.7894Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.875 11.4204H196.373C196.329 11.4204 196.293 11.4564 196.293 11.5V24.2424C196.293 24.2864 196.329 24.322 196.373 24.322H203.875C203.919 24.322 203.954 24.2864 203.954 24.2424V22.8693C203.954 22.8253 203.919 22.7894 203.875 22.7894H197.985V18.5674H203.641C203.685 18.5674 203.721 18.5318 203.721 18.4878V17.1147C203.721 17.0707 203.685 17.035 203.641 17.035H197.985V12.9531H203.875C203.919 12.9531 203.954 12.9171 203.954 12.8735V11.5C203.954 11.4564 203.919 11.4204 203.875 11.4204Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.246 18.844C216.138 18.8055 216.901 18.456 217.513 17.8044C218.163 17.1136 218.492 16.2528 218.492 15.246C218.492 14.145 218.112 13.222 217.361 12.5026C216.611 11.7843 215.579 11.4204 214.293 11.4204H210.145C210.101 11.4204 210.065 11.4564 210.065 11.5V24.2424C210.065 24.2864 210.101 24.322 210.145 24.322H211.677C211.721 24.322 211.757 24.2864 211.757 24.2424V12.9531H214.162C214.948 12.9531 215.593 13.1647 216.076 13.5827C216.557 13.9979 216.801 14.5577 216.801 15.246C216.801 15.9347 216.557 16.4945 216.076 16.909C215.592 17.3273 214.948 17.5393 214.162 17.5393H212.247C212.216 17.5393 212.188 17.5571 212.175 17.585C212.162 17.613 212.166 17.6462 212.186 17.6703L217.697 24.2934C217.712 24.3116 217.735 24.322 217.759 24.322H219.693C219.724 24.322 219.752 24.3042 219.765 24.2759C219.778 24.248 219.774 24.2148 219.754 24.1911L215.246 18.844Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.222 11.4204H224.72C224.676 11.4204 224.64 11.4564 224.64 11.5V24.2424C224.64 24.2864 224.676 24.322 224.72 24.322H232.222C232.265 24.322 232.301 24.2864 232.301 24.2424V22.8693C232.301 22.8253 232.265 22.7894 232.222 22.7894H226.332V18.5674H231.988C232.032 18.5674 232.068 18.5318 232.068 18.4878V17.1147C232.068 17.0707 232.032 17.035 231.988 17.035H226.332V12.9531H232.222C232.265 12.9531 232.301 12.9171 232.301 12.8735V11.5C232.301 11.4564 232.265 11.4204 232.222 11.4204Z"
          fill="#AB7858"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.899 11.4204H247.367C247.323 11.4204 247.287 11.4564 247.287 11.5001V20.6082L238.551 11.0262C238.529 11.0021 238.493 10.9937 238.463 11.0052C238.433 11.0171 238.412 11.0468 238.412 11.0796V24.2425C238.412 24.2865 238.448 24.3221 238.492 24.3221H240.024C240.068 24.3221 240.104 24.2865 240.104 24.2425V15.1343L248.84 24.7163C248.855 24.7334 248.877 24.7425 248.899 24.7425C248.909 24.7425 248.918 24.7408 248.927 24.7373C248.958 24.7254 248.978 24.6957 248.978 24.6629V11.5001C248.978 11.4564 248.943 11.4204 248.899 11.4204Z"
          fill="#AB7858"
        />
      </svg>
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;

  .logo-text {
    float: left;
    display: inline-block;
    width: 200px;
  }
`;

const StyledSquare = styled.div`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: solid 2px ${props => props.fill};
  float: left;
  margin-right: 15px;

  svg {
    position: absolute;
    top: -2px;
    right: -4px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
`;
