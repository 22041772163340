import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash/flowRight';
import EyeDropper from 'react-eyedropper';

let html2canvas = null;
if (typeof window !== 'undefined') {
  html2canvas = require('html2canvas');
}

import { EyeDropperIcon } from '../../../../ui/Icons';
import { rgbToHex } from '../../../../utils/color';

export const EyeDropperButton = compose(withApollo)(
  ({ client, onElementClick }) => {
    const [active, setActive] = useState(false);
    const element = useRef();

    let eventListener = (e) => {
      console.log(e);
      const x = e.pageX;
      const y = e.pageY;
      const editor = document.getElementById('editor');
      html2canvas(editor).then(function (canvas) {
        console.log(x, y);
        const ctx = canvas.getContext('2d');
        const p = ctx.getImageData(x, y, 1, 1).data;
        const hex = '#' + ('000000' + rgbToHex(p[0], p[1], p[2])).slice(-6);
        if (typeof onElementClick === 'function') {
          onElementClick(hex);
          setActive(false);
        }
      });
    };

    useEffect(() => {
      if (active === true) {
        let editor = document.getElementById('editor');
        if (editor) {
          editor.addEventListener('click', eventListener);
          editor.classList.add('crosshair');
        }
      }
      return () => {
        let editor = document.getElementById('editor');
        editor.removeEventListener('click', eventListener);
        editor.classList.remove('crosshair');
      };
    }, [active]);

    return (
      <StyledEyeDropperButton
        active={active}
        onClick={() => setActive(!active)}
      >
        <EyeDropperIcon className="icon" />
      </StyledEyeDropperButton>
    );
  }
);

const StyledEyeDropperButton = styled.button`
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  position: relative;
  background: ${(props) => (props.active ? '#18a0fb' : 'white')};
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: solid 1px rgb(220, 220, 220);
  outline: none;

  &:hover {
    cursor: pointer;
  }

  .icon {
    transition: fill 0.2s ease-in-out;
    position: relative;
    top: 2px;
    right: 2px;
    fill: ${(props) => (props.active ? 'white' : 'rgb(190, 190, 190)')};
    width: 14px;
    height: 14px;
  }

  .eye-dropper {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
`;
