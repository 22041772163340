import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import { animated, useSpring } from 'react-spring/web.cjs';

import { Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import {
  LOGIN_MUTATION,
  GET_RESET_CODE_MUTATION,
  RESET_PASSWORD_MUTATION,
  GET_REGISTER_CODE_MUTATION,
  REGISTER_MUTATION,
  REGISTER_THIRDPARTY_MUTATION,
} from '../../../client/__graphql__/mutations';

import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Logo } from '../ui/Logo';

const PageContainer = styled(animated.div)`
  display: flex;
  padding: 200px 20px 50px 20px;
  justify-content: center;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;

  .logo {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const LinkButton = styled((props) => <Link {...props} />)`
  margin: 0 20px;
  padding: 10px 0;
  text-decoration: none;
  color: white;
`;

const LinkButtonThirdParty = styled((props) => <Link {...props} />)`
  display: block;
  margin: 10px 0 0 0;
  padding: 0;
  text-decoration: none;
  color: white;
`;

const Form = styled.form`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FormError = styled.div`
  margin: 10px 0;
  padding: 10px 10px;
  width: 100%;
  background: ${(props) => props.theme.notification.error.background};
  border-radius: 2px;
  color: white;
`;

const FormSuccess = styled.div`
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  background: rgb(30, 30, 30);
  color: white;
`;

const InputSpacer = styled.div`
  margin-bottom: 20px;

  &.flex {
    display: flex;

    .input-50 {
      width: calc(100% - 10px);

      &:first-child {
        margin-right: 20px;
      }
    }
  }
`;

const LoginForm = ({ history, location }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    if (location.search.match(/logged_out/)) {
      setError('Du ble logget ut. Logg inn igjen for å redigere nyhetsbrev.');
    }
  }, [location.search]);

  return (
    <Mutation
      mutation={LOGIN_MUTATION}
      onCompleted={({ login }) => {
        if (login && login.success) {
          history.replace('/oversikt');
        }
      }}
      onError={({ graphQLErrors, networkError }) => {
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
          setError(graphQLErrors[0].message);
        }
      }}
    >
      {(login, { loading, error }) => {
        return (
          <Fragment>
            <p>Logg inn for å opprette nyhetsbrev.</p>
            {errorMessage ? <FormError>{errorMessage}</FormError> : null}
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                login({
                  variables: {
                    input: {
                      email: email,
                      password: password,
                    },
                  },
                });
                setError('');
              }}
            >
              <InputSpacer>
                <Input
                  placeholder="E-post"
                  value={email}
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputSpacer>
              <InputSpacer>
                <Input
                  placeholder="Passord"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputSpacer>
              <Button type="submit">Logg inn</Button>
              <LinkButton to="/glemt-passord">Glemt passord?</LinkButton>
              <LinkButton to="/registrer">Ny bruker?</LinkButton>
            </Form>
          </Fragment>
        );
      }}
    </Mutation>
  );
};

const RegisterForm = ({ history }) => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeated, setRepeatedPassword] = useState('');
  const [posted, setPosted] = useState(false);
  const [errorMessage, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation
          mutation={REGISTER_MUTATION}
          onCompleted={async ({ register }) => {
            if (register.success) {
              setCode('');
              setSuccess('Brukeren ble opprettet.');

              const {
                data: { login },
              } = await client.mutate({
                mutation: LOGIN_MUTATION,
                variables: {
                  input: {
                    email: email,
                    password: password,
                  },
                },
              });

              setEmail('');
              setPassword('');
              setRepeatedPassword('');

              if (login && login.success) {
                history.replace('/oversikt');
              } else {
                history.replace('/');
              }
            }
          }}
          onError={({ graphQLErrors }) => {
            if (graphQLErrors) {
              setError(graphQLErrors[0].message);
            }
          }}
        >
          {(register, { loading, error }) => (
            <Mutation
              mutation={GET_REGISTER_CODE_MUTATION}
              onCompleted={({ getRegisterCode }) => {
                if (getRegisterCode.success) {
                  setPosted(true);
                }
              }}
              onError={({ graphQLErrors }) => {
                if (graphQLErrors) {
                  setError(graphQLErrors[0].message);
                }
              }}
            >
              {(getCode, { loading, error }) => (
                <Fragment>
                  <p>Opprett ny bruker.</p>
                  <p
                    style={{
                      margin: '20px 0',
                      padding: '10px',
                      fontSize: '14px',
                      fontWeight: '600',
                      background: 'rgb(30, 30, 30)',
                    }}
                  >
                    Husk! Dette er e-posten du er registrert med på
                    privatmegleren.no
                  </p>
                  {errorMessage ? <FormError>{errorMessage}</FormError> : null}
                  {success ? <FormSuccess>{success}</FormSuccess> : null}
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setError('');
                      if (!posted) {
                        getCode({
                          variables: {
                            input: {
                              email: email,
                            },
                          },
                        });
                      } else {
                        if (password !== passwordRepeated) {
                          setError('Passordene er ikke like.');
                        } else {
                          register({
                            variables: {
                              input: {
                                email: email,
                                code: parseInt(code),
                                password: password,
                              },
                            },
                          });
                        }
                      }
                    }}
                  >
                    <InputSpacer>
                      <Input
                        placeholder="E-post"
                        value={email}
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputSpacer>
                    {posted ? (
                      <Fragment>
                        <InputSpacer>
                          <Input
                            placeholder="Verifiseringskode (sendt på mail)"
                            value={code}
                            type="text"
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </InputSpacer>
                        <InputSpacer>
                          <Input
                            placeholder="Passord"
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputSpacer>
                        <InputSpacer>
                          <Input
                            placeholder="Repeter passord"
                            value={passwordRepeated}
                            type="password"
                            onChange={(e) =>
                              setRepeatedPassword(e.target.value)
                            }
                          />
                        </InputSpacer>
                      </Fragment>
                    ) : null}
                    <Button>
                      {!posted ? 'Send verifiseringskode' : 'Opprett bruker'}
                    </Button>
                    <LinkButton to="/">Innlogging</LinkButton>
                  </Form>
                </Fragment>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
};

const RegisterFormThirdParty = ({ history }) => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [access, setAccess] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeated, setRepeatedPassword] = useState('');
  const [errorMessage, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation
          mutation={REGISTER_THIRDPARTY_MUTATION}
          onCompleted={async ({ registerThirdParty }) => {
            if (registerThirdParty.success) {
              setSuccess(
                `Brukeren ble opprettet. Før du kan logge inn må vi aktivere kontoen din. Du vil motta en e-post når kontoen din
                er klar.`
              );
              setName('');
              setCompany('');
              setAccess('');
              setEmail('');
              setPassword('');
              setRepeatedPassword('');
            }
          }}
          onError={({ graphQLErrors }) => {
            setSuccess('');
            if (graphQLErrors) {
              setError(graphQLErrors[0].message);
            }
          }}
        >
          {(register, { loading, error }) => (
            <Fragment>
              <p>Opprett ny bruker for tredjepart</p>
              {errorMessage ? <FormError>{errorMessage}</FormError> : null}
              {success ? <FormSuccess>{success}</FormSuccess> : null}
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setError('');
                  if (!company) {
                    setError('Navn på firma mangler');
                  } else if (!access) {
                    setError('Mangler informasjon om ønsket tilgang');
                  } else if (!name) {
                    setError('Mangler navn');
                  } else if (!email) {
                    setError('Mangler e-post');
                  } else if (!password) {
                    setError('Passord mangler');
                  } else if (password !== passwordRepeated) {
                    setError('Passordene er ikke like');
                  } else if (password && password.length < 8) {
                    setError('Passordet må være minst 8 tegn');
                  } else {
                    register({
                      variables: {
                        input: {
                          name: name,
                          company: company,
                          access: access,
                          email: email,
                          password: password,
                        },
                      },
                    });
                  }
                }}
              >
                <InputSpacer>
                  <Input
                    placeholder="Firma"
                    value={company}
                    type="text"
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </InputSpacer>
                <InputSpacer>
                  <Input
                    className="input-50"
                    placeholder="Ønsker tilgang til"
                    value={access}
                    type="text"
                    onChange={(e) => setAccess(e.target.value)}
                  />
                </InputSpacer>
                <InputSpacer>
                  <Input
                    placeholder="Navn"
                    value={name}
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputSpacer>
                <InputSpacer>
                  <Input
                    placeholder="E-post"
                    value={email}
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputSpacer>
                <InputSpacer>
                  <Input
                    placeholder="Ønsket passord"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputSpacer>
                <InputSpacer>
                  <Input
                    placeholder="Repeter passord"
                    value={passwordRepeated}
                    type="password"
                    onChange={(e) => setRepeatedPassword(e.target.value)}
                  />
                </InputSpacer>
                <Button>Opprett bruker</Button>
                <LinkButton to="/">Innlogging</LinkButton>
              </Form>
            </Fragment>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
};

const ResetForm = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeated, setRepeatedPassword] = useState('');
  const [posted, setPosted] = useState(false);
  const [errorMessage, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <Mutation
      mutation={GET_RESET_CODE_MUTATION}
      onCompleted={({ getResetCode }) => {
        if (getResetCode.success) {
          setError('');
          setPosted(true);
        }
      }}
      onError={({ graphQLErrors, networkError }) => {
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
          setError(graphQLErrors[0].message);
        }
      }}
    >
      {(getCode, { loading, error }) => (
        <Mutation
          mutation={RESET_PASSWORD_MUTATION}
          onCompleted={({ resetPassword }) => {
            if (resetPassword.success) {
              setError('');
              setPosted(false);
              setSuccess('Passordet ble tilbakestilt.');
              setEmail('');
              setPassword('');
              setRepeatedPassword('');
              setCode('');
            }
          }}
          onError={({ graphQLErrors, networkError }) => {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
              setError(graphQLErrors[0].message);
            }
          }}
        >
          {(reset, { loading, error }) => (
            <Fragment>
              <p>Glemt passord</p>
              <p
                style={{
                  margin: '20px 0',
                  padding: '10px',
                  fontSize: '14px',
                  fontWeight: '600',
                  background: 'rgb(30, 30, 30)',
                }}
              >
                Husk! Dette er e-posten du er registrert med på
                privatmegleren.no
              </p>
              {errorMessage ? <FormError>{errorMessage}</FormError> : null}
              {success ? <FormSuccess>{success}</FormSuccess> : null}
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!posted) {
                    getCode({
                      variables: {
                        input: {
                          email: email,
                        },
                      },
                    });
                  } else {
                    if (password !== passwordRepeated) {
                      setError('Passordene er ikke like');
                    } else {
                      reset({
                        variables: {
                          input: {
                            email: email,
                            code: parseInt(code),
                            password: password,
                          },
                        },
                      });
                    }
                  }
                }}
              >
                <InputSpacer>
                  <Input
                    placeholder="E-post"
                    value={email}
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputSpacer>
                {posted ? (
                  <Fragment>
                    <InputSpacer>
                      <Input
                        placeholder="Verifiseringskode (sendt på mail)"
                        value={code}
                        type="text"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </InputSpacer>
                    <InputSpacer>
                      <Input
                        placeholder="Nytt passord"
                        value={password}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputSpacer>
                    <InputSpacer>
                      <Input
                        placeholder="Repeter nytt passord"
                        value={passwordRepeated}
                        type="password"
                        onChange={(e) => setRepeatedPassword(e.target.value)}
                      />
                    </InputSpacer>
                  </Fragment>
                ) : null}
                <Button type="submit">
                  {!posted ? 'Send verifiseringskode' : 'Tilbakestill'}
                </Button>
                <LinkButton to="/">Innlogging</LinkButton>
              </Form>
            </Fragment>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export const Login = ({ match, location, history }) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  return (
    <PageContainer style={props}>
      <Helmet>
        <body className="login" />
      </Helmet>
      <FormContainer>
        <Logo className="logo" />
        <Route exact path="/" render={(props) => <LoginForm {...props} />} />
        <Route
          exact
          path="/registrer"
          render={(props) => <RegisterForm {...props} />}
        />
        <Route
          exact
          path="/registrering-tredjepart"
          render={(props) => <RegisterFormThirdParty {...props} />}
        />
        <Route
          exact
          path="/glemt-passord"
          render={(props) => <ResetForm {...props} />}
        />
        <Route
          exact
          path="/:page(|registrer|glemt-passord)"
          render={() => {
            return (
              <>
                <p>Trenger du tilgang som tredjepart?</p>
                <LinkButtonThirdParty to="/registrering-tredjepart">
                  Registrering for tredjeparter
                </LinkButtonThirdParty>
              </>
            );
          }}
        />
      </FormContainer>
    </PageContainer>
  );
};
