import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash/flowRight';
import { animated, useSpring } from 'react-spring/web.cjs';
import { LOGOUT_MUTATION } from '../../../client/__graphql__/mutations';
import { PROFILE_INFO } from '../../../client/__graphql__/queries';

export const UserMenu = React.memo(
  compose(withApollo)(({ client, history }) => {
    const [show, toggle] = useState(false);
    const props = useSpring({
      delay: 200,
      from: { opacity: 0, transform: 'translate3d(10px, 0, 0)' },
      to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    });
    return (
      <Mutation
        mutation={LOGOUT_MUTATION}
        onCompleted={({ logout }) => {
          if (logout.success) {
            client.resetStore();
            history.replace('/');
          }
        }}
        onError={({ graphQLErrors }) => {}}
      >
        {(logout) => (
          <Query query={PROFILE_INFO}>
            {({ data, loading, error }) => {
              if (loading) {
                return null;
              }
              return (
                <UserMenuWrapper>
                  <UserMenuTop onClick={() => toggle(!show)} style={props}>
                    <UserName>
                      {' '}
                      Hei, {data.profile && data.profile.name.split(' ')[0]}
                    </UserName>
                    <UserProfileImage
                      image={`https://privatmegleren.no/image/ansatt/${data.profile.externalId}`}
                    />
                  </UserMenuTop>
                  <UserMenuList show={show}>
                    <UserMenuLink onClick={() => null} disabled={true} />
                    <UserMenuLink
                      onClick={async () => {
                        logout();
                      }}
                    >
                      Logg ut
                    </UserMenuLink>
                  </UserMenuList>
                </UserMenuWrapper>
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  })
);

const UserProfileImage = ({ image }) => {
  const [loaded, setLoaded] = useState(false);
  const [missing, setMissing] = useState(false);
  const [url, setUrl] = useState(image);
  useEffect(() => {
    if (missing) {
      setUrl('/images/profile_image_placeholder.jpg');
    }
  }, [missing]);

  return (
    <UserProfileImg image={url} loaded={loaded}>
      <img
        onLoad={() => setLoaded(true)}
        src={url}
        onError={(err) => {
          setMissing(true);
        }}
      />
    </UserProfileImg>
  );
};

const UserProfileImg = styled.div`
  transition: opacity 0.3s ease-in-out;
  position: relative;
  width: 40px;
  height: 40px;
  background: ${(props) => `url(${props.image})`} no-repeat white;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  float: right;
  opacity: ${(props) => (props.loaded ? 1 : 0)};

  &:hover {
    cursor: pointer;
  }

  img {
    width: 1px;
    height: 1px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    margin: auto;
  }
`;

const UserMenuWrapper = styled.div`
  position: relative;
  width: 300px;
  user-select: none;
  z-index: 1000;
`;

const UserMenuTop = styled(animated.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const UserName = styled(animated.div)`
  color: white;
  margin-right: 10px;
`;

const UserMenuList = styled.ul`
  position: absolute;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-flow: column;
  top: 60px;
  right: -20px;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  background: rgb(10, 10, 10);
`;

const UserMenuLink = styled.li`
  transition: color 0.2s ease-in-out;
  display: block;
  padding: 15px;
  width: 100%;
  height: 48px;
  font-size: 14px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  color: rgb(100, 100, 100);

  &:nth-of-type(even) {
    border-top: solid 1px rgb(30, 30, 30);
  }

  &:hover {
    cursor: pointer;
    color: rgb(130, 130, 130);
  }
`;
