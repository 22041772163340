import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import { Query, Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  GET_ALL_NEWSLETTERS_QUERY,
  GET_NUMBER_OF_RECEIVERS,
  GET_PROJECTS_QUERY_CACHE,
  AUTHENTICATED_CACHE,
} from '../../../client/__graphql__/queries';
import {
  ADD_NEWSLETTER_MUTATION,
  DELETE_NEWSLETTER_MUTATION,
} from '../../../client/__graphql__/mutations';

import { Content } from '../layout/Content';
import { ConfirmActionModal } from '../ui/Modals/ConfirmAction';
import { ListItem } from '../ui/ListItem';
import { Button, LinkButton, ButtonGroup } from '../ui/Button';
import { AddIcon, CopyIcon } from '../ui/Icons';

import { addNotification } from '../utils/notification';

const NewsletterList = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const NoNewsletters = styled.div`
  padding: 10px;
  background: rgb(20, 20, 20);
  font-size: 18px;
  font-weight: 300;
  color: white;
`;

const InteresetedCount = styled.p`
  display: inline-block;
  font-size: 18px;
  color: white;
  margin-left: 10px;

  &:first-child {
    padding: 0 10px;
  }
`;

const ProjectTitle = styled.h1`
  margin-bottom: 10px;
`;
const NewsletterHeading = styled.h2`
  margin-bottom: 10px;
`;
const ProjectOppdragsnummer = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: white;

  .sguid {
    &:hover {
      cursor: pointer;
    }
  }

  .copy-icon {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    fill: ${(props) => props.theme.primary};
    margin-left: 5px;
  }
`;

const StyledProjectStatWrapper = styled.div`
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  flex-flow: row;
  padding: 20px;
  border-radius: 2px;
  background: rgb(15, 15, 15);
`;

const StyledProjectStatColumn = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledProjectStatCount = styled.span`
  font-family: 'Playfair Display', serif;
  font-size: 25px;
  color: ${(props) => props.theme.primary};
`;

const StyledProjectStatType = styled.span`
  color: white;
`;

const ProjectStats = ({ location, sguid, thirdParty }) => {
  return (
    <Query query={AUTHENTICATED_CACHE}>
      {({ data: { authenticated } }) => (
        <StyledProjectStatWrapper>
          <StyledProjectStatColumn>
            <Query
              query={GET_NUMBER_OF_RECEIVERS}
              fetchPolicy="network-only"
              variables={{ sguid }}
              ssr={false}
            >
              {({ data, loading, error }) => {
                return (
                  <Fragment>
                    <StyledProjectStatCount>
                      {loading ? (
                        <span>Laster..</span>
                      ) : (
                        <>
                          {data.getReceiversCount &&
                          data.getReceiversCount.interested
                            ? data.getReceiversCount.interested
                            : 0}{' '}
                          /{' '}
                          {data.getReceiversCount &&
                          data.getReceiversCount.buyers
                            ? data.getReceiversCount.buyers
                            : 0}
                        </>
                      )}
                    </StyledProjectStatCount>
                    <StyledProjectStatType>
                      Interessenter / kjøpere
                    </StyledProjectStatType>
                  </Fragment>
                );
              }}
            </Query>
          </StyledProjectStatColumn>

          {authenticated.thirdParty !== true ? (
            <StyledProjectStatColumn>
              <LinkButton to={`${location}/interessenter`}>
                Se interessenter og kjøpere
              </LinkButton>
            </StyledProjectStatColumn>
          ) : null}
        </StyledProjectStatWrapper>
      )}
    </Query>
  );
};

export const Project = ({ match, location, history }) => {
  const [showConfirm, toggleShowConfirm] = useState(false);
  const [showConfirmDelete, toggleShowDeleteConfirm] = useState(false);
  return (
    <ApolloConsumer>
      {(client) => (
        <Content scroll="true" padding="20px 35px 20px 20px">
          <Query
            query={GET_PROJECTS_QUERY_CACHE}
            variables={{ sguid: match.params.sguid }}
            ssr={false}
          >
            {({ data: { getProject: project }, loading, error }) => {
              return (
                <>
                  <ProjectTitle>{project && project.name}</ProjectTitle>
                  <ProjectOppdragsnummer>
                    <CopyToClipboard
                      text={match.params.sguid}
                      onCopy={() => {
                        addNotification(client, {
                          type: 'success',
                          message: 'Oppdragsnummer ble kopiert',
                        });
                      }}
                    >
                      <span className="sguid">
                        {match.params.sguid} <CopyIcon className="copy-icon" />
                      </span>
                    </CopyToClipboard>
                  </ProjectOppdragsnummer>

                  <ProjectStats
                    sguid={match.params.sguid}
                    location={location.pathname}
                  />

                  <NewsletterHeading>Alle nyhetsbrev</NewsletterHeading>
                  <p>
                    Liste over alle nyhetsbrev som har blitt opprettet på dette
                    prosjektet.
                  </p>
                  <Mutation
                    mutation={ADD_NEWSLETTER_MUTATION}
                    onCompleted={(data) => {
                      if (data.addNewsletter && data.addNewsletter.identifier) {
                        toggleShowConfirm(false);
                        setTimeout(() => {
                          history.push(
                            `${location.pathname}/${data.addNewsletter.identifier}`
                          );
                        }, 250);
                      }
                    }}
                    update={(cache) => {
                      setTimeout(() => {
                        addNotification(cache, {
                          type: 'success',
                          message: 'Et nytt nyhetsbrev ble opprettet',
                        });
                      }, 250);
                    }}
                  >
                    {(add, { loading, error }) => (
                      <ConfirmActionModal
                        show={showConfirm}
                        text="Ønsker du å opprette et nyhetsbrev?"
                        onAccept={() => {
                          add({ variables: { sguid: match.params.sguid } });
                        }}
                        onCancel={() => toggleShowConfirm(false)}
                      />
                    )}
                  </Mutation>
                  <Button
                    style={{ marginTop: '20px' }}
                    primary="true"
                    onClick={() => {
                      toggleShowConfirm(true);
                    }}
                  >
                    <span>Opprett nyhetsbrev</span>
                    <AddIcon className="add-icon" />
                  </Button>
                  <Query
                    query={GET_ALL_NEWSLETTERS_QUERY}
                    variables={{ sguid: match.params.sguid }}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading, error, refetch }) => {
                      return (
                        <Query
                          query={GET_ALL_NEWSLETTERS_QUERY}
                          variables={{ sguid: match.params.sguid }}
                          pollInterval={10000}
                        >
                          {({ data, loading, error }) => {
                            if (loading) {
                              return null;
                            }
                            if (data.newsletters) {
                              return (
                                <NewsletterList>
                                  {data.newsletters.length ? (
                                    data.newsletters.map((item, index) => (
                                      <ListItem key={index}>
                                        <div className="column">
                                          <h2>{item.name}</h2>
                                          <div className="row">
                                            <span className="info-column">
                                              <span className="label">
                                                Opprettet
                                              </span>
                                              <span className="value">
                                                {item.created}
                                              </span>
                                            </span>
                                            <span className="info-column">
                                              <span className="label">
                                                Sist oppdatert
                                              </span>
                                              <span className="value">
                                                {item.updated
                                                  ? item.updated
                                                  : 'Ikke oppdatert'}
                                              </span>
                                            </span>
                                            <span className="info-column">
                                              <span className="label">
                                                Sendt
                                              </span>
                                              <span className="value">
                                                {' '}
                                                {item.posted ? 'Ja' : 'Nei'}
                                              </span>
                                            </span>
                                            <span className="info-column">
                                              <span className="label">
                                                Sendt sist
                                              </span>
                                              <span className="value">
                                                {item.postedDate
                                                  ? item.postedDate
                                                  : 'Ikke sendt'}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="column column-row">
                                          <ButtonGroup className="margin-top">
                                            <LinkButton
                                              primary={true}
                                              style={{ marginRight: '10px' }}
                                              to={`${location.pathname}/${item.identifier}`}
                                            >
                                              Redigere
                                            </LinkButton>
                                            <Mutation
                                              mutation={
                                                DELETE_NEWSLETTER_MUTATION
                                              }
                                              onCompleted={(data) => {
                                                if (
                                                  data &&
                                                  data.deleteNewsletter &&
                                                  data.deleteNewsletter
                                                    .success === true
                                                ) {
                                                  toggleShowDeleteConfirm(
                                                    false
                                                  );
                                                  refetch({
                                                    variables: {
                                                      sguid: match.params.sguid,
                                                    },
                                                  });
                                                }
                                              }}
                                              update={(cache) => {
                                                addNotification(cache, {
                                                  type: 'success',
                                                  message:
                                                    'Nyhetsbrevet ble slettet.',
                                                });
                                              }}
                                            >
                                              {(del) => (
                                                <ConfirmActionModal
                                                  show={
                                                    showConfirmDelete ? 1 : 0
                                                  }
                                                  text="Ønsker du å slette dette nyhetsbrevet?"
                                                  onAccept={() => {
                                                    del({
                                                      variables: {
                                                        input: {
                                                          identifier:
                                                            item.identifier,
                                                        },
                                                      },
                                                    });
                                                  }}
                                                  onCancel={() =>
                                                    toggleShowDeleteConfirm(
                                                      false
                                                    )
                                                  }
                                                />
                                              )}
                                            </Mutation>
                                            <Button
                                              className="delete"
                                              onClick={() =>
                                                toggleShowDeleteConfirm(true)
                                              }
                                            >
                                              Slett
                                            </Button>
                                          </ButtonGroup>
                                        </div>
                                      </ListItem>
                                    ))
                                  ) : (
                                    <NoNewsletters>
                                      Ingen nyhetsbrev er opprettet..
                                    </NoNewsletters>
                                  )}
                                </NewsletterList>
                              );
                            }
                          }}
                        </Query>
                      );
                    }}
                  </Query>
                </>
              );
            }}
          </Query>
        </Content>
      )}
    </ApolloConsumer>
  );
};
