import gql from 'graphql-tag';

export const AUTHENTICATED = gql`
  query Authenticated {
    authenticated {
      name
      thirdParty
      externalId
      loggedIn
    }
  }
`;

export const AUTHENTICATED_CACHE = gql`
  query {
    authenticated @client {
      name
      thirdParty
      externalId
      loggedIn
    }
  }
`;

export const PROFILE_INFO = gql`
  query {
    profile: authenticated @client {
      externalId
      name
    }
  }
`;

export const SEARCH_PROJECT_QUERY = gql`
  query SearchProject($input: SearchProjectInput) {
    projectSearch(input: $input) {
      result {
        total
        pages
        items {
          sguid
          address
          zip
          place
          interested
          buyers
        }
      }
    }
  }
`;

export const INTERESTED_FETCHED_QUERY = gql`
  query InterestedFetched($sguid: String) {
    interestedFetched(sguid: $sguid) {
      fetched
    }
  }
`;

export const GET_INTERESTED_QUERY = gql`
  query GetInterested($input: GetInterestedInput) {
    getInterested(input: $input) {
      fetched
      total
      list {
        id
        firstName
        lastName
        email
        phone
        unsubscribed
        deleted
        registrated
        created
      }
    }
  }
`;

export const EDIT_MODE_QUERY = gql`
  query {
    edit @client {
      mode
      element
      elements {
        id
      }
      elementName
      startColor
      colorType
      color
      saved
    }
  }
`;

export const GET_PROJECTS_QUERY = gql`
  query GetProjects($input: GetProjectsInput) {
    getProjects(input: $input) {
      numberOfProjects
      pages
      offset
      currentPage
      projects {
        address
        interested
        buyers
        sguid
      }
    }
  }
`;

export const GET_PROJECT_NAME_QUERY = gql`
  query GetProjectName($sguid: String) {
    getProjectName(sguid: $sguid) {
      name
    }
  }
`;

export const GET_PROJECT_QUERY = gql`
  query GetProject($sguid: String) {
    getProject(sguid: $sguid) {
      name
      office
      created
    }
  }
`;

export const GET_PROJECTS_QUERY_CACHE = gql`
  query GetProject($sguid: String) {
    getProject(sguid: $sguid) @client {
      name
      office
      created
    }
  }
`;

export const GET_OFFICE_NAME_BY_SGUID = gql`
  query GetProject($sguid: String) {
    getProject(sguid: $sguid) {
      office
    }
  }
`;

export const GET_ALL_NEWSLETTERS_QUERY = gql`
  query GetNewsletters($sguid: String) {
    newsletters(sguid: $sguid) {
      identifier
      name
      description
      subject
      posted
      postedDate
      draft
      click
      active
      created
      updated
    }
  }
`;

export const GET_NEWSLETTER_QUERY = gql`
  query GetNewsletter($id: String) {
    getNewsletter(id: $id) {
      id: identifier
      identifier
      name
      description
      subject
      image
      imageLink
      posted
      click
      draft
      active
      created
      hasTemplate
      template
      updated
    }
  }
`;

export const GET_TEMPLATE_QUERY = gql`
  query GetTemplate($id: String) {
    getTemplate(id: $id) {
      name
      officeName
      structure
    }
  }
`;

export const GET_NEWSLETTER_POSTED_HISTORY_QUERY = gql`
  query GetNewsletterPostedHistory($id: String) {
    postedHistory(id: $id) {
      identifier
      from
      posted
      interested
      buyers
      click
      viewed
    }
  }
`;

export const GET_NEWSLETTER_POSTED_HISTORY_INTERESTED_QUERY = gql`
  query GetNewsletterPostedInterestedHistory($id: String, $currentPage: Int) {
    interestedHistory(id: $id, currentPage: $currentPage) {
      count
      pages
      offset
      currentPage
      items {
        to
        clicked
        viewed
        success
        failed
        posted
      }
    }
  }
`;

export const GET_NEWSLETTER_POSTED = gql`
  query GetNewsletterPosted($id: String) {
    posted: getNewsletter(id: $id) {
      posted
    }
  }
`;

export const GET_NUMBER_OF_RECEIVERS = gql`
  query ReceiversCount($sguid: String) {
    getReceiversCount(sguid: $sguid) {
      interested
      buyers
    }
  }
`;

export const GET_NEWSLETTER_NAME_QUERY = gql`
  query GetNewsletter($id: String) {
    getNewsletter(id: $id) {
      id: identifier
      name
    }
  }
`;

export const GET_PROJECT_IMAGES = gql`
  query GetProjectImages($sguid: String) {
    getProjectImages(sguid: $sguid) {
      standard
    }
  }
`;

export const GET_NEWSLETTER_IMAGE_QUERY = gql`
  query GetImage(
    $elementId: String
    $newsletterId: String
    $sguid: String
    $fileVersion: Int
  ) {
    getImage(
      elementId: $elementId
      newsletterId: $newsletterId
      sguid: $sguid
      fileVersion: $fileVersion
    ) {
      imageLink
    }
  }
`;

export const GET_NEWSLETTER_DATA_QUERY = gql`
  query GetNewsletterData($newsletterId: String, $elementId: String) {
    getNewsletterData(newsletterId: $newsletterId, elementId: $elementId) {
      id
      data
      additionalData
      state {
        show
      }
      content
      type
      background
      color
    }
  }
`;

export const GET_NEWSLETTER_BUTTON_QUERY = gql`
  query GetNewsletterButton($newsletterId: String, $elementId: String) {
    getNewsletterButton(newsletterId: $newsletterId, elementId: $elementId) {
      link
    }
  }
`;

export const GET_NEWSLETTER_LOGO_OFFICE_NAME_QUERY = gql`
  query GetNewsletterOfficeLogo($newsletterId: String, $elementId: String) {
    getNewsletterOfficeLogo(
      newsletterId: $newsletterId
      elementId: $elementId
    ) {
      text
    }
  }
`;

export const GET_NEWSLETTER_IMAGE_LINK_QUERY = gql`
  query GetNewsletter($id: String) {
    getNewsletter(id: $id) {
      imageLink
    }
  }
`;

export const GET_NEWSLETTER_TEMPLATES_QUERY = gql`
  query GetTemplates($newsletterId: String, $type: Int) {
    getTemplates(newsletterId: $newsletterId, type: $type) {
      identifier
      name
      description
      selected
    }
  }
`;

export const PREVIEW_STATE_QUERY = gql`
  query {
    edit @client {
      mode
      element
      elements {
        id
      }
      saved
    }
    preview @client
  }
`;

export const SHOW_TEMPLATES_STATE_QUERY = gql`
  query {
    showTemplates @client
  }
`;

export const NOTIFICATION_STATE_QUERY = gql`
  query {
    notification @client {
      items {
        key
        type
        show
        message
      }
    }
  }
`;

export const GET_NEWSLETTER_TEMPLATES_CACHE_QUERY = gql`
  query GetTemplates($newsletterId: String, $type: Int) {
    getTemplates(newsletterId: $newsletterId, type: $type) {
      identifier
      name
      description
      selected
    }
  }
`;
