import { useEffect } from 'react';

let timer = null;
export const useDebounce = (cb, sec, args) => {
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb();
    }, sec);
  }, args);
};
