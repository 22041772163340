import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Mutation, Query } from '@apollo/client/react/components';
import { ApolloConsumer, useLazyQuery, useQuery } from '@apollo/client';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import debounce from 'lodash/debounce';

import {
  GET_PROJECTS_QUERY_CACHE,
  GET_INTERESTED_QUERY,
  AUTHENTICATED_CACHE,
} from '../../../client/__graphql__/queries';
import {
  UNSUBSCRIBE_INTERESTED_MUTATION,
  DELETE_INTERESTED_MUTATION,
  GET_LATEST_INTEREST_DATA,
} from '../../../client/__graphql__/mutations';

import { Content } from '../layout/Content';
import { ConfirmActionModal } from '../ui/Modals/ConfirmAction';
import { ListItem } from '../ui/ListItem';
import { Button, LinkButton, ButtonGroup, RefreshButton } from '../ui/Button';
import { AddIcon, CopyIcon, Refresh } from '../ui/Icons';
import { Pagination } from '../ui/Pagination';

import { addNotification } from '../utils/notification';
import { Checkbox } from '../ui/Checkbox';
import {
  Table,
  TableHead,
  TableBody,
  TableHeadCol,
  TableRow,
  TableCol,
} from '../ui/Table';
import { AddInterestedModal } from '../ui/Modals/AddInterested';
import { Loader } from '../ui/Loader';
import { SearchFieldInteressenter } from '../ui/SearchInteressenter';
import { useDebounce } from '../utils/useDebounce';
import { getOperationName } from '@apollo/client/utilities';

const InterestedRow = ({ item, type, currentPage, match }) => {
  const [showConfirmUnsubsribe, setShowConfirmUnsubscribe] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState('');
  return (
    <TableRow>
      <TableCol>{item.firstName}</TableCol>
      <TableCol>{item.lastName}</TableCol>
      <TableCol>{item.email}</TableCol>
      <TableCol>{item.phone}</TableCol>
      {type === 'interessenter' ? (
        <TableCol>{item.registrated}</TableCol>
      ) : null}
      {type === 'interessenter' ? (
        <TableCol>
          <Mutation
            mutation={UNSUBSCRIBE_INTERESTED_MUTATION}
            onCompleted={() => {
              setShowConfirmUnsubscribe('');
            }}
            update={(cache) => {
              const c = cache.readQuery({
                query: GET_INTERESTED_QUERY,
                variables: {
                  input: {
                    sguid: match.params.sguid,
                    view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
                    query: '',
                    currentPage: currentPage,
                    itemsPerPage: 10,
                  },
                },
              });
              const items = c.getInterested.list.map((el) => {
                if (el.id === showConfirmUnsubsribe) {
                  return Object.assign({}, el, {
                    unsubscribed: !item.unsubscribed,
                  });
                }
                return el;
              });
              cache.writeQuery({
                query: GET_INTERESTED_QUERY,
                variables: {
                  input: {
                    sguid: match.params.sguid,
                    view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
                    query: null,
                    currentPage: currentPage,
                    itemsPerPage: 10,
                  },
                },
                data: {
                  getInterested: {
                    fetched: true,
                    total: items.length,
                    list: items,
                    __typename: 'GetInterestedResponse',
                  },
                },
              });
              addNotification(cache, {
                type: 'success',
                message: `Interessenten ble meldt ${
                  !item.unsubscribed ? 'av' : 'på'
                } mottak av nyhetsbrev`,
              });
            }}
          >
            {(toggle, { loading }) => (
              <ConfirmActionModal
                show={showConfirmUnsubsribe === item.id}
                text={
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Er du sikker på at du ønsker å melde{' '}
                    {item.unsubscribed === true ? 'på' : 'av'}{' '}
                    {type === 'interessenter' ? 'interessenten' : 'kjøperen'}?
                  </div>
                }
                onAccept={() => {
                  toggle({
                    variables: {
                      id: item.id,
                      projectid: match.params.sguid,
                      type: type,
                      currentState: item.unsubscribed,
                    },
                  });
                }}
                onCancel={() => {
                  setShowConfirmUnsubscribe('');
                }}
              />
            )}
          </Mutation>

          <Checkbox
            label=""
            checked={item.unsubscribed}
            onClick={() => {
              setShowConfirmUnsubscribe(item.id);
            }}
          />
        </TableCol>
      ) : null}
      <TableCol>{item.created}</TableCol>
      {type === 'interessenter' ? (
        <TableCol>
          <Mutation
            mutation={DELETE_INTERESTED_MUTATION}
            refetchQueries={[getOperationName(GET_INTERESTED_QUERY)]}
            onCompleted={() => {
              setShowConfirmDelete('');
            }}
            update={(cache) => {
              /*
              const c = cache.readQuery({
                query: GET_INTERESTED_QUERY,
                variables: {
                  input: {
                    sguid: match.params.sguid,
                    view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
                    query: '',
                    currentPage: currentPage,
                    itemsPerPage: 10,
                  },
                },
              });
              const newElements = c.getInterested.list.filter(
                (elem) => showConfirmDelete !== elem.id
              );

              cache.writeQuery({
                query: GET_INTERESTED_QUERY,
                variables: {
                  input: {
                    sguid: match.params.sguid,
                    view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
                    query: null,
                    currentPage: currentPage,
                    itemsPerPage: 10,
                  },
                },
                data: {
                  getInterested: {
                    fetched: true,
                    total: newElements.length,
                    list: newElements,
                    __typename: 'GetInterestedResponse',
                  },
                },
              });
              */

              addNotification(cache, {
                type: 'success',
                message: `Interessenten ble slettet`,
              });
            }}
          >
            {(del) => (
              <ConfirmActionModal
                show={showConfirmDelete === item.id}
                text={
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Er du sikker på at du ønsker å slette interessenten?
                  </div>
                }
                onAccept={() => {
                  del({
                    variables: {
                      id: item.id,
                      projectid: match.params.sguid,
                    },
                  });
                }}
                onCancel={() => {
                  setShowConfirmDelete('');
                }}
              />
            )}
          </Mutation>
          <Button
            className="delete"
            onClick={() => {
              setShowConfirmDelete(item.id);
            }}
          >
            Slett
          </Button>
        </TableCol>
      ) : null}
    </TableRow>
  );
};

let timer = null;

export const Interested = ({ match, location, history }) => {
  const [showConfirm, toggleShowConfirm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState('');
  const [showConfirmUnsubsribe, setShowConfirmUnsubscribe] = useState('');
  const [type, setType] = useState('interessenter');
  const [
    search,
    {
      data: interessentData,
      loading: interessentLoading,
      error: interessentError,
    },
  ] = useLazyQuery(GET_INTERESTED_QUERY);

  useEffect(() => {
    search({
      variables: {
        input: {
          sguid: match.params.sguid,
          view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
          query: searchQuery,
          currentPage: currentPage,
          itemsPerPage: 10,
        },
      },
    });
  }, [currentPage]);

  useDebounce(
    () => {
      search({
        variables: {
          input: {
            sguid: match.params.sguid,
            view: type === 'interessenter' ? 'INTERESTED' : 'BUYER',
            query: searchQuery,
            currentPage: currentPage,
            itemsPerPage: 10,
          },
        },
      });
    },
    500,
    [searchQuery, type]
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <Query query={AUTHENTICATED_CACHE}>
          {({ data }) => {
            console.log(data);
            if (data?.authenticated && data.authenticated.thirdParty === true) {
              return (
                <Content scroll="true" padding="20px 35px 20px 20px">
                  <h2>Du har ikke tilgang til interessenter og kjøpere</h2>
                </Content>
              );
            }
            return (
              <Content scroll="true" padding="20px 35px 20px 20px">
                <Query
                  query={GET_PROJECTS_QUERY_CACHE}
                  variables={{ sguid: match.params.sguid }}
                  ssr={false}
                >
                  {({ data: { getProject: project }, loading, error }) => {
                    return (
                      <>
                        <ProjectTitle style={{ marginBottom: 0 }}>
                          Interessenter og kjøpere
                        </ProjectTitle>
                        <h2
                          style={{
                            marginTop: 0,
                            color: 'white',
                          }}
                        >
                          {project && project.name}
                        </h2>
                        <ProjectOppdragsnummer>
                          <CopyToClipboard
                            text={match.params.sguid}
                            onCopy={() => {
                              addNotification(client, {
                                type: 'success',
                                message: 'Oppdragsnummer ble kopiert',
                              });
                            }}
                          >
                            <span className="sguid">
                              {match.params.sguid}{' '}
                              <CopyIcon className="copy-icon" />
                            </span>
                          </CopyToClipboard>
                        </ProjectOppdragsnummer>
                        <AddInterestedModal
                          getInterestedVariables={{
                            input: {
                              sguid: match.params.sguid,
                              view:
                                type === 'interessenter'
                                  ? 'INTERESTED'
                                  : 'BUYER',
                              currentPage: currentPage,
                              itemsPerPage: 10,
                            },
                          }}
                          sguid={match.params.sguid}
                          show={showConfirm}
                          onClose={() => toggleShowConfirm(false)}
                        />
                      </>
                    );
                  }}
                </Query>
                <NewsletterHeading>
                  Se registrerte interessenter / kjøpere
                </NewsletterHeading>
                {/*
                <p style={{ fontSize: '14px', marginBottom: '20px' }}>
                  <b>Merk</b> at interessenter kun hentes fra privatmegleren.no,
                  eksterne skjemaer (GDPR-skjema)
                  <br /> og direkte registrerte i dette systemet. Interessenter
                  registrert direkte i Webmegler, fremkommer ikke her.
                </p>
                */}
                <ActionRow>
                  <ActionColumn>
                    <Checkbox
                      label="Interessenter"
                      checked={type === 'interessenter'}
                      onClick={() => {
                        setType('interessenter');
                      }}
                    />
                    <Checkbox
                      label="Kjøpere"
                      checked={type === 'kjope'}
                      onClick={() => setType('kjope')}
                    />
                  </ActionColumn>
                  <ActionColumn>
                    <Query
                      query={GET_INTERESTED_QUERY}
                      variables={{
                        input: {
                          sguid: match.params.sguid,
                          view:
                            type === 'interessenter' ? 'INTERESTED' : 'BUYER',
                          query: '',
                          currentPage: currentPage,
                          itemsPerPage: 10,
                        },
                      }}
                    >
                      {({ loading }) => {
                        if (loading) {
                          return (
                            <FetchingNewDataLoader>
                              <span>Vennligst vent..</span>
                              <Loader />
                            </FetchingNewDataLoader>
                          );
                        } else {
                          return (
                            <Button
                              primary="true"
                              onClick={() =>
                                search({
                                  variables: {
                                    input: {
                                      sguid: match.params.sguid,
                                      view:
                                        type === 'interessenter'
                                          ? 'INTERESTED'
                                          : 'BUYER',
                                      query: null,
                                      currentPage: currentPage,
                                      itemsPerPage: 10,
                                    },
                                  },
                                })
                              }
                            >
                              Oppdater liste
                            </Button>
                          );
                        }
                      }}
                    </Query>
                  </ActionColumn>
                </ActionRow>
                <SearchFieldInteressenter
                  value={searchQuery}
                  onSubmit={() => null}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (currentPage !== 0) {
                      setCurrentPage(0);
                    }
                  }}
                  placeholder={
                    type === 'interessenter'
                      ? 'Søk i interessenter'
                      : 'Søk i kjøpere'
                  }
                />
                {interessentLoading && (
                  <LoaderWrapper
                    type={type === 'interessenter' ? 'INTERESTED' : 'BUYER'}
                  />
                )}
                {interessentData && (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeadCol>Fornavn</TableHeadCol>
                          <TableHeadCol>Etternavn</TableHeadCol>
                          <TableHeadCol>Email</TableHeadCol>
                          <TableHeadCol>Mobil</TableHeadCol>
                          {type === 'interessenter' ? (
                            <TableHeadCol>Registrert</TableHeadCol>
                          ) : null}
                          {type === 'interessenter' ? (
                            <TableHeadCol>Avmeldt</TableHeadCol>
                          ) : null}
                          <TableHeadCol>Opprettet</TableHeadCol>
                          {type === 'interessenter' ? (
                            <TableHeadCol></TableHeadCol>
                          ) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {interessentData &&
                          interessentData.getInterested &&
                          interessentData.getInterested.list &&
                          interessentData.getInterested.list.map(
                            (item, index) => (
                              <InterestedRow
                                key={`interested_${index}`}
                                type={type}
                                match={match}
                                item={item}
                                currentPage={currentPage}
                              />
                            )
                          )}
                      </TableBody>
                    </Table>
                    <Pagination
                      pages={Math.ceil(
                        interessentData &&
                          interessentData.getInterested &&
                          interessentData.getInterested.total / 10
                      )}
                      currentPage={currentPage}
                      onClick={(page) => {
                        setCurrentPage(page);
                      }}
                    />
                  </>
                )}
              </Content>
            );
          }}
        </Query>
      )}
    </ApolloConsumer>
  );
};

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionColumn = styled.div``;

const FetchingNewDataLoader = styled.div`
  display: flex;
  align-items: center;
  color: white;
  height: 48px;
  span {
    margin-right: 20px;
  }
`;

const NoItems = styled.div`
  padding: 20px 0;
  background: rgb(20, 20, 20);
  font-size: 18px;
  font-weight: 300;
  color: white;
`;

const LoaderWrapper = ({ type }) => {
  return (
    <StyledLoaderWrapper>
      <Loader />
      {type === 'INTERESTED' ? (
        <span>Henter interessenter..</span>
      ) : (
        <span>Henter kjøpere..</span>
      )}
    </StyledLoaderWrapper>
  );
};

const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 0;
  width: 100%;
  color: white;

  div {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 20px;
  }
`;

const NewsletterList = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const NoNewsletters = styled.div`
  padding: 10px;
  background: rgb(20, 20, 20);
  font-size: 18px;
  font-weight: 300;
  color: white;
`;

const InteresetedCount = styled.p`
  display: inline-block;
  font-size: 18px;
  color: white;
  margin-left: 10px;

  &:first-child {
    padding: 0 10px;
  }
`;

const ProjectTitle = styled.h1`
  margin-bottom: 10px;
`;
const NewsletterHeading = styled.h2`
  margin-bottom: 10px;
`;
const ProjectOppdragsnummer = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: white;

  .sguid {
    &:hover {
      cursor: pointer;
    }
  }

  .copy-icon {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    fill: ${(props) => props.theme.primary};
    margin-left: 5px;
  }
`;
