import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring/web.cjs';
import { Button } from '../Button';
import { ModalConfirm } from '../Modals';

const ButtonGroup = styled.div``;

export const ConfirmActionModal = ({
  show,
  text,
  immediate,
  acceptButtonText = 'Ja',
  cancelButtonText = 'Nei',
  onAccept,
  onCancel,
  children,
  onClickOutside
}) => {
  if (typeof window !== 'undefined') {
    return createPortal(
      <ModalConfirm show={show}>
        {!children ? (
          <Fragment>
            <h2>{text}</h2>
            <ButtonGroup>
              <Button
                style={{ marginRight: '20px' }}
                primary="true"
                onClick={onAccept}
                marginRight="20px"
              >
                {acceptButtonText}
              </Button>
              <Button onClick={onCancel}>{cancelButtonText}</Button>
            </ButtonGroup>
          </Fragment>
        ) : (
          children
        )}
      </ModalConfirm>,
      document.getElementById('modals')
    );
  } else {
    return null;
  }
};
