import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { animated, useSpring, interpolate } from 'react-spring/web.cjs';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { ChromePicker } from 'react-color';

import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { ApolloConsumer } from '@apollo/client';
import * as compose from 'lodash/flowRight';

import { ModalError } from '../ui/ModalError';
import { addNotification } from '../utils/notification';
import { ConfirmActionModal } from '../ui/Modals/ConfirmAction';
import { Input } from '../ui/Input';

import {
  SET_EDIT_MODE_MUTATION,
  SAVE_NEWSLETTER_MUTATION,
  POST_NEWSLETTER_MUTATION,
  TEST_NEWSLETTER_MUTATION,
  TOGGLE_PREVIEW_MUTATION,
  TOGGLE_SHOW_TEMPLATES_MUTATION,
  DELETE_NEWSLETTER_MUTATION,
  SAVE_NEWSLETTER_DATA_MUTATION,
} from '../../../client/__graphql__/mutations';

import {
  EDIT_MODE_QUERY,
  SHOW_TEMPLATES_STATE_QUERY,
  GET_NUMBER_OF_RECEIVERS,
  GET_NEWSLETTER_POSTED,
  GET_NEWSLETTER_DATA_QUERY,
} from '../../../client/__graphql__/queries';

import {
  LockIcon,
  PreviewIcon,
  DeleteIcon,
  PostIcon,
  MailIcon,
  TemplateIcon,
  SettingsIcon,
  StatisticsIcon,
  PaletteIcon,
  LayoutIcon,
} from '../ui/Icons';

import { Button, ButtonGroup } from '../ui/Button';
import { ToolsWrapper } from './editor/ui/tools/ToolsWrapper';
import { EyeDropperButton } from './editor/ui/tools/EyeDropper';
import { SelectReceiversModal } from '../ui/Modals/SelectReceivers';
import { defaultsQuery } from '../../../client/__graphql__/defaults';

const ElementSelected = styled.div`
  width: 100%;
  padding: 20px;
  float: left;
  border-top: solid 4px rgb(230, 230, 230);
  background: rgb(255, 255, 255);
  font-size: 12px;
  color: rgb(150, 150, 150);
`;

function updateElementColor(opts) {
  opts.edit.elements.map((item) => {
    const prevData = opts.client.readQuery({
      query: GET_NEWSLETTER_DATA_QUERY,
      variables: {
        newsletterId: opts.newsletterId,
        elementId: item.id,
      },
    });
    const newData = Object.assign({}, prevData.getNewsletterData, {
      [opts.edit.colorType]: opts.color,
    });

    opts.client.writeQuery({
      query: GET_NEWSLETTER_DATA_QUERY,
      variables: {
        newsletterId: opts.newsletterId,
        elementId: item.id,
      },
      data: {
        getNewsletterData: newData,
      },
    });
  });
}

function saveElementColor(opts) {
  opts.edit.elements.map((item) => {
    opts.client.mutate({
      mutation: SAVE_NEWSLETTER_DATA_MUTATION,
      variables: {
        newsletterId: opts.newsletterId,
        elementId: item.id,
        field: opts.edit.colorType,
        data: opts.edit.color,
      },
    });
  });
}

const SidebarModeSection = compose(withApollo)(
  ({ match, history, client, edit }) => {
    const [color, setColor] = useState('');
    useEffect(() => {
      if (edit.mode === 1) {
        client.writeQuery({
          query: defaultsQuery,
          data: {
            edit: {
              ...edit,
              __typename: 'Edit',
              mode: 1,
              element: '',
              startColor: '',
              colorType: '',
              color: '',
            },
          },
        });
      }
    }, [edit.mode]);

    const getEditMode = (mode) => {
      if (mode === 1) {
        return 'innhold';
      }
      if (mode === 2) {
        return 'farger';
      }
      if (mode === 3) {
        return 'layout';
      }
      return '';
    };

    return (
      <Mutation
        mutation={SET_EDIT_MODE_MUTATION}
        update={(
          cache,
          {
            data: {
              setEditMode: { ...edit },
            },
          }
        ) => {
          addNotification(cache, {
            type: 'success',
            message: `Endret modus til ${getEditMode(edit.editMode)}`,
          });
        }}
      >
        {(setActive) => (
          <SidebarModeWrapper>
            <SidebarModeHeading>Modus</SidebarModeHeading>
            <SidebarModeInsideWrapper>
              <SidebarModeButton
                active={edit.mode === 1 ? 1 : 0}
                onClick={() => setActive({ variables: { mode: 1 } })}
              >
                <TemplateIcon className="icon" />
              </SidebarModeButton>
              <SidebarModeButton
                active={edit.mode === 2 ? 1 : 0}
                onClick={() => setActive({ variables: { mode: 2 } })}
              >
                <PaletteIcon className="palette" />
              </SidebarModeButton>
              <SidebarModeButton
                active={edit.mode === 3 ? 1 : 0}
                onClick={() => setActive({ variables: { mode: 3 } })}
              >
                <LayoutIcon className="layout" />
              </SidebarModeButton>
            </SidebarModeInsideWrapper>

            {edit && edit.element && edit.elementName ? (
              <ElementSelected>
                Endrer farge: {edit.elementName}
              </ElementSelected>
            ) : null}
            {edit.mode === 2 && edit && edit.element !== '' ? (
              <>
                <ChromePicker
                  disableAlpha={true}
                  color={edit.color}
                  onChange={({ hex }) => {
                    const data = client.readQuery({ query: defaultsQuery });
                    client.writeQuery({
                      query: defaultsQuery,
                      data: {
                        edit: {
                          ...data.edit,
                          __typename: 'Edit',
                          color: hex,
                          saved: false,
                        },
                      },
                    });

                    /*
                    Get prevdata for element and update with new
                  */

                    updateElementColor({
                      client,
                      edit,
                      newsletterId: match.params.newsletterId,
                      color: hex,
                    });

                    setColor(hex);
                  }}
                  onChangeComplete={({ hex }) => {
                    saveElementColor({
                      client,
                      edit,
                      newsletterId: match.params.newsletterId,
                      color: hex,
                    });

                    setColor(hex);
                  }}
                />
              </>
            ) : null}
            {edit.mode === 2 && edit && edit.element === '' ? (
              <NoElementSelected>
                Velg et element for å endre farge <b>(trykk på malepensel)</b>
              </NoElementSelected>
            ) : null}
            {edit.mode === 3 ? (
              <NoElementSelected>
                Legg til / fjern elementer i malen etter behov.{' '}
                <b>(trykk på pluss og minus)</b>
              </NoElementSelected>
            ) : null}
          </SidebarModeWrapper>
        )}
      </Mutation>
    );
  }
);

export const Sidebar = withRouter(
  ({ match, getNewsletter, preview, history }) => {
    const [showConfirmPost, toggleShowConfirmPost] = useState(false);
    const [showConfirmTest, toggleShowSendTest] = useState(false);
    const [showConfirmDelete, toggleShowDeleteConfirm] = useState(false);
    const [email, setEmail] = useState('');

    return (
      <ApolloConsumer>
        {(client) => (
          <Query query={EDIT_MODE_QUERY} ssr={false}>
            {({ data, loading, error }) => {
              let edit = data?.edit;
              if (!edit) {
                return null;
              }
              return (
                <StyledSidebar>
                  <Mutation
                    mutation={DELETE_NEWSLETTER_MUTATION}
                    onCompleted={(data) => {
                      if (
                        data &&
                        data.deleteNewsletter &&
                        data.deleteNewsletter.success === true
                      ) {
                        toggleShowDeleteConfirm(false);
                        setTimeout(() => {
                          history.replace(`/prosjekt/${match.params.sguid}`);
                        }, 250);
                      }
                    }}
                    update={(cache) => {
                      addNotification(cache, {
                        type: 'success',
                        message: 'Nyhetsbrevet ble slettet.',
                      });
                    }}
                  >
                    {(del) => (
                      <ConfirmActionModal
                        show={showConfirmDelete ? 1 : 0}
                        text="Ønsker du å slette dette nyhetsbrevet?"
                        onAccept={() => {
                          del({
                            variables: {
                              input: {
                                identifier: match.params.newsletterId,
                              },
                            },
                          });
                        }}
                        onCancel={() => toggleShowDeleteConfirm(false)}
                      />
                    )}
                  </Mutation>
                  <Mutation
                    mutation={SAVE_NEWSLETTER_MUTATION}
                    update={(cache) => {
                      addNotification(cache, {
                        type: 'success',
                        message: 'Nyhetsbrevet ble lagret.',
                      });
                    }}
                  >
                    {(save, { loading, error }) => (
                      <Query
                        query={GET_NEWSLETTER_POSTED}
                        variables={{ id: match.params.newsletterId }}
                        //pollInterval={10000}
                      >
                        {({ data: { posted } = {}, loading, error }) => {
                          return (
                            <Query
                              query={GET_NUMBER_OF_RECEIVERS}
                              fetchPolicy="network-only"
                              ssr={false}
                              variables={{ sguid: match.params.sguid }}
                            >
                              {({ data, loading, error }) => {
                                if (loading) {
                                  return null;
                                }
                                return (
                                  <Fragment>
                                    {posted && posted.posted ? (
                                      <Published>
                                        Nyhetsbrevet er sendt
                                      </Published>
                                    ) : null}
                                    <NumberOfInterested>
                                      <NumberOfInterestedRow>
                                        {loading ? (
                                          <div>
                                            <span>Laster..</span>
                                          </div>
                                        ) : (
                                          <>
                                            <span>
                                              {data?.getReceiversCount &&
                                              data.getReceiversCount
                                                .interested > 0
                                                ? data.getReceiversCount
                                                    .interested
                                                : 0}
                                            </span>
                                            <span>
                                              {data?.getReceiversCount &&
                                              data.getReceiversCount
                                                .interested > 0 &&
                                              data.getReceiversCount
                                                .interested > 1
                                                ? 'interessenter'
                                                : data.getReceiversCount
                                                    .interested > 0
                                                ? 'interessent'
                                                : 'interessenter'}{' '}
                                            </span>
                                          </>
                                        )}
                                      </NumberOfInterestedRow>
                                      {/*<NumberOfInterestedRow>
                                      <LinkToInterested>
                                        Se interessenter
                                      </LinkToInterested>
                                    </NumberOfInterestedRow>*/}
                                    </NumberOfInterested>
                                    <NumberOfInterested>
                                      <NumberOfInterestedRow>
                                        {loading ? (
                                          <div>
                                            <span>Laster..</span>
                                          </div>
                                        ) : (
                                          <>
                                            <span>
                                              {data.getReceiversCount &&
                                              data.getReceiversCount.buyers > 0
                                                ? data.getReceiversCount.buyers
                                                : 0}
                                            </span>
                                            <span>
                                              {(data.getReceiversCount &&
                                                data.getReceiversCount.buyers >
                                                  1) ||
                                              (data.getReceiversCount &&
                                                data.getReceiversCount
                                                  .buyers === 0)
                                                ? 'kjøpere'
                                                : 'kjøper'}
                                            </span>
                                          </>
                                        )}
                                      </NumberOfInterestedRow>
                                    </NumberOfInterested>
                                    <SidebarButtonWrapper>
                                      <SidebarModeSection
                                        edit={edit}
                                        match={match}
                                      />
                                    </SidebarButtonWrapper>
                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <SelectReceiversModal
                                        show={showConfirmPost ? 1 : 0}
                                        sguid={match.params.sguid}
                                        onClose={toggleShowConfirmPost}
                                      />
                                    ) : null}
                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <SidebarButton
                                        primary="true"
                                        className="post"
                                        active={true}
                                        onClick={async () => {
                                          if (!getNewsletter.subject) {
                                            addNotification(client, {
                                              type: 'error',
                                              message: `Nyhetsbrevet må ha et emne.`,
                                            });
                                          } else {
                                            toggleShowConfirmPost(true);
                                            await client.mutate({
                                              mutation: SAVE_NEWSLETTER_MUTATION,
                                              variables: {
                                                input: {
                                                  identifier:
                                                    match.params.newsletterId,
                                                  name: getNewsletter.name,
                                                  subject:
                                                    getNewsletter.subject,
                                                },
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        {!getNewsletter.subject ? (
                                          <LockIcon
                                            className="icon"
                                            fill="white"
                                          />
                                        ) : (
                                          <PostIcon
                                            className="icon"
                                            fill="white"
                                          />
                                        )}
                                        Send nyhetsbrev
                                      </SidebarButton>
                                    ) : null}

                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <Mutation
                                        mutation={TEST_NEWSLETTER_MUTATION}
                                        onCompleted={(data) => {
                                          toggleShowSendTest(false);
                                          setEmail('');
                                        }}
                                        update={(cache) => {
                                          addNotification(cache, {
                                            type: 'success',
                                            message: `Nyhetsbrevet ble sendt til ${email}`,
                                          });
                                        }}
                                      >
                                        {(post, { error }) => (
                                          <ConfirmActionModal
                                            style={{ maxWidth: '350px' }}
                                            show={showConfirmTest ? 1 : 0}
                                          >
                                            <h2>Send en test-epost</h2>
                                            {error &&
                                              error.graphQLErrors.map(
                                                (item, index) => (
                                                  <ModalError key={index}>
                                                    {item.message}
                                                  </ModalError>
                                                )
                                              )}
                                            <Input
                                              style={{ maxWidth: '350px' }}
                                              placeholder="E-post"
                                              value={email}
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                            />
                                            <ButtonGroup>
                                              <Button
                                                onClick={() => {
                                                  toggleShowSendTest(false);
                                                  setEmail('');
                                                }}
                                              >
                                                Avbryt
                                              </Button>
                                              <Button
                                                className="check-active"
                                                primary={email ? 'true' : null}
                                                active={email}
                                                onClick={async () => {
                                                  await client.mutate({
                                                    mutation: SAVE_NEWSLETTER_MUTATION,
                                                    variables: {
                                                      input: {
                                                        identifier:
                                                          match.params
                                                            .newsletterId,
                                                        name:
                                                          getNewsletter.name,
                                                        subject:
                                                          getNewsletter.subject,
                                                      },
                                                    },
                                                  });

                                                  post({
                                                    variables: {
                                                      input: {
                                                        id:
                                                          match.params
                                                            .newsletterId,
                                                        email,
                                                      },
                                                    },
                                                  });
                                                }}
                                              >
                                                {!email ? (
                                                  <LockIcon className="button-icon" />
                                                ) : null}
                                                Send
                                              </Button>
                                            </ButtonGroup>
                                          </ConfirmActionModal>
                                        )}
                                      </Mutation>
                                    ) : null}
                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <SidebarButton
                                        onClick={() => toggleShowSendTest(true)}
                                        active={true}
                                      >
                                        <PostIcon
                                          className="icon"
                                          fill="white"
                                        />
                                        Send en test
                                      </SidebarButton>
                                    ) : null}
                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <Mutation
                                        mutation={TOGGLE_PREVIEW_MUTATION}
                                      >
                                        {(toggle) => (
                                          <SidebarButton
                                            active="true"
                                            onClick={() => {
                                              toggle();
                                            }}
                                          >
                                            <PreviewIcon
                                              className="icon"
                                              fill="white"
                                            />
                                            {preview
                                              ? 'Lukk'
                                              : 'Forhåndsvisning'}
                                          </SidebarButton>
                                        )}
                                      </Mutation>
                                    ) : null}
                                    {getNewsletter &&
                                    getNewsletter.hasTemplate === true &&
                                    edit.mode !== 2 &&
                                    edit.mode !== 3 ? (
                                      <SidebarButton
                                        onClick={() => {
                                          history.push(
                                            `${location.pathname}/statistikk`
                                          );
                                        }}
                                        active={true}
                                      >
                                        <StatisticsIcon
                                          className="icon"
                                          fill="white"
                                        />
                                        Statistikk
                                      </SidebarButton>
                                    ) : null}
                                    {edit.mode !== 2 && edit.mode !== 3 ? (
                                      <Query query={SHOW_TEMPLATES_STATE_QUERY}>
                                        {({ data, loading }) => (
                                          <Mutation
                                            mutation={
                                              TOGGLE_SHOW_TEMPLATES_MUTATION
                                            }
                                            refetchQueries={['showTemplates']}
                                          >
                                            {(toggle) => (
                                              <SidebarButton
                                                style={{ marginTop: '10px' }}
                                                active="true"
                                                save="true"
                                                onClick={() => toggle()}
                                              >
                                                <TemplateIcon
                                                  className="icon"
                                                  fill="white"
                                                />
                                                {data.showTemplates
                                                  ? 'Lukk'
                                                  : 'Endre mal'}
                                              </SidebarButton>
                                            )}
                                          </Mutation>
                                        )}
                                      </Query>
                                    ) : null}
                                    {edit.mode !== 2 && edit.mode !== 3 ? (
                                      <SidebarButton
                                        className="delete"
                                        delete="true"
                                        active="true"
                                        onClick={() => {
                                          toggleShowDeleteConfirm(true);
                                        }}
                                      >
                                        <DeleteIcon
                                          className="icon"
                                          fill="white"
                                        />
                                        Slett
                                      </SidebarButton>
                                    ) : null}
                                  </Fragment>
                                );
                              }}
                            </Query>
                          );
                        }}
                      </Query>
                    )}
                  </Mutation>
                </StyledSidebar>
              );
            }}
          </Query>
        )}
      </ApolloConsumer>
    );
  }
);

const SidebarModeWrapper = styled.div`
  width: 100%;
  float: left;
  user-select: none;

  .chrome-picker {
    width: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    float: left !important;
  }
`;

const SidebarModeInsideWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

const SidebarModeButton = styled.button`
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background: ${(props) =>
    props.active === 1 ? 'rgb(255, 255, 255)' : 'rgb(230, 230, 230)'};
  color: ${(props) =>
    props.active === 1 ? 'rgb(150, 150, 150)' : 'rgb(160, 160, 160)'};
  border: none;
  outline: none;
  font-size: 12px;
  float: left;
  pointer-events: ${(props) => (props.active === 1 ? 'none' : 'auto')};

  &:hover {
    cursor: pointer;
  }

  span {
    margin-left: 5px;
  }

  .icon {
    fill: rgb(150, 150, 150);
    width: 20px;
    margin-left: 7px;
    margin-right: 5px;
  }

  .palette {
    transition: opacity 0.2s ease-in-out;
    width: 35px;
    height: 35px;
    opacity: ${(props) => (props.active === 1 ? '1' : '0.5')};
  }

  .layout {
    transition: opacity 0.2s ease-in-out;
    width: 25px;
    height: 25px;
    opacity: ${(props) => (props.active === 1 ? '1' : '0.5')};
  }
`;

const SidebarColorButton = styled(SidebarModeButton)`
  background: ${(props) =>
    props.active === 1 ? '#18a0fb' : 'rgb(40, 40, 40)'};
  color: ${(props) => (props.active === 1 ? 'white' : 'rgb(160, 160, 160)')};
  pointer-events: ${(props) => (props.active === 1 ? 'auto' : 'none')};
  border-right: solid 1px
    ${(props) => (props.active ? darken(0.15, '#18a0fb') : 'rgb(50, 50, 50)')};

  &.cancel {
    background: ${(props) =>
      props.active === 1 ? darken(0.1, '#18a0fb') : 'rgb(40, 40, 40)'};
  }
`;

const SidebarModeHeading = styled.div`
  padding: 10px 15px;
  width: 100%;
  display: block;
  background: rgb(255, 255, 255);
  border-top: solid 4px rgb(230, 230, 230);
  border-bottom: solid 1px rgb(230, 230, 230);
  color: rgb(150, 150, 150);
  font-size: 14px;
`;

const NoElementSelected = styled.div`
  width: 100%;
  padding: 20px;
  float: left;
  border-top: solid 4px rgb(230, 230, 230);
  background: rgb(255, 255, 255);
  font-size: 12px;
  color: rgb(150, 150, 150);
`;

const StyledSidebar = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 200px;
  height: 100vh;
  float: left;
  background: black;
  z-index: 1;
`;

const LinkToInterested = styled.span`
  font-size: 12px !important;
  text-decoration: underline;
  color: rgb(150, 150, 150) !important;
`;

const NumberOfInterestedRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const NumberOfInterested = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px 15px;
  width: 200px;
  background: white;
  border-bottom: solid 2px rgb(230, 230, 230);

  &:last-child {
    border-bottom: none;
  }

  span {
    color: rgb(150, 150, 150);

    &:first-child {
      font-size: 18px;
      font-weight: 400;
      margin-right: 5px;
    }
    &:last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const SidebarButton = styled.button`
  transition: all 0.2s ease-in-out;
  position: relative;
  margin: 0;
  width: 100%;
  padding: 15px 20px 15px 45px;
  background: rgb(20, 20, 20);
  border: none;
  border-bottom: solid 1px rgb(35, 35, 35);
  font-size: 14px;
  font-weight: 400;
  color: white;
  outline: none;
  text-align: left;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};

  &.post {
    background: ${lighten('0.1', 'rgb(20, 20, 20)')};
    border-top: solid 4px rgb(230, 230, 230);
    border-bottom: solid 1px ${lighten('0.1', 'rgb(35, 35, 35)')};
  }

  &.save {
    background: rgb(255, 255, 255);
    border-top: solid 4px rgb(230, 230, 230);
    border-bottom: none;
    color: rgb(150, 150, 150);
  }

  &.delete {
    background: rgb(210, 30, 0);
    color: white;
    border-bottom: none;
  }

  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 18px;
    width: 12px;
    margin-right: 10px;

    &.w15 {
      width: 15px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const SidebarButtonWrapper = styled.div`
  width: 100%;
`;

const Published = styled.div`
  width: 100%;
  padding: 15px 15px;
  background: rgb(220, 220, 220);
  color: rgb(100, 100, 100);
  font-size: 14px;
  font-weight: 400;

  .subtext {
    font-size: 12px;
    text-decoration: underline;
  }
`;
