import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';

import { addElement } from '../../../../utils/query-helpers';
import { GET_NEWSLETTER_DATA_QUERY } from '../../../../../../client/__graphql__/queries';
import { AddElementButton } from './AddElement';

export const AddListElementWrapper = withApollo(
  ({ client, item, newsletterId }) => {
    const [show, setShow] = useState(false);
    const checkHidden = async (cb) => {
      const hiddenElements = [];
      for (let element of item.children) {
        const {
          data: { getNewsletterData: elementData },
        } = await client.query({
          query: GET_NEWSLETTER_DATA_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            newsletterId: newsletterId,
            elementId: element.id,
          },
        });
        if (
          elementData &&
          elementData.state &&
          elementData.state.show === false
        ) {
          hiddenElements.push(elementData);
        }
      }
      if (hiddenElements && hiddenElements.length > 0) {
        setShow(true);
      } else {
        setShow(false);
      }
      if (typeof cb === 'function') {
        cb(hiddenElements || []);
      }
    };

    useEffect(() => {
      checkHidden();
    }, []);

    useEffect(() => {
      checkHidden();
    }, [item.children]);

    return (
      <ListWrapper>
        <AddElementButton
          className="relative"
          label="Legg til"
          children={item.children}
          checkChildrenState={true}
          newsletterId={newsletterId}
          elementId={item.id}
          onClick={() => {
            checkHidden((hiddenElements) => {
              if (hiddenElements && hiddenElements.length > 0) {
                const element = hiddenElements[0];
                const newState = Object.assign({}, element, {
                  state: {
                    show: true,
                    __typename: 'NewsletterDataState',
                  },
                });

                client.writeQuery({
                  query: GET_NEWSLETTER_DATA_QUERY,
                  variables: {
                    newsletterId: newsletterId,
                    elementId: element.id,
                  },
                  data: {
                    getNewsletterData: newState,
                  },
                });
                addElement(client, {
                  input: {
                    newsletterId: newsletterId,
                    elementId: element.id,
                  },
                });
              }
            });
          }}
        />
      </ListWrapper>
    );
  }
);

const ListWrapper = styled.div`
  width: 100%;
`;
