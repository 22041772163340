import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring/web.cjs';

const StyledModalOverlay = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 9997;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
`;

const StyledModal = styled(animated.div)`
  position: fixed;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 20px;
  width: 100%;
  max-width: 425px;
  height: 300px;
  pointer-events: auto;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: ${props => (props.show ? 9999 : 9998)};

  p {
    margin-bottom: 20px;
  }

  &.gallery {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    height: 100vh;
    text-align: center;
  }

  &.add-interested-modal {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }

  &.button {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 50%;
    padding: 20px;
    height: 100vh;
    text-align: center;
  }
`;

const StyledModalConfirm = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 20px;
  width: 500px;
  height: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  color: white;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 700px) {
    width: 90%;
  }
`;

export const Modal = ({
  show,
  immediate,
  children,
  className,
  onClickClose,
  onClickOutside
}) => {
  const [showModal, setShow] = useState(false);
  const modalProps = useSpring({
    immediate: immediate,
    from: { opacity: 0, zIndex: 9997 },
    to: { opacity: show == 1 ? 1 : 0, zIndex: show == 1 ? 9998 : 9997 },
    config: {
      duration: 250
    }
  });

  const modalOverlayprops = useSpring({
    immediate: immediate,
    from: { opacity: 0, zIndex: 9995 },
    to: { opacity: show == 1 ? 1 : 0, zIndex: show == 1 ? 9996 : 9995 },
    config: {
      duration: 250
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let modal = document.getElementById('modals');
      let root = document.getElementById('root');
      if (show) {
        modal.classList.add('show');
        root.classList.add('hide');
        setShow(true);
      } else {
        modal.classList.remove('show');
        root.classList.remove('hide');
        setShow(false);
      }
    }
  }, [show]);

  if (typeof window !== 'undefined') {
    return createPortal(
      <>
        <StyledModal
          style={modalProps}
          className={showModal ? `${className} show` : className}
          show={show}
        >
          {children}
        </StyledModal>
        <StyledModalOverlay
          style={modalOverlayprops}
          show={show}
          onClick={onClickOutside}
        />
      </>,
      document.getElementById('modals')
    );
  } else {
    return null;
  }
};

export const ModalConfirm = ({
  show,
  immediate,
  children,
  className,
  onClickClose,
  onClickOutside,
  hideClose
}) => {
  const [showModal, setShow] = useState(false);
  const modalProps = useSpring({
    immediate: immediate,
    from: { opacity: 0, zIndex: 9997 },
    to: { opacity: show == 1 ? 1 : 0, zIndex: show == 1 ? 9998 : 9997 }
  });

  const modalOverlayprops = useSpring({
    immediate: immediate,
    from: { opacity: 0, zIndex: 9995 },
    to: { opacity: show == 1 ? 1 : 0, zIndex: show == 1 ? 9996 : 9995 }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let modal = document.getElementById('modals');
      let root = document.getElementById('root');
      if (show) {
        modal.classList.add('show');
        root.classList.add('hide');
        setShow(true);
      } else {
        modal.classList.remove('show');
        root.classList.remove('hide');
        setShow(false);
      }
    }
  }, [show]);

  return createPortal(
    <Fragment>
      <StyledModalConfirm
        style={modalProps}
        show={show}
        className={showModal ? 'show' : ''}
      >
        {children}
      </StyledModalConfirm>
      <StyledModalOverlay
        style={modalOverlayprops}
        show={show}
        onClick={onClickOutside}
      />
    </Fragment>,
    document.getElementById('modals')
  );
};
