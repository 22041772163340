import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';

import { Content } from '../layout/Content';
import {
  GET_PROJECTS_QUERY,
  AUTHENTICATED_CACHE,
} from '../../../client/__graphql__/queries';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Pagination } from '../ui/Pagination';
import { PageLoader, Loader } from '../ui/Loader';
import { ListItem } from '../ui/ListItem';
import { CopyIcon } from '../ui/Icons';
import { LinkButton } from '../ui/Button';
import { SearchField } from '../ui/Search';
import { addNotification } from '../utils/notification';

const NewsletterList = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const NoNewsletters = styled.div`
  padding: 20px 0;
  font-weight: 300;
  color: white;
`;

const NewsletterCreated = styled.div`
  margin-top: 10px;
  display: inline-flex;
  flex-flow: column;
  margin-right: 20px;

  span {
    &:first-child {
      font-size: 14px;
      font-weight: 300;
      color: rgb(150, 150, 150);
    }
    &:last-child {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 300;
    }
  }
`;

const ListItemInfo = styled.div`
  color: white;
  margin-bottom: 10px;
`;

const ProjectCount = styled.div`
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: white;

  span {
    margin-left: 15px;
  }
`;

export const Overview = ({ match, location, history }) => {
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initalSearch, setInitialSearch] = useState(true);

  return (
    <ApolloConsumer>
      {(client) => (
        <Query query={AUTHENTICATED_CACHE} fetchPolicy="cache-only">
          {({ data, loading: l, error }) => {
            return (
              <Content scroll="true" padding="20px 35px 20px 20px">
                {data?.authenticated && data.authenticated.thirdParty ? (
                  <h1 className="heading">Alle tilgjengelige prosjekter</h1>
                ) : (
                  <h1 className="heading">Alle prosjekter</h1>
                )}
                {data?.authenticated && data.authenticated.thirdParty ? (
                  <p>En oversikt over alle prosjekter</p>
                ) : (
                  <p>En oversikt over alle dine prosjekter</p>
                )}
                <SearchField
                  thirdParty={
                    data?.authenticated && data.authenticated.thirdParty
                  }
                  currentPage={currentPage}
                  initalSearch={initalSearch}
                  setInitialSearch={setInitialSearch}
                  isLoading={() => {
                    setLoading(true);
                  }}
                  onResult={({ projectSearch }) => {
                    if (
                      projectSearch &&
                      projectSearch.result &&
                      projectSearch.result.items
                    ) {
                      setTotal(projectSearch.result.total);
                      setPages(projectSearch.result.pages);
                      setProjects(projectSearch.result.items);
                      setLoading(false);
                    } else {
                      setProjects([]);
                      setLoading(false);
                    }
                  }}
                >
                  <ProjectCount>
                    {loading ? <Loader /> : null}
                    <span>Antall prosjekter: {total}</span>
                  </ProjectCount>
                </SearchField>
                {loading ? null : projects && projects.length ? (
                  <Fragment>
                    <NewsletterList>
                      {projects.map((item, index) => (
                        <ListItem key={index}>
                          <div className="column">
                            <h2>{item.address}</h2>
                            <ListItemInfo>
                              {item.zip} {item.place}
                            </ListItemInfo>
                            <div className="row">
                              <span className="info-column">
                                <span className="label">Oppdragsnummer</span>
                                <CopyToClipboard
                                  key={index}
                                  text={item.sguid}
                                  onCopy={() => {
                                    addNotification(client, {
                                      type: 'success',
                                      message: 'Oppdragsnummer ble kopiert',
                                    });
                                  }}
                                >
                                  <span className="value link">
                                    {item.sguid}{' '}
                                    <CopyIcon className="copy-icon" />
                                  </span>
                                </CopyToClipboard>
                              </span>
                              <span className="info-column">
                                <span className="label">Interessenter</span>
                                <span className="value">{item.interested}</span>
                              </span>
                              <span className="info-column">
                                <span className="label">Kjøpere</span>
                                <span className="value">{item.buyers}</span>
                              </span>
                            </div>
                          </div>
                          <div className="column">
                            <LinkButton
                              primary={true}
                              to={`/prosjekt/${item.sguid}`}
                            >
                              Gå til prosjekt
                            </LinkButton>
                          </div>
                        </ListItem>
                      ))}
                    </NewsletterList>
                    <Pagination
                      pages={pages}
                      currentPage={currentPage}
                      onClick={(page) => {
                        setCurrentPage(page);
                      }}
                    />
                  </Fragment>
                ) : null}
              </Content>
            );
          }}
        </Query>
      )}
    </ApolloConsumer>
  );
};
