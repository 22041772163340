import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { AUTHENTICATED } from '../../../client/__graphql__/queries';

import { Header } from './Header';
import { BreadCrumbs } from '../ui/BreadCrumbs';

export const PageLayout = ({ match, location, history, children }) => {
  return (
    <ApolloConsumer>
      {(client) => (
        <Query query={AUTHENTICATED} fetchPolicy="network-only">
          {({ data, loading, error }) => {
            if (loading) {
              return null;
            } else if (!data.authenticated.loggedIn) {
              client.resetStore();
              return <Redirect to="/?logged_out=true" />;
            } else {
              return (
                <Query query={AUTHENTICATED} pollInterval={5000}>
                  {() => (
                    <Fragment>
                      <Header history={history} />
                      <BreadCrumbs />
                      {children}
                    </Fragment>
                  )}
                </Query>
              );
            }
          }}
        </Query>
      )}
    </ApolloConsumer>
  );
};
