import React, { useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring, interpolate } from 'react-spring/web.cjs';
import { addNotification } from '../utils/notification';

import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash/flowRight';

import {
  UPLOAD_FILE,
  UPDATE_FIELD_MUTATION,
  SAVE_NEWSLETTER_MUTATION,
  TOGGLE_SHOW_TEMPLATES_MUTATION,
  SELECT_TEMPLATE_MUTATION,
} from '../../../client/__graphql__/mutations';

import {
  GET_NEWSLETTER_QUERY,
  PREVIEW_STATE_QUERY,
  SHOW_TEMPLATES_STATE_QUERY,
  GET_NEWSLETTER_TEMPLATES_QUERY,
  GET_TEMPLATE_QUERY,
} from '../../../client/__graphql__/queries';

const TemplateItem = ({ item, loading, onSelect }) => {
  return (
    <StyledTemplateItem>
      <StyledTemplateItemContent>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
        <TemplateButton
          selected={item.selected ? 1 : 0}
          onClick={() => {
            if (!item.selected) {
              onSelect(item);
            }
          }}
        >
          {loading
            ? 'Gjør klar mal. Vennligst vent..'
            : item.selected
            ? 'Valgt'
            : 'Velg'}
        </TemplateButton>
      </StyledTemplateItemContent>
    </StyledTemplateItem>
  );
};

export const TemplateSelection = compose(withApollo)(
  ({ client, refetch, newsletterId }) => {
    const [selectedIdentifier, setSelectedItem] = useState('');

    const { showTemplates } = client.readQuery({
      query: SHOW_TEMPLATES_STATE_QUERY,
    });

    const { xyz } = useSpring({
      from: { xyz: [0, 100, 0] },
      xyz: showTemplates ? [0, 0, 0] : [0, 100, 0],
    });

    const { y } = useSpring({
      delay: !showTemplates ? 0 : 700,
      from: { y: 0 },
      y: showTemplates ? 1 : 0,
    });

    const { x } = useSpring({
      immediate: !showTemplates,
      delay: 500,
      from: { x: 0 },
      x: showTemplates ? 1 : 0,
    });

    return (
      <Mutation
        mutation={SELECT_TEMPLATE_MUTATION}
        onCompleted={() => {
          setTimeout(() => {
            client.query({
              query: GET_TEMPLATE_QUERY,
              variables: { id: newsletterId },
              ssr: false,
              fetchPolicy: 'network-only',
            });
            client.query({
              query: GET_NEWSLETTER_QUERY,
              variables: { id: newsletterId },
              ssr: false,
              fetchPolicy: 'network-only',
            });
          }, 700);
        }}
        update={async (cache, { data: { selectTemplate } }) => {
          let data = cache.readQuery({
            query: GET_NEWSLETTER_TEMPLATES_QUERY,
            variables: { newsletterId, type: 1 },
          });
          data.getTemplates.map((item) => {
            const newItem = { ...item };
            if (newItem.identifier === selectTemplate.identifier) {
              newItem.selected = true;
            } else {
              newItem.selected = false;
            }
            return newItem;
          });
          cache.writeQuery({
            query: GET_NEWSLETTER_TEMPLATES_QUERY,
            data: {
              getTemplates: data.getTemplates,
            },
          });

          cache.writeQuery({
            query: SHOW_TEMPLATES_STATE_QUERY,
            data: {
              showTemplates: false,
            },
          });

          addNotification(cache, {
            type: 'success',
            message: `"${selectTemplate.name}" ble satt som mal for nyhetsbrevet`,
          });
        }}
      >
        {(selectTemplate, { loading: selectLoading, error }) => {
          return (
            <StyledTemplateSlide
              style={{
                transform: xyz.interpolate(
                  (x, y, z) => `translate3d(${x}px, -${y}vh, ${z}px)`
                ),
              }}
            >
              <StyledTemplateInsideWrapper
                style={{
                  opacity: x.interpolate((x) => `${x}`),
                }}
              >
                <CloseTemplatesButton
                  style={{
                    opacity: y.interpolate((y) => `${y}`),
                  }}
                  onClick={() => {
                    client.writeQuery({
                      query: SHOW_TEMPLATES_STATE_QUERY,
                      data: {
                        showTemplates: false,
                      },
                    });
                  }}
                >
                  Lukk
                </CloseTemplatesButton>
                <h1>Maler</h1>
                <p>
                  Finner du ingen mal? Ønsker du en skreddersydd mal for ditt
                  prosjekt? Send en henvendelse til{' '}
                  <a href="mailto:helpdesk@reeltime.no">helpdesk@reeltime.no</a>
                </p>
                <TemplateWrapper>
                  <Query
                    query={GET_NEWSLETTER_TEMPLATES_QUERY}
                    variables={{
                      newsletterId: newsletterId,
                      type: 1,
                    }}
                    fetchPolicy="cache-and-network"
                    ssr={false}
                  >
                    {({ data, loading, error }) => {
                      if (loading) {
                        return null;
                      }

                      if (error) {
                        return null;
                      }

                      return data.getTemplates.map((item, index) => {
                        return (
                          <TemplateItem
                            key={index}
                            item={item}
                            loading={
                              selectLoading &&
                              item.identifier === selectedIdentifier
                            }
                            onSelect={(selectedItem) => {
                              setSelectedItem(selectedItem.identifier);
                              selectTemplate({
                                variables: {
                                  newsletterId,
                                  templateId: item.identifier,
                                },
                              });
                            }}
                            key={`template-${index}`}
                          />
                        );
                      });
                    }}
                  </Query>
                </TemplateWrapper>
              </StyledTemplateInsideWrapper>
            </StyledTemplateSlide>
          );
        }}
      </Mutation>
    );
  }
);

const TemplateButton = styled.button`
  transition: all 0.2s ease-in-out;
  padding: 10px;
  background: none;
  border: none;
  background: ${(props) =>
    props.selected ? props.theme.primary : 'rgb(200, 200, 200)'};
  font-size: 14px;
  color: ${(props) => (props.selected ? 'rgb(0, 0, 0)' : 'rgb(150, 150, 150)')};
  border-radius: 2px;
  outline: none;
  margin-top: 10px;

  &:hover {
    cursor: ${(props) => (props.selected ? 'none' : 'pointer')};
  }
`;

const StyledTemplateSlide = styled(animated.div)`
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  background: rgb(180, 180, 180);
  overflow-y: auto;
  z-index: 1001;

  h1 {
    color: black;
  }

  p {
    color: black;
    font-weight: 300;

    a {
      font-weight: 600;
      color: inherit;
    }
  }
`;

const StyledTemplateInsideWrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 30px;
  width: 100%;
  float: left;
`;

const StyledTemplateItem = styled.div`
  width: calc((100% / 2) - 10px);
  flex: 1 0 auto;
  display: inline-flex;
  justify-content: space-between;
  padding: 30px;
  margin-bottom: 20px;
  background: rgb(230, 230, 230);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  p {
    color: rgb(50, 50, 50);
  }

  &:nth-of-type(even) {
    margin-left: 20px;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;

    &:nth-of-type(even) {
      margin-left: 0;
    }
  }
`;

const StyledTemplateItemContent = styled.div`
  padding: 0;
  width: calc(100% - 150px);

  h2 {
    margin: 0 0 10px 0;
    color: rgb(50, 50, 50);
  }
`;

const CloseTemplatesButton = styled(animated.button)`
  position: absolute;
  padding: 10px;
  background: rgb(230, 230, 230);
  z-index: 1000;
  top: 30px;
  right: 30px;
  font-size: 14px;
  color: rgb(150, 150, 150);
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;
