import styled from 'styled-components';

export const FormError = styled.div`
  width: 100%;
  padding: 10px;
  color: white;
  background: rgb(210, 30, 0);
  border-radius: 2px;
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 20px 0;
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormCol = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;
