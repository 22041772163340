import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring, interpolate } from 'react-spring/web.cjs';
import { Query, Mutation } from '@apollo/client/react/components';
import * as compose from 'lodash/flowRight';
import { withApollo } from '@apollo/client/react/hoc';

import {
  UPLOAD_FILE,
  UPDATE_FIELD_MUTATION,
  SAVE_NEWSLETTER_MUTATION,
  TOGGLE_SHOW_TEMPLATES_MUTATION,
  SELECT_TEMPLATE_MUTATION,
} from '../../../client/__graphql__/mutations';

import {
  GET_NEWSLETTER_QUERY,
  PREVIEW_STATE_QUERY,
  SHOW_TEMPLATES_STATE_QUERY,
  GET_NEWSLETTER_TEMPLATES_QUERY,
  GET_TEMPLATE_QUERY,
  EDIT_MODE_QUERY,
} from '../../../client/__graphql__/queries';

import { Content } from '../layout/Content';
import { Sidebar } from '../layout/Sidebar';
import { Editor } from '../layout/editor/Index';
import { TemplateSelection } from '../layout/TemplateSelection';

import { Input } from '../ui/Input';
import { InputSpacer } from '../ui/InputSpacer';
import {
  AddLinkIcon,
  LockIcon,
  PreviewIcon,
  SaveIcon,
  DeleteIcon,
  PostIcon,
  UploadIcon,
  MailIcon,
  TemplateIcon,
} from '../ui/Icons';

import { addNotification } from '../utils/notification';

import { EditImageLinkModal } from '../ui/Modals/AddImageLink';
import { ConfirmActionModal } from '../ui/Modals/ConfirmAction';
import { LinkButton, Button } from '../ui/Button';
import { defaultsQuery } from '../../../client/__graphql__/defaults';

const ContentWrapper = styled.div`
  position: relative;
  transition: background 0.2s ease-in-out;
  width: calc(100% - 200px);
  height: 100vh;
  float: left;
  background: rgb(200, 200, 200);
  overflow: hidden;

  .tip {
    padding: 10px;
    margin-top: 0;
    margin-bottom: 20px;
    background: none;
    font-size: 14px;
    font-weight: 400;
    color: rgb(100, 100, 100);
    background: rgb(210, 210, 210);
  }

  h1 {
    color: rgb(60, 60, 60);
    margin-bottom: 20px;
  }
`;

const NewsletterContent = styled.div`
  padding: 50px 20px;
  width: calc(100% + 1rem);
  height: calc(100% - 130px);
  overflow-y: scroll;
  float: left;
`;

const NewsletterWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 100px;

  h1 {
    margin-bottom: 20px;
  }
`;

const StyledNewsletter = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 800px;
`;

const NewsletterEditor = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 600px;
  background: ${(props) =>
    props.image
      ? `url(${props.image}) no-repeat rgb(30, 30, 30)`
      : 'rgb(30, 30, 30)'};
  background-size: contain;
  background-position: center;
`;

const NewsletterImageWrapper = styled.div`
  width: 100%;
  margin-bottom: -3px;
  float: left;

  img {
    width: 100%;
  }
`;

const NewsletterFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 70px;
  background: black;

  a {
    margin-top: -5px;
    font-size: 14px;
    text-decoration: none;
    color: rgb(200, 200, 200);

    &:last-child {
      margin-top: 5px;
      font-size: 12px;
    }
  }
`;

const NewsletterAddExternalLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: rgb(20, 20, 20);
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }

  .add-link-icon {
    height: 20px;
    fill: white;
  }
`;

const NewsletterEditWrapperTop = styled.div`
  margin-bottom: 50px;
`;

const StyledTemplateSelectionWrapper = styled(animated.div)`
  position: relative;
  width: calc(100% - 200px);
  height: calc(100vh - 130px);
  float: left;
  background: rgb(220, 220, 220);
  overflow: hidden;
`;

const TemplateButton = styled.button`
  transition: all 0.2s ease-in-out;
  padding: 10px;
  background: none;
  border: none;
  background: ${(props) =>
    props.selected ? props.theme.primary : 'rgb(100, 100, 100)'};
  font-size: 16px;
  color: ${(props) => (props.selected ? 'rgb(0, 0, 0)' : 'rgb(0, 0, 0)')};
  outline: none;
  margin-top: 10px;

  &:hover {
    cursor: ${(props) => (props.selected ? 'none' : 'pointer')};
  }
`;

const SelectFirstTemplateButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  background: rgb(230, 230, 230);
  z-index: 1000;
  top: 20px;
  right: 20px;
  font-size: 14px;
  color: rgb(150, 150, 150);
  border-radius: 2px;
  box-shadow: rgba(0, 5px, 10px, 0.2);
  border: none;
  outline: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const TemplateSelectionNoSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

const Page = ({ client, showTemplates, location, match, history }) => {
  const [showEditLink, toggleShowEditLink] = useState(false);

  useEffect(() => {
    client.writeQuery({
      query: defaultsQuery,
      data: {
        preview: false,
        showTemplates: false,
      },
    });
  }, [location]);

  const { preview } = client.readQuery({
    query: PREVIEW_STATE_QUERY,
  });

  const { x } = useSpring({
    from: { x: 200 },
    x: preview || showTemplates ? 200 : 0,
  });

  const { y } = useSpring({
    delay: !preview ? 0 : 700,
    from: { y: 0 },
    y: preview || showTemplates ? 1 : 0,
  });

  const { y2 } = useSpring({
    delay: preview ? 0 : 700,
    from: { y2: 0 },
    y2: !preview && !showTemplates ? 1 : 0,
  });

  const getEditMode = (mode) => {
    if (mode === 1) {
      return 'Innhold';
    }
    if (mode === 2) {
      return 'Farger';
    }
    if (mode === 3) {
      return 'Layout';
    }
    return '';
  };

  return (
    <Query
      query={GET_NEWSLETTER_QUERY}
      variables={{ id: match.params.newsletterId }}
      fetchPolicy="network-only"
      ssr={false}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }

        return (
          <Mutation
            mutation={SAVE_NEWSLETTER_MUTATION}
            update={(cache) => {
              addNotification(cache, {
                type: 'success',
                message: 'Nyhetsbrevet ble lagret.',
              });
            }}
          >
            {(save, { loading, error }) => (
              <Content
                padding="0"
                style={{
                  width: x.interpolate((x) => `calc(100% + ${x}px)`),
                }}
              >
                {data?.getNewsletter &&
                data.getNewsletter.hasTemplate === true ? (
                  <ContentWrapper preview={preview ? 1 : 0}>
                    <Query query={EDIT_MODE_QUERY} ssr={false}>
                      {({ data }) => {
                        return (
                          <ModeTab
                            style={{
                              opacity: y2.interpolate((y) => `${y}`),
                            }}
                            onClick={() => null}
                          >
                            <span>Aktiv modus</span>
                            <span>{getEditMode(data?.edit.mode)}</span>
                          </ModeTab>
                        );
                      }}
                    </Query>
                    <PreviewNotification
                      style={{
                        opacity: y.interpolate((y) => `${y}`),
                      }}
                      onClick={() => {
                        client.writeQuery({
                          query: defaultsQuery,
                          data: {
                            preview: false,
                          },
                        });
                      }}
                    >
                      Lukk forhåndsvisning
                    </PreviewNotification>
                    <TemplateSelection
                      newsletterId={match.params.newsletterId}
                      showTemplates={showTemplates}
                      refetch={refetch}
                    />
                    <EditImageLinkModal
                      id={match.params.newsletterId}
                      show={showEditLink ? 1 : 0}
                      onCancel={() => toggleShowEditLink(false)}
                      onSave={() => toggleShowEditLink(false)}
                    />

                    <Mutation mutation={UPDATE_FIELD_MUTATION}>
                      {(update, { loading, error }) => (
                        <NewsletterContent className="editor-scroll">
                          <NewsletterWrapper>
                            {!preview ? <h1>Redigere nyhetsbrev</h1> : null}
                            {!preview ? (
                              <StyledNewsletterMessage>
                                <b>NB!</b> Det er kun tillatt å sende ut
                                informasjon om det aktuelle prosjektet.
                              </StyledNewsletterMessage>
                            ) : null}

                            {!preview ? (
                              <InputSpacer>
                                <Input
                                  type="text"
                                  placeholder="Navn på nyhetsbrev"
                                  value={data.getNewsletter.name}
                                  colorSchema="primary"
                                  onBlur={(e) => {
                                    client.mutate({
                                      mutation: SAVE_NEWSLETTER_MUTATION,
                                      variables: {
                                        input: {
                                          identifier: match.params.newsletterId,
                                          name: data.getNewsletter.name,
                                        },
                                      },
                                    });
                                  }}
                                  onChange={(e) =>
                                    update({
                                      variables: {
                                        key: 'name',
                                        value: e.target.value,
                                        id: match.params.newsletterId,
                                      },
                                    })
                                  }
                                />
                              </InputSpacer>
                            ) : null}
                            {!preview ? (
                              <Input
                                type="text"
                                placeholder="Emne"
                                value={data.getNewsletter.subject}
                                colorSchema="primary"
                                onBlur={(e) => {
                                  client.mutate({
                                    mutation: SAVE_NEWSLETTER_MUTATION,
                                    variables: {
                                      input: {
                                        identifier: match.params.newsletterId,
                                        subject: data.getNewsletter.subject,
                                      },
                                    },
                                  });
                                }}
                                onChange={(e) =>
                                  update({
                                    variables: {
                                      key: 'subject',
                                      value: e.target.value,
                                      id: match.params.newsletterId,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <p
                                style={{
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                Emne:{' '}
                                {data.getNewsletter.subject
                                  ? data.getNewsletter.subject
                                  : 'Ikke definert'}
                              </p>
                            )}
                            <Editor match={match} />
                          </NewsletterWrapper>
                        </NewsletterContent>
                      )}
                    </Mutation>
                  </ContentWrapper>
                ) : (
                  <StyledTemplateSelectionWrapper
                    style={{
                      width: x.interpolate((x) => `calc(100% - ${x}px)`),
                    }}
                  >
                    <TemplateSelection
                      newsletterId={match.params.newsletterId}
                      showTemplates={showTemplates}
                      refetch={refetch}
                    />
                    <TemplateSelectionNoSelection>
                      <p
                        style={{
                          color: 'rgb(150, 150, 150)',
                          fontWeight: '400',
                          marginBottom: '20px',
                        }}
                      >
                        Ingen mal er valgt
                      </p>
                      <Mutation mutation={TOGGLE_SHOW_TEMPLATES_MUTATION}>
                        {(toggle) => (
                          <>
                            <Button
                              style={{ marginBottom: '20px' }}
                              onClick={() => toggle()}
                            >
                              Velg mal
                            </Button>
                            <LinkButton
                              className="light-gray"
                              to={`/prosjekt/${match.params.sguid}`}
                            >
                              Avbryt
                            </LinkButton>
                          </>
                        )}
                      </Mutation>
                    </TemplateSelectionNoSelection>
                  </StyledTemplateSelectionWrapper>
                )}
                <Sidebar
                  match={match}
                  getNewsletter={data.getNewsletter}
                  preview={preview}
                  history={history}
                />
              </Content>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export const Newsletter = compose(withApollo)((props) => {
  return (
    <Query query={SHOW_TEMPLATES_STATE_QUERY}>
      {({ data, loading, error }) => {
        if (loading) {
          return null;
        }

        return <Page {...props} showTemplates={data?.showTemplates} />;
      }}
    </Query>
  );
});

const StyledNewsletterMessage = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  border-radius: 2px;
  color: rgb(150, 150, 150);
  font-size: 14px;
`;

const PreviewNotification = styled(animated.div)`
  position: absolute;
  padding: 10px;
  background: rgb(230, 230, 230);
  z-index: 1000;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: rgb(150, 150, 150);
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
`;

const ModeTab = styled(animated.div)`
  display: flex;
  flex-flow: column;
  position: absolute;
  padding: 10px;
  background: #18a0fb;
  z-index: 1000;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: white;
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;

  span {
    &:first-child {
      font-size: 12px;
    }
    &:last-child {
      font-size: 16px;
    }
  }
`;
