import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Mutation } from '@apollo/client/react/components';
import { addNotification } from '../../utils/notification';

import { Modal } from '../Modals';
import { ModalLoader } from '../Loader';
import { Button, ButtonGroup } from '../Button';
import { Form, FormRow, FormCol, FormError } from '../Form';
import { Input } from '../Input';
import { Textarea } from '../TextArea';
import { Checkbox } from '../Checkbox';
import { GET_INTERESTED_QUERY } from '../../../../client/__graphql__/queries';
import { REGISTER_INTERESTED } from '../../../../client/__graphql__/mutations';
import {
  validateEmail,
  validatePhone,
} from '../../../../server/utils/validation';

export const AddInterestedModal = withRouter(
  ({ getInterestedVariables, match, show, sguid, onClose }) => {
    const [firstName, setFirtstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [comment, setComment] = useState('');
    const [consent, setConsent] = useState(false);

    const reset = () => {
      if (show === true) {
        setFirtstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setComment('');
        setConsent(false);
      }
    };

    return (
      <Modal show={show} className="add-interested-modal">
        <ModalContent>
          <Mutation
            mutation={REGISTER_INTERESTED}
            onCompleted={({ addInterested }) => {
              if (addInterested && addInterested.success) {
                onClose();
                setTimeout(() => {
                  reset();
                }, 1000);
              }
            }}
            update={(cache, { data: { addInterested } }) => {
              const c = cache.readQuery({
                query: GET_INTERESTED_QUERY,
                variables: getInterestedVariables,
              });

              const newItem = {
                id: addInterested.id,
                firstName,
                lastName,
                email,
                phone,
                unsubscribed: false,
                registrated: 'Megler',
                deleted: false,
                created: addInterested.created,
                __typename: 'Interested',
              };
              const arr = [newItem, ...c.getInterested.list];
              cache.writeQuery({
                query: GET_INTERESTED_QUERY,
                variables: getInterestedVariables,
                data: {
                  getInterested: {
                    fetched: true,
                    total: arr.length,
                    list: arr,
                    __typename: 'GetInterestedResponse',
                  },
                },
              });

              addNotification(cache, {
                type: 'success',
                message: 'Interessenten ble opprettet',
              });
            }}
          >
            {(post, { loading, error }) => {
              return (
                <>
                  <h1 style={{ textAlign: 'left', marginBottom: '20px' }}>
                    Registrer interessent
                  </h1>
                  <p style={{ marginTop: '10px', textAlign: 'left' }}>
                    <b>
                      Merk! Det er ikke mulig å slette, eller endre
                      interessenter i Webmegler fra denne løsningen.
                    </b>
                  </p>
                  {error && error.graphQLErrors
                    ? error.graphQLErrors.map(({ message }, i) => (
                        <FormError key={i}>{message}</FormError>
                      ))
                    : null}
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormRow>
                      <FormCol>
                        <Input
                          placeholder="Fornavn *"
                          value={firstName}
                          showError={true}
                          onChange={(e) => {
                            setFirtstName(e.target.value);
                          }}
                        />
                      </FormCol>
                      <FormCol>
                        <Input
                          placeholder="Etternavn *"
                          value={lastName}
                          showError={true}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <Input
                          placeholder="E-post *"
                          value={email}
                          showError={true}
                          onChange={(e) => {
                            const valid = validateEmail(e.target.value);
                            setValidEmail(valid);
                            setEmail(e.target.value);
                          }}
                        />
                      </FormCol>
                      <FormCol>
                        <Input
                          placeholder="Mobil * (8 tall)"
                          value={phone}
                          showError={true}
                          onChange={(e) => {
                            const valid = validatePhone(e.target.value);
                            setValidPhone(valid);
                            setPhone(e.target.value);
                          }}
                        />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <Textarea
                        placeholder="Kommentar"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </FormRow>
                    <FormRow>
                      <Checkbox
                        label="Ønsker nyhetsbrev med oppdateringer pr. e-post"
                        checked={consent}
                        onClick={() => setConsent(!consent)}
                      />
                    </FormRow>
                    <FormRow>
                      <Button
                        style={{ marginRight: '20px' }}
                        onClick={() => {
                          reset();
                          onClose();
                        }}
                      >
                        Avbryt
                      </Button>
                      <Button
                        primary
                        className="check-active"
                        disabled={loading}
                        active={
                          consent &&
                          validEmail &&
                          validPhone &&
                          firstName &&
                          lastName
                        }
                        onClick={() => {
                          if (
                            consent &&
                            validEmail &&
                            validPhone &&
                            firstName &&
                            lastName
                          ) {
                            post({
                              variables: {
                                input: {
                                  sguid,
                                  firstName,
                                  lastName,
                                  email,
                                  phone,
                                  comment,
                                  consent,
                                },
                              },
                            });
                          }
                        }}
                      >
                        Registrer
                      </Button>
                    </FormRow>
                  </Form>
                </>
              );
            }}
          </Mutation>
        </ModalContent>
      </Modal>
    );
  }
);

const ModalContent = styled.div`
  position: relative;
  width: 650px;

  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
`;
