import styled from 'styled-components';

export const Table = styled.table`
  margin: 20px 0;
  width: 100%;
`;

export const TableHead = styled.thead`
  background: #1f1f1f;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  background: rgb(25, 25, 25);

  &:nth-of-type(even) {
    background: rgb(20, 20, 20);
  }
`;

export const TableHeadCol = styled.th`
  padding: 10px;
  text-align: left;
  color: white;
`;

export const TableCol = styled.td`
  padding: 10px;
  text-align: left;
  color: white;
`;
