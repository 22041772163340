import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export const Pagination = ({ pages, currentPage, onClick }) => {
  const handlePageClick = ({ selected }) => {
    onClick(selected);
  };

  return (
    <PaginationWrapper>
      <ReactPaginate
        initialPage={currentPage}
        previousLabel={'Forrige'}
        nextLabel={'Neste'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={6}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      color: white;
      float: left;

      a {
        padding: 10px;
        display: block;
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        background: ${props => props.theme.primary};
        color: black;
      }

      &.previous {
        background: ${props => props.theme.secondary};
        color: white;

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }

      &.next {
        background: ${props => props.theme.secondary};
        color: white;

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
`;
