import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from './client/__config__/GlobalStyles';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Helmet } from 'react-helmet';
import MDSpinner from 'react-md-spinner';

import { PageLayout } from './common/components/layout/PageLayout';
import { Overview } from './common/components/containers/Overview';
import { Project } from './common/components/containers/Project';
import { Newsletter } from './common/components/containers/Newsletter';
import { Statistics } from './common/components/containers/Statistics';

import {
  GET_PROJECT_QUERY,
  PREVIEW_STATE_QUERY,
} from './client/__graphql__/queries';

import { NoMatch } from './common/components/containers/NoMatch';
import { Notification } from './common/components/ui/Notification';
import { Login } from './common/components/containers/Login';
import { PageLoader } from './common/components/ui/Loader';
import { Interested } from './common/components/containers/Interested';

const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const StyledLoaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;

  p {
    margin-top: 20px;
  }
`;

const PageErrorWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageError = styled.div`
  padding: 20px;
  background: rgb(190, 20, 10);
  color: white;
`;

const App = ({ location }) => (
  <Fragment>
    <GlobalStyles />
    <Helmet>
      <title>Nyhetsbrev - PrivatMegleren Nybygg</title>
    </Helmet>
    <Switch>
      <Route
        exact
        path="/:p1(registrer|registrering-tredjepart|glemt-passord|)?"
        component={Login}
      />
      <Route
        exact
        path="/:page?/:sguid?/:newsletterId?/:page2?"
        render={(props) => {
          if (
            props.match.params.page === 'oversikt' ||
            props.match.params.page === 'prosjekt'
          ) {
            return (
              <PageLayout {...props}>
                <Switch>
                  <Route
                    exact
                    path="/oversikt"
                    render={() => <Overview {...props} />}
                  />
                  <Route
                    path="/:page/:sguid/:newsletterId?/:page2?"
                    render={({ match }) => {
                      return (
                        <Query
                          query={GET_PROJECT_QUERY}
                          variables={{ sguid: match.params.sguid }}
                          fetchPolicy="network-only"
                          ssr={false}
                        >
                          {({ data, loading, error }) => {
                            if (loading) {
                              return (
                                <PageLoader loaderText="Henter prosjekt.." />
                              );
                            }

                            return (
                              <Query query={PREVIEW_STATE_QUERY}>
                                {({ data }) => {
                                  return (
                                    <Fragment>
                                      {!error ? (
                                        <Switch>
                                          <Route
                                            exact
                                            path="/:page/:sguid"
                                            component={Project}
                                          />
                                          <Route
                                            exact
                                            path="/:page/:sguid/interessenter"
                                            component={Interested}
                                          />
                                          <Route
                                            exact
                                            path="/:page/:sguid/:newsletterId"
                                            render={({ match }) => {
                                              if (
                                                match &&
                                                match.params &&
                                                match.params.newsletterId &&
                                                match.params.newsletterId !==
                                                  'interessenter'
                                              ) {
                                                return (
                                                  <Newsletter match={match} />
                                                );
                                              }
                                              return null;
                                            }}
                                          />
                                          <Route
                                            exact
                                            path="/:page/:sguid/:newsletterId/statistikk"
                                            component={Statistics}
                                          />
                                          <Route component={NoMatch} />
                                        </Switch>
                                      ) : (
                                        <PageErrorWrapper>
                                          <PageError>
                                            {error &&
                                            error.graphQLErrors &&
                                            error.graphQLErrors[0]
                                              ? error.graphQLErrors[0].message
                                              : null}
                                          </PageError>
                                        </PageErrorWrapper>
                                      )}
                                    </Fragment>
                                  );
                                }}
                              </Query>
                            );
                          }}
                        </Query>
                      );
                    }}
                  />
                </Switch>
                <Route
                  path="/:page?/:sguid?/:newsletterId?/:page2?"
                  render={() => <Notification />}
                />
              </PageLayout>
            );
          }
          return null;
        }}
      />
    </Switch>
  </Fragment>
);

export default App;
