import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { Button } from '../Button';
import { Input } from '../Input';
import { Query, Mutation } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { Modal } from '../Modals';
import { addNotification } from '../../utils/notification';
import { EditColorItem, EditColorWrapper } from '../../ui/ColorEditItem';

import { GET_NEWSLETTER_BUTTON_QUERY } from '../../../../client/__graphql__/queries';
import { SAVE_NEWSLETTER_BUTTON_MUTATION } from '../../../../client/__graphql__/mutations';

const ButtonGroup = styled.div`
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  .input-wrapper {
    width: 350px;
    float: left;
  }
`;

export const EditButtonModal = ({
  show,
  newsletterId,
  elementId,
  onSave,
  onCancel,
  onClickOutside,
  editMode,
}) => {
  if (typeof window !== 'undefined') {
    return createPortal(
      <ApolloConsumer>
        {(client) => (
          <Modal className="button" show={show} onClickOutside={onClickOutside}>
            <Query
              query={GET_NEWSLETTER_BUTTON_QUERY}
              variables={{
                newsletterId: newsletterId,
                elementId: elementId,
              }}
              fetchPolicy="network-only"
              ssr={false}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return null;
                }
                if (error) {
                  return null;
                }

                return (
                  <Mutation
                    mutation={SAVE_NEWSLETTER_BUTTON_MUTATION}
                    update={(cache) => {
                      addNotification(cache, {
                        type: 'success',
                        message: 'Lagret',
                      });
                    }}
                  >
                    {(save) => (
                      <Fragment>
                        <h2>Endre lenke</h2>
                        <InputWrapper>
                          <Input
                            prefix="https://"
                            placeholder="Lenke på knapp"
                            value={data?.getNewsletterButton.link}
                            onChange={(e) => {
                              client.writeQuery({
                                query: GET_NEWSLETTER_BUTTON_QUERY,
                                variables: { newsletterId, elementId },
                                data: {
                                  getNewsletterButton: {
                                    __typename: 'NewsletterButton',
                                    link: e.target.value.replace(
                                      /([A-z0-9]+)/gi,
                                      (o) => o.toLowerCase()
                                    ),
                                  },
                                },
                              });
                            }}
                          />
                        </InputWrapper>
                        <ButtonGroup>
                          <Button
                            onClick={onCancel}
                            style={{ marginRight: '20px' }}
                          >
                            Avbryt
                          </Button>
                          <Button
                            primary="true"
                            onClick={() => {
                              save({
                                variables: {
                                  newsletterId,
                                  elementId,
                                  data: data?.getNewsletterButton.link,
                                },
                              });
                              onCancel();
                            }}
                          >
                            Lagre
                          </Button>
                        </ButtonGroup>
                      </Fragment>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          </Modal>
        )}
      </ApolloConsumer>,
      document.getElementById('modals')
    );
  } else {
    return null;
  }
};
