import React from 'react';
import styled from 'styled-components';
import { Checkmark } from './Icons';

export const Checkbox = ({ label, checked, onClick }) => {
  return (
    <StyledCheckboxWrapper onClick={onClick}>
      <StyledCheckbox>
        {checked ? <Checkmark className="checkmark" /> : null}
      </StyledCheckbox>
      {label ? <StyledCheckboxLabel>{label}</StyledCheckboxLabel> : null}
    </StyledCheckboxWrapper>
  );
};

const StyledCheckboxWrapper = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 20px;
  user-select: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckboxLabel = styled.div`
  margin-left: 10px;
  font-size: 16px;
  color: white;
`;

const StyledCheckbox = styled.div`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  border: solid 2px rgb(60, 60, 60);

  .checkmark {
    position: absolute;
    display: block;
    width: 70%;
    height: 70%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    path {
      width: 100%;
      height: 100%;
      fill: ${props => props.theme.primary};
    }
  }
`;
