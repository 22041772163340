import React from 'react';
import styled from 'styled-components';
import Loaders from 'react-loader-spinner';

export const Loader = () => {
  return <Loaders type="TailSpin" color="#e5c88f" height={30} width={30} />;
};

export const PageLoader = ({ loaderText }) => {
  return (
    <PageLoaderWrapper>
      <Loader />
      {loaderText ? <p>{loaderText}</p> : null}
    </PageLoaderWrapper>
  );
};

export const ModalLoader = ({ loaderText }) => {
  return (
    <ModalLoaderWrapper>
      <Loader />
      {loaderText ? <p>{loaderText}</p> : null}
    </ModalLoaderWrapper>
  );
};

const ModalLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;

  p {
    margin: 0 0 0 20px !important;
    padding: 0 !important;
    color: white;
  }
`;

const PageLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;

  p {
    margin-left: 20px;
    color: white;
  }
`;
