import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash/flowRight';
import {
  SAVE_NEWSLETTER_DATA_MUTATION,
  UPDATE_FIELD_MUTATION,
} from '../../../../client/__graphql__/mutations';
import { GET_NEWSLETTER_DATA_QUERY } from '../../../../client/__graphql__/queries';

import { Button } from '../Button';
import { Input } from '../Input';
import { Modal } from '../Modals';
import { addNotification } from '../../utils/notification';

export const EditImageLinkModal = compose(withApollo)(
  ({
    show,
    newsletterId,
    elementId,
    onSave,
    onCancel,
    onClickOutside,
    client,
  }) => {
    if (typeof window !== 'undefined') {
      const [link, setLink] = useState('');
      return createPortal(
        <Modal show={show} onClickOutside={onClickOutside}>
          <Query
            query={GET_NEWSLETTER_DATA_QUERY}
            variables={{ newsletterId, elementId }}
            fetchPolicy="cache-and-network"
            ssr={false}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return null;
              }

              let imageLink = '';
              if (
                data?.getNewsletterData &&
                data.getNewsletterData.additionalData
              ) {
                try {
                  let parsed = JSON.parse(
                    data.getNewsletterData.additionalData
                  );
                  imageLink = parsed && parsed.link ? parsed.link : '';
                } catch (err) {
                  console.error(err);
                }
              }

              return (
                <Mutation
                  mutation={SAVE_NEWSLETTER_DATA_MUTATION}
                  update={(cache) => {
                    addNotification(cache, {
                      type: 'success',
                      message: link
                        ? `Lenken https://${link} ble lagret`
                        : `Lenken ble fjernet`,
                    });
                  }}
                >
                  {(save) => (
                    <Mutation mutation={UPDATE_FIELD_MUTATION}>
                      {(update) => (
                        <>
                          <h2>Legg til / endre lenke på bilde</h2>
                          <p>Alle lenker som skal benyttes må være https.</p>
                          {imageLink ? (
                            <StyledUsingLink>
                              Lenke som brukes: https://
                              {imageLink}
                            </StyledUsingLink>
                          ) : null}
                          <InputWrapper>
                            <Input
                              prefix="https://"
                              placeholder="Lenke på bilde"
                              value={link}
                              onChange={(e) => {
                                setLink(
                                  e.target.value.replace(/([A-z0-9]+)/gi, (o) =>
                                    o.toLowerCase()
                                  )
                                );
                              }}
                            />
                          </InputWrapper>
                          <ButtonGroup>
                            <Button
                              onClick={onCancel}
                              style={{ marginRight: '20px' }}
                            >
                              Avbryt
                            </Button>
                            <Button
                              primary="true"
                              onClick={() => {
                                save({
                                  variables: {
                                    newsletterId,
                                    elementId,
                                    field: 'additional_data',
                                    data: JSON.stringify({ link: link }),
                                  },
                                });
                                const prevData = client.readQuery({
                                  query: GET_NEWSLETTER_DATA_QUERY,
                                  variables: { newsletterId, elementId },
                                });

                                const newData = Object.assign(
                                  {},
                                  prevData.getNewsletterData,
                                  { link: link }
                                );

                                client.writeQuery({
                                  query: GET_NEWSLETTER_DATA_QUERY,
                                  variables: { newsletterId, elementId },
                                  data: {
                                    getNewsletterData: newData,
                                  },
                                });

                                onSave();
                              }}
                            >
                              Lagre
                            </Button>
                          </ButtonGroup>
                        </>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              );
            }}
          </Query>
        </Modal>,
        document.getElementById('modals')
      );
    } else {
      return null;
    }
  }
);

const StyledUsingLink = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

const ButtonGroup = styled.div`
  margin-top: 20px;
`;

const ModalError = styled.div`
  width: 100%;
  padding: 10px;
  background: red;
  color: white;
`;

const InputWrapper = styled.div`
  .input-wrapper {
    width: 350px;
    float: left;
  }
`;
