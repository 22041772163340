import React from 'react';
import styled from 'styled-components';

const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 55px;
  background: ${props => props.theme.input[props.colorSchema].field};
  border-radius: 2px;
  overflow: hidden;
`;

const StyledInput = styled.input`
  display: inline-block;
  width: inherit;
  padding: 27px 10px 5px 10px;
  background: none;
  font-size: 16px;
  border: none;
  outline: none;
  color: ${props => props.theme.input[props.colorSchema].font};
  float: left;

  &::-webkit-input-placeholder {
    color: ${props => props.theme.input[props.colorSchema].placeholder};
  }
`;

const StyledInputPlaceholder = styled.div`
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.input[props.colorSchema].placeholder};
`;

const StyledInputPrefix = styled.div`
  position: relative;
  bottom: -27px;
  left: 10px;
  float: left;
  color: ${props => props.theme.input[props.colorSchema].prefix};
`;

const StyledInputInsideWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  float: left;
`;

export const Input = ({
  className,
  type,
  placeholder,
  prefix,
  value,
  onChange,
  onBlur,
  onFocus,
  colorSchema = 'secondary',
  style
}) => {
  return (
    <StyledInputWrapper
      colorSchema={colorSchema}
      className={className ? `${className} input-wrapper` : 'input-wrapper'}
      style={style}
    >
      <StyledInputPlaceholder colorSchema={colorSchema}>
        {placeholder}
      </StyledInputPlaceholder>
      <StyledInputInsideWrapper colorSchema={colorSchema}>
        {prefix ? (
          <StyledInputPrefix colorSchema={colorSchema}>
            {prefix}
          </StyledInputPrefix>
        ) : null}
        <StyledInput
          colorSchema={colorSchema}
          hasPrefix={prefix ? 1 : 0}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </StyledInputInsideWrapper>
    </StyledInputWrapper>
  );
};
