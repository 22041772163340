import React from 'react';

export const ColorIcon = ({ className, fill }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.127 0C14.6021 0 14.1103 0.23209 13.7348 0.569434C6.49885 7.03309 5.63689 7.18848 5.9759 9.36295C4.5973 9.52764 3.36647 10.1615 2.64496 12.063C2.55598 12.2981 2.34215 12.4409 2.09246 12.4409C1.67178 12.4409 0.370879 11.393 0 11.1396C0 14.2591 1.43637 17 4.84766 17C8.7384 17 9.91844 14.0864 9.6641 12.156C11.5534 11.9232 12.8898 10.0805 16.5189 3.3077C16.7742 2.81064 17 2.27375 17 1.71527C17 0.714531 16.0999 0 15.127 0ZM7.8532 14.7937C7.18748 15.5527 6.17645 15.9375 4.84766 15.9375C2.72996 15.9375 1.78334 14.6482 1.36963 13.3247C1.63592 13.4459 1.86701 13.5037 2.09246 13.5037C2.78707 13.5037 3.39402 13.086 3.6384 12.4402C3.8592 11.8585 4.17131 10.6489 6.41949 10.3803L8.58035 12.061C8.71416 13.0797 8.53885 14.0117 7.8532 14.7937V14.7937ZM15.5826 2.80566C11.6513 10.1422 10.8116 10.9441 9.1385 11.1499L7.0799 9.54889C6.78771 7.67523 6.5885 8.37748 14.445 1.35967C14.6552 1.17074 14.9036 1.0625 15.127 1.0625C15.5185 1.0625 15.9375 1.3248 15.9375 1.71527C15.9375 2.06656 15.7536 2.47197 15.5826 2.80566Z"
      fill={fill}
    />
  </svg>
);

export const AddLinkIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="external-link-square"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill={fill}
      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm16 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v352zM99.515 374.828c-4.686-4.686-4.686-12.284 0-16.971l195.15-195.15-.707-.707-89.958.342c-6.627 0-12-5.373-12-12v-9.999c0-6.628 5.372-12 12-12L340 128c6.627 0 12 5.372 12 12l-.343 136c0 6.627-5.373 12-12 12h-9.999c-6.627 0-12-5.373-12-12l.342-89.958-.707-.707-195.15 195.15c-4.686 4.686-12.284 4.686-16.971 0l-5.657-5.657z"
      className={className}
    />
  </svg>
);

export const ImagesIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="images"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    className={className}
  >
    <path
      fill={fill}
      d="M528 32H112c-26.51 0-48 21.49-48 48v16H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-16h16c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-48 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h16v240c0 26.51 21.49 48 48 48h368v16zm64-64c0 8.822-7.178 16-16 16H112c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM176 200c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm240.971 23.029c-9.373-9.373-24.568-9.373-33.941 0L288 238.059l-31.029-31.03c-9.373-9.373-24.569-9.373-33.941 0l-88 88A24.002 24.002 0 0 0 128 312v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88zM480 320H160v-4.686l80-80 48 48 112-112 80 80V320z"
    />
  </svg>
);

export const TemplateIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="poll-h"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path
      fill={fill}
      d="M448 432V80c0-26.5-21.5-48-48-48H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48zM48 448c-8.82 0-16-7.18-16-16V80c0-8.82 7.18-16 16-16h352c8.82 0 16 7.18 16 16v352c0 8.82-7.18 16-16 16H48zm208-280v-16c0-4.42-3.58-8-8-8H104c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h144c4.42 0 8-3.58 8-8zm96 96v-16c0-4.42-3.58-8-8-8H104c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h240c4.42 0 8-3.58 8-8zm-160 96v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h80c4.42 0 8-3.58 8-8z"
    />
  </svg>
);

export const SettingsIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="cog"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"
    />
  </svg>
);

export const MailIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="envelope"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
    />
  </svg>
);

export const LockIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="lock"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path
      fill={fill}
      d="M400 224h-16v-62.5C384 73.1 312.9.3 224.5 0 136-.3 64 71.6 64 160v64H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM96 160c0-70.6 57.4-128 128-128s128 57.4 128 128v64H96v-64zm304 320H48c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v192c0 8.8-7.2 16-16 16z"
    />
  </svg>
);

export const AddIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="plus"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    className={className}
  >
    <path
      fill={fill}
      d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
    />
  </svg>
);

export const PreviewIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="search"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
    />
  </svg>
);

export const SaveIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="save"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path
      fill={fill}
      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z"
    />
  </svg>
);

export const DeleteIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="trash"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path
      fill={fill}
      d="M440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h18.9l33.2 372.3a48 48 0 0 0 47.8 43.7h232.2a48 48 0 0 0 47.8-43.7L421.1 96H440a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zm184.8 427a15.91 15.91 0 0 1-15.9 14.6H107.9A15.91 15.91 0 0 1 92 465.4L59 96h330z"
    />
  </svg>
);

export const PostIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="paper-plane"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M464 4.3L16 262.7C-7 276-4.7 309.9 19.8 320L160 378v102c0 30.2 37.8 43.3 56.7 20.3l60.7-73.8 126.4 52.2c19.1 7.9 40.7-4.2 43.8-24.7l64-417.1C515.7 10.2 487-9 464 4.3zM192 480v-88.8l54.5 22.5L192 480zm224-30.9l-206.2-85.2 199.5-235.8c4.8-5.6-2.9-13.2-8.5-8.4L145.5 337.3 32 290.5 480 32l-64 417.1z"
    />
  </svg>
);

export const UploadIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="arrow-to-top"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    className={className}
  >
    <path
      fill={fill}
      d="M35.5 248l148-148.5c4.7-4.7 12.3-4.7 17 0l148 148.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L209 156.1V468c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12V156.1L59.6 272c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.6-4.7-12.2 0-16.9zM0 44v8c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44z"
    />
  </svg>
);

export const EditIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="pencil"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"
    />
  </svg>
);

export const StatisticsIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="chart-bar"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M424 352h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8zm-96 0h16c4.4 0 8-3.6 8-8V200c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v144c0 4.4 3.6 8 8 8zm-192 0h16c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8zm96 0h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v208c0 4.4 3.6 8 8 8zm272 64H32V72c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v360c0 8.84 7.16 16 16 16h488c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8z"
    />
  </svg>
);

export const PaletteIcon = ({ className, fill }) => (
  <svg
    width="133"
    height="133"
    viewBox="0 0 133 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M99.3805 33.6195C93.6087 27.8478 86.4194 23.697 78.5351 21.5844L66.5 66.5L99.3805 33.6195Z"
      fill="#ECBF62"
    />
    <path
      d="M21.5844 78.5351C23.697 86.4194 27.8478 93.6087 33.6195 99.3804L66.5 66.5L21.5844 78.5351Z"
      fill="#5891CE"
    />
    <path
      d="M33.6195 99.3805C39.3913 105.152 46.5806 109.303 54.4649 111.416L66.5 66.5L33.6195 99.3805Z"
      fill="#646FB1"
    />
    <path
      d="M54.4649 111.416C62.3492 113.528 70.6507 113.528 78.5351 111.416L66.5 66.5L54.4649 111.416Z"
      fill="#782E8B"
    />
    <path
      d="M78.5351 111.416C86.4194 109.303 93.6088 105.152 99.3805 99.3805L66.5 66.5L78.5351 111.416Z"
      fill="#8A2B65"
    />
    <path
      d="M99.3805 99.3805C105.152 93.6087 109.303 86.4194 111.416 78.5351L66.5 66.5L99.3805 99.3805Z"
      fill="#CB392E"
    />
    <path
      d="M111.416 78.5351C113.528 70.6508 113.528 62.3493 111.416 54.4649L66.5 66.5L111.416 78.5351Z"
      fill="#D77946"
    />
    <path
      d="M111.416 54.4649C109.303 46.5806 105.152 39.3912 99.3805 33.6195L66.5 66.5L111.416 54.4649Z"
      fill="#DF974A"
    />
    <path
      d="M21.5845 54.4649C19.4719 62.3492 19.4719 70.6507 21.5845 78.5351L66.5 66.5L21.5845 54.4649Z"
      fill="#5C9E8D"
    />
    <path
      d="M33.6195 33.6195C27.8478 39.3913 23.697 46.5806 21.5844 54.4649L66.5 66.5L33.6195 33.6195Z"
      fill="#77B755"
    />
    <path
      d="M54.4649 21.5844C46.5806 23.697 39.3913 27.8478 33.6195 33.6195L66.5 66.5L54.4649 21.5844Z"
      fill="#C2D653"
    />
    <path
      d="M78.5351 21.5845C70.6508 19.4719 62.3493 19.4719 54.4649 21.5845L66.5 66.5L78.5351 21.5845Z"
      fill="#EAEA51"
    />
  </svg>
);

export const LayoutIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="20" height="5.45455" rx="0.909091" fill="#969696" />
    <rect
      y="6.36365"
      width="6.36364"
      height="13.6364"
      rx="0.909091"
      fill="#969696"
    />
    <rect
      x="7.27271"
      y="6.36365"
      width="12.7273"
      height="13.6364"
      rx="0.909091"
      fill="#969696"
    />
  </svg>
);

export const EyeDropperIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="eye-dropper"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M483.61 26.62c-35.51-35.65-92.84-35.33-128.04 0l-82.63 82.94-17-17.06c-9.37-9.41-24.57-9.41-33.95 0l-40.98 41.14c-9.37 9.41-9.38 24.67 0 34.08l16.98 17.05-147.3 149.84a64.38 64.38 0 0 0-18.76 45.44v33.74l-27 39.79c-7.98 12.7-6.13 29.25 4.44 39.86l9.11 9.14c10.57 10.61 27.06 12.46 39.71 4.46l39.65-27.1h33.62a63.91 63.91 0 0 0 45.27-18.82l149.31-147.85L343 330.31c9.38 9.41 24.58 9.41 33.95 0l40.98-41.14c9.38-9.41 9.37-24.67 0-34.08l-16.95-17.02 82.63-82.94c29.67-29.77 45.24-83.1 0-128.51zM154.09 440.41c-6.05 6.07-14.08 7.41-22.63 7.41H88.59l-47.47 32.05-9.11-9.14 31.94-47.65v-43.03c0-8.58 3.33-16.65 9.38-22.72l147.3-149.85 82.77 83.08-149.31 149.85zm306.89-307.99L355.71 238.07l33.93 34.06-29.67 29.78L209.3 150.67l29.67-29.77 33.97 34.1L378.21 49.34c22.82-22.91 59.95-22.91 82.77 0 39.39 39.53 6.91 76.14 0 83.08z"
    />
  </svg>
);

export const LinkIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="link"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fill}
      d="M301.148 394.702l-79.2 79.19c-50.778 50.799-133.037 50.824-183.84 0-50.799-50.778-50.824-133.037 0-183.84l79.19-79.2a132.833 132.833 0 0 1 3.532-3.403c7.55-7.005 19.795-2.004 20.208 8.286.193 4.807.598 9.607 1.216 14.384.481 3.717-.746 7.447-3.397 10.096-16.48 16.469-75.142 75.128-75.3 75.286-36.738 36.759-36.731 96.188 0 132.94 36.759 36.738 96.188 36.731 132.94 0l79.2-79.2.36-.36c36.301-36.672 36.14-96.07-.37-132.58-8.214-8.214-17.577-14.58-27.585-19.109-4.566-2.066-7.426-6.667-7.134-11.67a62.197 62.197 0 0 1 2.826-15.259c2.103-6.601 9.531-9.961 15.919-7.28 15.073 6.324 29.187 15.62 41.435 27.868 50.688 50.689 50.679 133.17 0 183.851zm-90.296-93.554c12.248 12.248 26.362 21.544 41.435 27.868 6.388 2.68 13.816-.68 15.919-7.28a62.197 62.197 0 0 0 2.826-15.259c.292-5.003-2.569-9.604-7.134-11.67-10.008-4.528-19.371-10.894-27.585-19.109-36.51-36.51-36.671-95.908-.37-132.58l.36-.36 79.2-79.2c36.752-36.731 96.181-36.738 132.94 0 36.731 36.752 36.738 96.181 0 132.94-.157.157-58.819 58.817-75.3 75.286-2.651 2.65-3.878 6.379-3.397 10.096a163.156 163.156 0 0 1 1.216 14.384c.413 10.291 12.659 15.291 20.208 8.286a131.324 131.324 0 0 0 3.532-3.403l79.19-79.2c50.824-50.803 50.799-133.062 0-183.84-50.802-50.824-133.062-50.799-183.84 0l-79.2 79.19c-50.679 50.682-50.688 133.163 0 183.851z"
      className={className}
    />
  </svg>
);

export const CopyIcon = ({ className, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="copy"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path
      fill={fill}
      d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"
      className={className}
    ></path>
  </svg>
);

export const Checkmark = ({ className, fill }) => (
  <svg
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 7.32748L1.83237 4.97396L6.72756 9.01133L14.7722 0.189789L17 2.18674L7.0399 13.1891L0 7.32748Z" />
  </svg>
);

export const Refresh = ({ className, fill }) => (
  <svg
    ariaHidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"></path>
  </svg>
);

export const SearchIcon = ({ className, focus }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      focus={focus}
      d="M29.7224 27.2685L20.605 18.1494C22.0073 16.3081 22.7964 13.9408 22.7964 11.3976C22.7964 5.08477 17.7116 0 11.3988 0C5.08597 0 -0.000488281 5.08645 -0.000488281 11.3993C-0.000488281 17.7121 5.08428 22.7969 11.3971 22.7969C13.9403 22.7969 16.2188 22.0078 18.1489 20.6055L27.268 29.7246C27.6181 30.0747 28.0571 30.2506 28.496 30.2506C28.935 30.2506 29.3722 30.0747 29.7241 29.7246C30.4244 29.0226 30.4244 27.9704 29.7224 27.2685L29.7224 27.2685ZM11.3988 19.2903C7.01432 19.2903 3.50776 15.7837 3.50776 11.3993C3.50776 7.01481 7.01432 3.50825 11.3988 3.50825C15.7833 3.50825 19.2898 7.01481 19.2898 11.3993C19.2898 15.7821 15.7816 19.2903 11.3988 19.2903Z"
      fill="black"
    />
  </svg>
);
