import React, { Fragment, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { ApolloConsumer } from '@apollo/client';
import * as compose from 'lodash/flowRight';
import { SAVE_NEWSLETTER_DATA_MUTATION } from '../../../../client/__graphql__/mutations';
import {
  GET_TEMPLATE_QUERY,
  GET_NEWSLETTER_DATA_QUERY,
  PREVIEW_STATE_QUERY,
} from '../../../../client/__graphql__/queries';
import { UploadImage } from './modules/UploadImage';
import { addNotification } from '../../utils/notification';

import { EditButton } from './ui/EditButton';
import { EditableLogo } from './ui/Logo';
import { RemoveElementButton } from './ui/buttons/RemoveElement';
import { AddElementButton } from './ui/buttons/AddElement';
import { addElement, removeElement } from '../../utils/query-helpers';
import { AddListElementWrapper } from './ui/buttons/AddListElementWrapper';
import { defaultsQuery } from '../../../../client/__graphql__/defaults';

function removeFormatting(ref, e) {
  e.preventDefault();
  let text = '';
  if (typeof e.clipboardData !== 'undefined') {
    text = e.clipboardData.getData('Text');
  } else {
    text = window.clipboardData.getData('Text');
  }
  ref.current.innerHTML = text;
}

const Template = React.memo(
  compose(
    withApollo,
    withRouter
  )(({ officeName, item, match, client }) => {
    let style = Object.assign({}, item.style);
    let attr = Object.assign({}, item.attr);
    let meta = Object.assign({}, item.meta);
    let state = {};

    const { edit, preview } = client.readQuery({
      query: PREVIEW_STATE_QUERY,
    });

    if (meta && meta.editable && !preview && edit.mode === 1) {
      attr.contentEditable = 'true';
      attr.suppressContentEditableWarning = 'true';
    }

    return (
      <Query
        query={GET_NEWSLETTER_DATA_QUERY}
        variables={{
          newsletterId: match.params.newsletterId,
          elementId: item.id,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return null;
          } else {
            // Get background and color of element

            if (data?.getNewsletterData && data.getNewsletterData.background) {
              style = Object.assign({}, style, {
                background: data.getNewsletterData.background,
              });
            }

            if (data?.getNewsletterData && data.getNewsletterData.color) {
              style = Object.assign({}, style, {
                color: data.getNewsletterData.color,
              });
            }

            // Get src

            if (
              data?.getNewsletterData &&
              data.getNewsletterData.data &&
              data.getNewsletterData.data.match(/files/gi)
            ) {
              attr = Object.assign({}, attr, {
                src: data.getNewsletterData.data,
              });
            }

            // Get content of element

            if (data?.getNewsletterData && data.getNewsletterData.content) {
              item = Object.assign({}, item, {
                content:
                  data.getNewsletterData && data.getNewsletterData.content
                    ? data.getNewsletterData.content
                    : item.content,
              });
            }

            // Get state of element

            state = Object.assign({}, state, {
              show:
                data?.getNewsletterData.state.show === null
                  ? true
                  : data?.getNewsletterData.state.show === true
                  ? true
                  : false,
            });

            return (
              <StyledEditWrapper>
                {!preview &&
                edit.mode === 1 &&
                meta &&
                meta.edit &&
                meta.edit.imageLink ? (
                  <EditButton
                    item={item}
                    label="Endre lenke"
                    type="image-link"
                  />
                ) : null}

                {edit.mode === 2 &&
                meta &&
                meta.edit &&
                meta.edit.background ? (
                  <EditButton
                    item={item}
                    preview={preview}
                    type="color"
                    disabled={!edit.saved}
                    className="section-color"
                    active={item.id === edit.element}
                    onClick={(disabled) => {
                      let connected =
                        (item.meta &&
                          item.meta.edit &&
                          item.meta.edit.connected_with &&
                          item.meta.edit.connected_with.map((item) => {
                            return { id: item };
                          })) ||
                        [];
                      let elements = [{ id: item.id }, ...connected];
                      elements = elements.map((item) => {
                        return {
                          id: item.id,
                          __typename: `element_${item.id}`,
                        };
                      });

                      const queryData = client.readQuery({
                        query: defaultsQuery,
                      });
                      client.writeQuery({
                        query: defaultsQuery,
                        data: {
                          ...queryData,
                          edit: {
                            ...queryData.edit,
                            __typename: 'Edit',
                            elements: elements,
                            element: item.id !== edit.element ? item.id : '',
                            elementName:
                              item.id !== edit.element ? item.elementName : '',
                            startColor: data?.getNewsletterData?.background
                              ? data.getNewsletterData.background
                              : item.style.background,
                            colorType: meta.edit.background
                              ? 'background'
                              : 'color',
                            color: data?.getNewsletterData?.background
                              ? data.getNewsletterData.background
                              : item.style.background,
                          },
                        },
                      });
                    }}
                  />
                ) : null}
                {edit.mode === 2 && meta && meta.edit && meta.edit.color ? (
                  <EditButton
                    item={item}
                    preview={preview}
                    type="color"
                    active={item.id === edit.element}
                    disabled={!edit.saved}
                    className="font-color"
                    onClick={() => {
                      let elements = [{ id: item.id }];
                      elements = elements.map((item) => {
                        return {
                          id: item.id,
                          __typename: `element_text_${item.id}`,
                        };
                      });

                      const queryData = client.readQuery({
                        query: defaultsQuery,
                      });
                      client.writeQuery({
                        query: defaultsQuery,
                        data: {
                          ...queryData,
                          edit: {
                            ...queryData.edit,
                            __typename: 'Edit',
                            element: item.id !== edit.element ? item.id : '',
                            elements: elements,
                            elementName:
                              item.id !== edit.element ? item.elementName : '',
                            startColor: data?.getNewsletterData?.color
                              ? data.getNewsletterData.color
                              : item.style.color,
                            colorType: meta.edit.background
                              ? 'background'
                              : 'color',
                            color: data?.getNewsletterData?.color
                              ? data.getNewsletterData.color
                              : item.style.color,
                          },
                        },
                      });
                    }}
                  />
                ) : null}

                {!preview &&
                edit.mode === 1 &&
                meta &&
                meta.edit &&
                meta.edit.upload ? (
                  <UploadImage
                    item={item}
                    newsletterId={match.params.newsletterId}
                    elementId={item.id}
                    match={match}
                    setVersion={0}
                    version={0}
                    refetch={() => null}
                  />
                ) : null}

                {meta &&
                meta.addable === true &&
                edit &&
                edit.mode === 3 &&
                state &&
                state.show === true ? (
                  <RemoveElementButton
                    onClick={() => {
                      const preparedStateData = Object.assign(
                        {},
                        data.getNewsletterData
                      );
                      const newState = Object.assign({}, preparedStateData, {
                        state: {
                          show: false,
                          __typename: 'NewsletterDataState',
                        },
                      });

                      client.writeQuery({
                        query: GET_NEWSLETTER_DATA_QUERY,
                        variables: {
                          newsletterId: match.params.newsletterId,
                          elementId: item.id,
                        },
                        data: {
                          getNewsletterData: newState,
                        },
                      });
                      removeElement(
                        client,
                        {
                          input: {
                            newsletterId: match.params.newsletterId,
                            elementId: item.id,
                          },
                        },
                        () => {
                          if (item.tag === 'li') {
                          }
                        }
                      );
                    }}
                    className={item.tag === 'button' ? 'button' : ''}
                  >
                    <span>-</span>
                  </RemoveElementButton>
                ) : null}

                <GetTemplate
                  item={item}
                  attr={attr}
                  data={data?.getNewsletterData}
                  meta={meta}
                  style={style}
                  state={state}
                  preview={preview}
                  match={match}
                  edit={edit}
                  client={client}
                  officeName={officeName}
                  elementAfter={
                    <>
                      {meta &&
                      meta.edit &&
                      meta.edit.button &&
                      !preview &&
                      edit.mode === 1 ? (
                        <EditButton
                          item={item}
                          preview={preview}
                          type="button-link"
                          disabled={!edit.saved}
                          active={item.id === edit.element}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        />
                      ) : null}

                      {meta &&
                      meta.edit &&
                      meta.edit.buttonColor &&
                      edit.mode === 2 ? (
                        <EditButton
                          item={item}
                          preview={preview}
                          type="color"
                          className="edit-button-color"
                          active={item.id === edit.element}
                          disabled={!edit.saved}
                          onClick={(disabled) => {
                            let elements = [{ id: item.id }];
                            elements = elements.map((item) => {
                              return {
                                id: item.id,
                                __typename: `element_button_text_${item.id}`,
                              };
                            });

                            const queryData = client.readQuery({
                              query: defaultsQuery,
                            });

                            client.writeQuery({
                              query: defaultsQuery,
                              data: {
                                ...queryData,
                                edit: {
                                  ...queryData.edit,
                                  __typename: 'Edit',
                                  element:
                                    item.id !== edit.element ? item.id : '',
                                  elements: elements,
                                  elementName:
                                    item.id !== edit.element
                                      ? item.elementName
                                      : '',
                                  colorType: 'color',
                                  color: data?.getNewsletterData?.color
                                    ? data.getNewsletterData.color
                                    : item.style.color,
                                },
                              },
                            });
                          }}
                        />
                      ) : null}
                      {meta &&
                      meta.edit &&
                      meta.edit.buttonBackground &&
                      edit.mode === 2 ? (
                        <EditButton
                          item={item}
                          preview={preview}
                          type="color"
                          disabled={!edit.saved}
                          className="edit-button-background"
                          active={item.id === edit.element}
                          onClick={(disabled) => {
                            let elements = [{ id: item.id }];
                            elements = elements.map((item) => {
                              return {
                                id: item.id,
                                __typename: `element_button_${item.id}`,
                              };
                            });

                            const queryData = client.readQuery({
                              query: defaultsQuery,
                            });
                            client.writeQuery({
                              query: defaultsQuery,
                              data: {
                                ...queryData,
                                edit: {
                                  ...queryData.edit,
                                  __typename: 'Edit',
                                  element:
                                    item.id !== edit.element ? item.id : '',
                                  elements: elements,
                                  elementName:
                                    item.id !== edit.element
                                      ? item.elementName
                                      : '',
                                  colorType: 'background',
                                  color: data?.getNewsletterData?.background
                                    ? data.getNewsletterData.background
                                    : item.style.color,
                                },
                              },
                            });
                          }}
                        />
                      ) : null}
                    </>
                  }
                />
                {meta && meta.edit && meta.edit.logo && meta.edit.logo.types ? (
                  <EditableLogo
                    edit={edit}
                    attr={attr}
                    style={style}
                    data={meta.edit.logo}
                    preview={preview}
                    newsletterId={match.params.newsletterId}
                    elementId={item.id}
                    match={match}
                  />
                ) : null}
              </StyledEditWrapper>
            );
          }
        }}
      </Query>
    );
  })
);

const GetTemplate = React.memo(
  ({
    item,
    attr,
    data,
    meta,
    state,
    style,
    preview,
    match,
    edit,
    officeName,
    elementAfter,
  }) => {
    // Removes inline display style if element is removed from template.
    if (state && state.show === false) {
      if (style && style.display) {
        style = Object.assign({}, style, { display: 'none' });
      }
    }
    const Tag = styled(item.tag)`
      position: relative;
      ${(props) => (props.show === false ? 'display: none; !important' : '')}
      &[contenteditable='true'] {
        transition: outline 0.2s ease-in-out;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;
        outline-offset: -2px;
        user-select: auto;

        &:focus {
          outline-color: #18a0fb;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
      }
    `;
    const content = item.content || null;
    switch (item.tag) {
      case 'img':
        return <Tag {...attr} style={style} />;
      default:
        const elementRef = useRef(null);
        return (
          <ApolloConsumer>
            {(client) => (
              <Mutation
                mutation={SAVE_NEWSLETTER_DATA_MUTATION}
                onCompleted={(data) => {
                  addNotification(client, {
                    type: 'success',
                    message: 'Lagret',
                  });

                  let prevData = client.readQuery({
                    query: GET_NEWSLETTER_DATA_QUERY,
                    variables: {
                      newsletterId: match.params.newsletterId,
                      elementId: item.id,
                    },
                  });

                  const newData = Object.assign(
                    {},
                    prevData.getNewsletterData,
                    {
                      content: data.saveNewsletterData.data,
                    }
                  );

                  client.writeQuery({
                    query: GET_NEWSLETTER_DATA_QUERY,
                    variables: {
                      newsletterId: match.params.newsletterId,
                      elementId: item.id,
                    },
                    data: {
                      getNewsletterData: newData,
                    },
                  });
                }}
              >
                {(save) => {
                  return (
                    <>
                      <Tag
                        show={state && state.show === true}
                        ref={elementRef}
                        {...attr}
                        style={style}
                        onKeyPress={
                          attr && attr.contentEditable ? (e) => {} : null
                        }
                        onPaste={
                          attr && attr.contentEditable
                            ? (e) => {
                                removeFormatting(elementRef, e);
                              }
                            : null
                        }
                        onBlur={
                          attr && attr.contentEditable
                            ? (e) => {
                                let d = e.target.innerHTML;
                                save({
                                  variables: {
                                    newsletterId: match.params.newsletterId,
                                    elementId: item.id,
                                    field: 'content',
                                    data: d,
                                  },
                                });
                              }
                            : null
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        />
                        {elementAfter}
                        {item && item.children && item.children.length
                          ? item.children.map((item, index) => (
                              <Template
                                key={index}
                                item={item}
                                preview={preview}
                                officeName={officeName}
                              />
                            ))
                          : null}
                      </Tag>

                      {state &&
                      state.show === false &&
                      item &&
                      item.tag &&
                      item.tag !== 'ul' &&
                      item.tag !== 'li' &&
                      edit.mode === 3 ? (
                        <AddElementButton
                          className="relative"
                          label="Legg til"
                          onClick={() => {
                            const {
                              getNewsletterData: elementData,
                            } = client.readQuery({
                              query: GET_NEWSLETTER_DATA_QUERY,
                              variables: {
                                newsletterId: match.params.newsletterId,
                                elementId: item.id,
                              },
                            });

                            const newState = Object.assign({}, elementData, {
                              state: {
                                show: true,
                                __typename: 'NewsletterDataState',
                              },
                            });

                            client.writeQuery({
                              query: GET_NEWSLETTER_DATA_QUERY,
                              variables: {
                                newsletterId: match.params.newsletterId,
                                elementId: item.id,
                              },
                              data: {
                                getNewsletterData: newState,
                              },
                            });
                            addElement(client, {
                              input: {
                                newsletterId: match.params.newsletterId,
                                elementId: item.id,
                              },
                            });
                          }}
                        />
                      ) : null}

                      {item &&
                      item.tag &&
                      item.tag === 'ul' &&
                      item.children &&
                      edit.mode === 3 ? (
                        <AddListElementWrapper
                          item={item}
                          newsletterId={match.params.newsletterId}
                        />
                      ) : null}
                    </>
                  );
                }}
              </Mutation>
            )}
          </ApolloConsumer>
        );
    }
  }
);

export const Editor = React.memo(({ match }) => {
  return (
    <StyledEditorWrapper id="editor">
      <Query
        query={GET_TEMPLATE_QUERY}
        variables={{ id: match.params.newsletterId }}
        fetchPolicy="cache-first"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return null;
          }

          if (error) {
            return (
              <FetchError>
                Malen er feilkonfigurert. Vennligst send en henvendelse til{' '}
                <a href="mailto:helpdesk@reeltime.no">helpdesk@reeltime.no</a>,
                så vil vi rette problemet så fort som mulig.
              </FetchError>
            );
          }

          if (data && data.getTemplate && data.getTemplate.structure) {
            return (
              <Fragment>
                <ElementsWrapper>
                  <Template
                    officeName={data.getTemplate.officeName}
                    item={data.getTemplate.structure}
                    match={match}
                  />
                </ElementsWrapper>
              </Fragment>
            );
          }
          return null;
        }}
      </Query>
    </StyledEditorWrapper>
  );
});

const StyledEditWrapper = styled.div`
  position: relative;
  text-align: center;
  min-width: 30px;
  clear: both;
`;

const StyledEditorWrapper = styled.div`
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);

  &.crosshair:hover {
    cursor: crosshair;
  }
`;

const ElementsWrapper = styled.div`
  user-select: none;
  table {
    padding: 0;

    tbody {
      padding: 0;

      tr {
        padding: 0;

        td {
          padding: 0;
        }
      }
    }

    span {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }
`;

const FetchError = styled.div`
  width: 100%;
  padding: 20px;
  font-size: 14px;
  background: rgb(210, 30, 0);
  text-align: center;
  color: white;

  a {
    color: white;
  }
`;
