import React, { useState, useEffect } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';

import { SEARCH_PROJECT_QUERY } from '../../../client/__graphql__/queries';
import { SearchIcon } from './Icons';

export const SearchField = withApollo(
  ({
    client,
    initalSearch,
    setInitialSearch,
    currentPage,
    thirdParty,
    onResult,
    isLoading,
    children,
  }) => {
    const [query, setSearchQuery] = useState('');
    const [focus, setFocus] = useState(false);

    const search = async () => {
      isLoading(true);
      const { data, loading, error } = await client.query({
        query: SEARCH_PROJECT_QUERY,
        variables: { input: { query, currentPage } },
        fetchPolicy: 'network-only',
      });
      if (error) {
        console.log(error);
      }
      if (data) {
        onResult(data);
      }
    };

    useEffect(() => {
      search();
    }, [currentPage]);

    useEffect(() => {
      if (initalSearch) {
        search();
        setInitialSearch(false);
      }
    }, []);

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search();
        }}
      >
        <OuterSearchWrapper>
          <SearchFieldWrapper>
            <SearchIcon className="search-icon" focus={focus.toString()} />
            <SearchInput
              focus={focus.toString()}
              placeholder={
                thirdParty
                  ? 'Søk på megler, avdeling eller prosjekt'
                  : 'Søk i dine oppdrag'
              }
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </SearchFieldWrapper>
          {children}
        </OuterSearchWrapper>
      </form>
    );
  }
);

const OuterSearchWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchFieldWrapper = styled.div`
  position: relative;

  .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    width: 25px;
    height: 25px;
    margin: auto;

    path {
      transition: opacity 0.1s ease-in-out;
      fill: white;
      opacity: ${(props) => (props.focus === 'true' ? 0.5 : 0.2)};
    }
  }
`;

const SearchInput = styled.input`
  transition: all 0.1s ease-in-out;
  margin: 0;
  border: none;
  padding: 20px 20px 20px 55px;
  font-size: 1.1rem;
  width: 400px;
  outline: none;
  background: rgb(30, 30, 30);
  border-radius: 2px;
  color: ${(props) =>
    props.focus === 'true' ? 'white' : 'rgb(180, 180, 180)'};

  &::-webkit-input-placeholder {
    transition: all 0.1s ease-in-out;
    color: ${(props) =>
      props.focus === 'true' ? 'white' : 'rgb(180, 180, 180)'};
  }
`;
