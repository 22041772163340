import React from 'react';

import { Content } from '../layout/Content';

export const NoMatch = () => {
  return (
    <Content className="centered-content">
      <h1>Finner ikke det du leter etter.</h1>
    </Content>
  );
};
