import 'isomorphic-fetch';
import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import { render } from 'react-dom';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { defaults, defaultsQuery } from './client/__graphql__/defaults';
import { resolvers } from './client/__graphql__/resolvers';

import { theme } from './client/__config__/theme';
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
  uri: `${window.process.graph}/api/graphql`,
  credentials: 'same-origin',
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log(graphQLErrors);
  if (
    graphQLErrors &&
    graphQLErrors[0] &&
    graphQLErrors[0].extensions &&
    graphQLErrors[0].extensions.code === '401'
  ) {
    operation.getContext().cache.writeData({
      data: {
        authenticated: {
          __typename: 'Authenticated',
          loggedIn: false,
        },
      },
    });
  }
});

const link = ApolloLink.from([errorLink, uploadLink]);
const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const client = new ApolloClient({
  link: link,
  cache: cache,
  resolvers,
});

/*
  Write default to cache
*/

client.onResetStore(() => {
  cache.writeQuery({
    query: defaultsQuery,
    data: defaults.data,
  });
});
cache.writeQuery({
  query: defaultsQuery,
  data: defaults.data,
});

render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
