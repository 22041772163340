import {
  ADD_TEMPLATE_ELEMENT_MUTATION,
  REMOVE_TEMPLATE_ELEMENT_MUTATION
} from '../../../client/__graphql__/mutations';
import { GET_NEWSLETTER_DATA_QUERY } from '../../../client/__graphql__/queries';

export const addElement = (client, variables, cb) => {
  if (typeof client !== 'undefined') {
    client
      .mutate({
        mutation: ADD_TEMPLATE_ELEMENT_MUTATION,
        variables: variables
      })
      .then(res => {
        if (typeof cb == 'function') {
          cb(res);
        }
      });
  }
};

export const removeElement = (client, variables, cb) => {
  if (typeof client !== 'undefined') {
    client
      .mutate({
        mutation: REMOVE_TEMPLATE_ELEMENT_MUTATION,
        variables: variables,
        refetchQueries: [{ query: GET_NEWSLETTER_DATA_QUERY }]
      })
      .then(res => {
        if (typeof cb == 'function') {
          cb(res);
        }
      });
  }
};
