import shortid from 'shortid';
import { NOTIFICATION_STATE_QUERY } from '../../../client/__graphql__/queries';

export const addNotification = async (cache, data) => {
  try {
    const { notification } = cache.readQuery({
      query: NOTIFICATION_STATE_QUERY,
    });
    let newItems = [
      // ...notification.items.splice(0, 1),
      {
        __typename: 'NotificationItem',
        key: `notification-${shortid.generate()}`,
        show: true,
        type: data.type,
        message: data.message,
      },
    ];
    cache.writeQuery({
      query: NOTIFICATION_STATE_QUERY,
      data: {
        notification: {
          __typename: 'Notification',
          items: newItems,
        },
      },
    });
    return true;
  } catch (err) {
    console.error(err);
  }
};
